//src/pages/shop/MoreModal.ja

import React from "react";

import { cakeAmount, shippingAmount } from "../../utilities/functions";

import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";

const MoreModal = ({ isOpen, toggle, selectedCake }) => {
  //console.log('>>>-MoreModal-{ isOpen, toggle, selectedCake }->',{isOpen,toggle,selectedCake})

  return (
    <MDBModal
      size="small"
      isOpen={isOpen}
      toggle={toggle}
      className="modal-notify modal-info text-white"
    >
      <MDBModalHeader>Christy&apos;s {selectedCake.name} Rum Cake</MDBModalHeader>
      <MDBModalBody>
        <h5>
          The {selectedCake.name} Rum Cake is ${cakeAmount(selectedCake.name)}. Shipping dimensions are 9
          inch diameter by 3 inches shipping 4lbs.
        </h5>
        <hr />
        <p>
          From 12 to 16 slices per cake depending on what your mummy taught you
          about sharing.
        </p>
        <hr />
        <p>
          Flat rate for shipping, taxes and handling per cake. Contact us if you
          want to buy more than one at a time.
        </p>
        <MDBTable>
          <MDBTableHead color="primary-color" textWhite>
            <tr>
              <th>Ship to</th>
              <th>Shipping Tax Handling</th>
              <th>Rate for first cake</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr>
              <td>You organize it</td>
              <td></td>
              <td className="pt-2 m-0 s-0 text-left">
                {"$" +
                  shippingAmount("noShipping", 1) +
                  ".00 - Contact Christy before purchase"}
              </td>
            </tr>
            <tr>
              <td>Continental USA</td>
              <td>standard</td>
              <td className="pt-2 m-0 s-0 text-left">
                {"$" +
                  shippingAmount("standard", 1) +
                  ".00 - Standard (2 - 4 days)"}
              </td>
            </tr>
            <tr>
              <td>Continental USA</td>
              <td>priority</td>
              <td className="pt-2 m-0 s-0 text-left">
                {"$" +
                  shippingAmount("priority", 1) +
                  ".00 - Priority (1 - 2 days)"}
              </td>
            </tr>
            <tr>
              <td>Continental USA</td>
              <td>overnight</td>
              <td className="pt-2 m-0 s-0 text-left">
                {"$" + shippingAmount("overnight", 1) + ".00 - Overnight"}
              </td>
            </tr>
            <tr>
              <td>All Other Locations</td>
              <td></td>
              <td className="pt-2 m-0 s-0 text-left">
              {"$" + shippingAmount("allOther", 1) + ".00 - All Other"}
              </td>
            </tr>
          </MDBTableBody>
        </MDBTable>
      </MDBModalBody>

      <MDBModalFooter>
        <MDBBtn color="primary" onClick={toggle}>
          Close
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default MoreModal;
