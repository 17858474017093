//scr/admin/users/AdminCarts

import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../../state/Context'
import { API, graphqlOperation } from "aws-amplify";
import { subDays, addDays } from 'date-fns'

import AdminModalAddToCart from './AdminModalAddToCart'
import DataTable from '../../../components/DataTable'

import {
  MDBCard,
  MDBCardHeader,
  MDBContainer, 
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBBtn
} from "mdbreact";

import { adminListCart } from '../../../utilities/lists'
import { listProducts } from "../../../graphql/queries";

//===========================================================================
const USERS_CART_FILTER_LIMIT = 50;
const START_DATE = subDays( new Date(), 181 * 4 );
const END_DATE = addDays( new Date(), 1 );
//===========================================================================

export default function AdminCarts({ selectedUser }) {
  //console.log('>>>-AdminCarts-selectedUser->',selectedUser)
  const { state } = useContext(Context)
  const [ dataTableHeaders, setDataTableHeaders ] = useState([]);
  const [ dataTableBody, setDataTableBody ] = useState([]);
  const [ selectedProduct, setSelectedProduct ] = useState({})
  const [ editProduct, setEditProduct ] = useState({})
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isAddProductModalOpen, setIsAddProductModalOpen ] = useState(false)

  useEffect(() => { 
    if (Object.keys(selectedUser).length > 0) {
      getUserProducts(selectedUser.id)
    } else {
      return
    } 
  },[selectedUser])

  const handleSelectedProduct = (item) => {
    //console.log('>>>-AdminCarts-handleSelectedUser-item->',item)
    setSelectedProduct(item)
  }
  const handleEditProduct = (item) => {
    //console.log('>>>-AdminCarts-handleEditUser-item->',item)
    setEditProduct(item)
  }

  const handleMakeOrder = () => {
    console.log(">>>-AdminCarts-handleMakeOrder-selectedUser->",selectedUser);
  }

  const makeCartRows = data => {
    if (data.length > 0) {
      return data.map(item => ({
        
        actions: (
          <React.Fragment>
          <MDBBtn
            color="green"
            size='sm'
            id={item.id}
            onClick={() => handleSelectedProduct(item)}
            style={{ spacing: 1, margin: 1, padding: 1 }}
            disabled={isLoading}
          >
            Select
          </MDBBtn>
          <MDBBtn
            color="red"
            size='sm'
            id={item.id}
            onClick={() => handleEditProduct(item)}
            style={{ spacing: 1, margin: 1, padding: 1 }}
            disabled={isLoading}
          >
            Edit
          </MDBBtn>
          </React.Fragment>
        ),
        quantity: item.quantity,
        productName: item.productName,
        hasAlcohol: item.hasAlcohol,
        addGarnish: item.addGarnish,
        unitPrice: item.unitPrice.toFixed(2),
        couponType: item.couponType,
        couponQuantity: item.couponQuantity,
        couponUnit: item.couponUnit,
        couponLocation: item.couponLocation,
        couponExpiry: item.couponExpiry,
        processState: item.processState,
        status: item.status,
        createdAt: item.createdAt,
        id: item.id
      }))
    } else {
      return {}
    }
  }

  const getUserProducts = async (id) => {
    setIsLoading(true)
    //console.log(">>>-AdminCarts-getUserProducts-selectedUser.id->", id)
    if (state.userIsAuthenticated && state.userIsAdmin) {
      // FOR TESTING
      //"f51bfd31-a1d5-4fa6-9fb9-f2f6266b9ca0  ---@aitoconsulting.com"
      //"01b3fda3-0b03-49f8-931c-337cbbe7abad  ---@christysrumcakes.com"
      //"d805a82a-fd3b-4f10-b600-18d65dfe440e  ---@christyhealth.com"
      // FOR TESTING
      const ModelProductFilterInput = { 
        cognitoId: { eq: id },
        processState: { eq: "carted" },
        createdAt: { between: [START_DATE, END_DATE] } 
      }
      try {
        const result = await API.graphql(graphqlOperation(listProducts, {
          limit:USERS_CART_FILTER_LIMIT,
          filter: ModelProductFilterInput
        }));
        const prodList = result.data.listProducts.items
        //console.log(">>>-AdminCarts-getUserProducts-prodList->", prodList)
        setDataTableHeaders(adminListCart.columns.map(col => col.label))
        setDataTableBody(makeCartRows(prodList))
      } catch (err) {
        setDataTableHeaders([])
        setDataTableBody([])
        alert(`In AdminCarts-listProducts-catch ${err.message}`)
        console.log(">>>-AdminCarts-error fetching listProducts...", err);
      }
    }
    setIsLoading(false)
  }

  return (
    <MDBCard>
      <MDBCardHeader tag="h4" className="text-center font-weight-bold text-uppercase py-2">
        <MDBContainer>
          <MDBRow>

            <MDBCol size="6" md="4">
              <p>Cart</p>
            </MDBCol>

            <MDBCol size="6" md="4">
              <MDBBtn
                color="primary"
                size='lg'
                onClick={() => setIsAddProductModalOpen(!isAddProductModalOpen)}
              >
                Add Product To Cart
              </MDBBtn>
              <AdminModalAddToCart 
                isOpen={isAddProductModalOpen}
                toggle={() => setIsAddProductModalOpen(!isAddProductModalOpen)}
              />
            </MDBCol>

            <MDBCol size="6" md="4">
              <MDBBtn
                color="primary"
                size='lg'
                onClick={handleMakeOrder}
              >
                Purchase Cart Items
              </MDBBtn>
            </MDBCol>

          </MDBRow>
            
        </MDBContainer>
      </MDBCardHeader>
      <MDBCardBody>
        {(Object.keys(selectedUser).length > 0) 
          ? (<div className="container">
              <table className="table">
                <thead>
                  <tr>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Email</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{selectedUser.firstName}</td>
                    <td>{selectedUser.lastName}</td>
                    <td>{selectedUser.email}</td>
                    <td>{selectedUser.phone}</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          ) : (
          <div><h4>Select a user.</h4></div>
          )
        }
        {
          (Object.keys(selectedUser).length > 0 && Object.keys(dataTableBody).length > 0) ? (
            <div>
              <hr/>
              <DataTable 
                dataTableHeaders={dataTableHeaders} 
                dataTableBody={dataTableBody} 
              />
            </div>
          ) : (
            (Object.keys(selectedUser).length > 0 && Object.keys(dataTableBody).length < 1) ? (
              <div><h4>No products in users cart.</h4></div>
            ) : (
              null
            )
          )
        }
      </MDBCardBody>
    </MDBCard>
  )
}