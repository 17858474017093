// src/nav/Routes.js

import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";

import Current from "../admin/dashboard/current/Current";
import History from "../admin/dashboard/history/History";
import Users from "../admin/management/users/Users";
import System from "../admin/management/system/System";

import AccountDetails from "../pages/account/AccountDetails";
import AddressesPage from "../pages/account/AddressesPage";
import Orders from "../pages/shop/Orders";
import Comments from "../pages/shop/Comments";

import SignUp from "../auth/SignUp";
import SignIn from "../auth/SignIn";
import RumCakeMenue from "../pages/RumCakeMenue";

import NotFound from "../nav/NotFound";

//===========================================================================
//===========================================================================

export default function Routes() {
  return (
    <Switch>
      <AdminRoute exact path="/admincurrent" component={Current} />
      <AdminRoute exact path="/adminhistory" component={History} />
      <AdminRoute exact path="/adminusers" component={Users} />
      <AdminRoute exact path="/adminsystem" component={System} />

      <PrivateRoute exact path="/account" component={AccountDetails} />
      <PrivateRoute exact path="/addresses" component={AddressesPage} />
      <PrivateRoute exact path="/orders" component={Orders} />
      <PrivateRoute exact path="/comments" component={Comments} />

      
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/signin" component={SignIn} />
      <Route exact path="/" component={RumCakeMenue} />
      <Route exact path="/rumcakemenue" component={RumCakeMenue} />
      
      <Route path="/not-found" component={NotFound} />

      <Route
        render={function() {
          return <Redirect to="/rumcakemenue" />;
        }}
      />
    </Switch>
  );
}
