//src/admin/management/users/AdminModalAddAddress.ja

import React from "react";

import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

const AdminModalAddAddress = ({ isOpen, toggle }) => {
  //console.log('>>>-AdminModalAddAddress-{ isOpen, toggle }->',{isOpen,toggle})

  return (
    <MDBModal
      size="small"
      isOpen={isOpen}
      toggle={toggle}
      className="modal-notify modal-info text-white"
    >
      <MDBModalHeader>Add Address</MDBModalHeader>

      <MDBModalBody>
        <p>Admin Modal Add Address</p>
      </MDBModalBody>

      <MDBModalFooter>
        <MDBBtn color="primary" onClick={toggle}>
          Close
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default AdminModalAddAddress;
