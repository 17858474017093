//scr/admin/users/AdminConfig

import React from 'react'
//import { Context } from '../../../state/Context'
//import { API, graphqlOperation } from "aws-amplify";

//import { adminListUsers } from '../../utilities/lists'
//import { adminListAddresses } from "../../graphql/custom";


export default function AdminConfig({ selectedUser }) {

  return (
    <div>
      <h1>{ selectedUser.id } in AdminConfig...</h1>
      <p className="mt-2 red">User configs</p>
      <p className="mt-2 red">User status</p>
      <p className="mt-2 red">User comments</p>
      <p className="mt-2 red">User likes</p>
    </div>  
  )
}
