// src/components/DataTable.js

import * as React from 'react';
import Cell from './Cell';
import './DataTable.css';

//===========================================================================
//===========================================================================

export default function DataTable({ dataTableHeaders, dataTableBody }) {
  //console.log('>>>-DataTable-dataTableHeaders->', dataTableHeaders)
  //console.log('>>>-DataTable-dataTableBody->', dataTableBody)

  const renderHeaderRow = (cell, cellIndex) => {
    //console.log('>>>-DataTable-renderHeaderRow->',{cell, cellIndex})
    return (
      <Cell
        key={`heading-${cellIndex}`}
        content={cell}
        header={true}
        fixed={cellIndex === 0}
      />
    )
  };
  
  const renderRow = (row, rowIndex) => {
    //console.log('>>>-DataTable-renderRow->',{row, rowIndex})
    return (
      <tr key={`row-${rowIndex}`}>
        {Object.values(row).map((cell, cellIndex) => {
          //console.log('>>>-DataTable-{rowIndex,cellIndex}->',{rowIndex,cellIndex})
          return (
            <Cell
              key={`${rowIndex}-${cellIndex}`}
              content={cell}
              fixed={cellIndex === 0}
            />
          )
        })}
      </tr>
    )
  };

  const theadMarkup = (
    <tr key="heading">
      {dataTableHeaders.map(renderHeaderRow)}
    </tr>
  );

  const tbodyMarkup = dataTableBody.map(renderRow);
    
  return (
    <table className="Table">
      <thead>{theadMarkup}</thead>
      <tbody>{tbodyMarkup}</tbody>
    </table>
  );
}
