// src/auth/SignIn.js

import React, { useState, useContext } from 'react'
import { Context } from '../state/Context'
import { Auth } from 'aws-amplify'
import { API, graphqlOperation } from 'aws-amplify'
import { subDays, addDays } from 'date-fns'

import { getUser, listProducts, listOrders } from '../graphql/queries'

import { validations } from '../utilities/validations.js'

import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModalFooter,
  MDBBtn,
  MDBInput,
  MDBIcon
} from 'mdbreact'

//===========================================================================
//===========================================================================

const SignIn = props => {
  // console.log('>>>-SignIn-props->', props)
  const { state, dispatch } = useContext(Context)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isSubmitting, setIsSubmitting] = useState('')
  const [formErrors, setFormErrors] = useState(null)

  const startDate = subDays( new Date(), 181 * 4 )
  const endDate = addDays( new Date(), 1 )

  // get user details from Async
  const getUserDetailsAsync = async id => {
    try {
      const input = { id: id }
      const result = await API.graphql(graphqlOperation(getUser, input))
      //console.log('>>>-SignIn-getUserDetailsAsync-result.data.getUser->',result.data.getUser)
      dispatch({ type: 'USER_SIGN_IN_DETAILS', payload: result.data.getUser })
      return 
    } catch (err) {
      if (state.userIsAuthenticated === true) {
        dispatch({ type: 'USER_SIGN_IN_DETAILS_FAILURE', payload: {} })
        alert(err.message)
    }
      console.log('error fetching user details...', err)
      dispatch({ type: 'USER_SIGN_IN_DETAILS_FAILURE', payload: {} })
    }
  }

  const getUserCart = async (id) => {
    //console.log('>>>-SignIn-getUserCart-id->',id)
    if (state.userIsAuthenticated === true) {
      //console.log('>>>-state.userIsAuthenticated is true->',state.userIsAuthenticated)
      const ModelProductFilterInput = { 
        cognitoId: { eq: id },
        processState: { eq: "carted" },
        createdAt: { between: [startDate, endDate] } 
      }
      try {
        const result = await API.graphql(graphqlOperation(
          listProducts,{
            limit: 100,
            filter: ModelProductFilterInput
          }
          ));
        const cartItems = result.data.listProducts.items;
        dispatch({ type: "INITIALIZE_USER_CART", payload: cartItems });
      } catch (err) {
        if (state.userIsAuthenticated === true) {
          dispatch({ type: "INITIALIZE_USER_CART", payload: [] });
          //handleSignOut()
        }
        console.log(">>>-RumCakeMenue-error fetching getCart...", err);
      }
    } else {
      dispatch({ type: "INITIALIZE_USER_CART", payload: [] });
    }
  }
  const getUserOrders = async (id) => {
    //console.log('>>>-SignIn-getUserOrders-id->',id)
    if (state.userIsAuthenticated === true) {
      //console.log('>>>-state.userIsAuthenticated is true->',state.userIsAuthenticated)
      try {
        const ModelOrderFilterInput = { 
          cognitoId: { eq: id },
          processState: { eq: "ordered" },
          createdAt: { between: [startDate, endDate] } 
        }
        const result = await API.graphql(graphqlOperation(
          listOrders, {
            limit: 100,
            filter: ModelOrderFilterInput
          }));
        const orderItems = result.data.listOrders.items;
        //console.log('>>>-RumCakeMenue-getOrders-orderItems->', orderItems)
        dispatch({ type: "LOAD_ORDER_BOOK", payload: orderItems });
      } catch (err) {
        if (state.userIsAuthenticated === true) {
          dispatch({ type: "LOAD_ORDER_BOOK", payload: [] })
          //alert(err.message)
          //alert('Please signin to start a new session.')
          //handleSignOut()
        }
        console.log(">>>-RumCakeMenue-error fetching getAddress...", err);
      }
    } else {
      //console.log('>>>-state.userIsAuthenticated is NOT true->',state.userIsAuthenticated)
      dispatch({ type: "LOAD_ORDER_BOOK", payload: [] });
    }
  }
  const getUserBillingAddress = async (id) => {
    console.log('>>>-SignIn-getUserBillingAddress-id->',id)
  }
  const getUserShippingAddress = async (id) => {
    console.log('>>>-SignIn-getUserShippingAddress-id->',id)
  }

  // sign in and get userDetails from Auth
  const handleSignIn = async event => {
    event.preventDefault()
    setIsSubmitting(true)
      try {
        const user = await Auth.signIn(email, password)
        dispatch({
          type: 'USER_SIGN_IN_SESSION',
          payload: user.signInUserSession.accessToken
        })
        //console.log('>>>-SignIn-handleSignIn-user->',user)
        const userGroups = user.signInUserSession.idToken.payload['cognito:groups']
        if (userGroups) {
          const result = userGroups.find(group => group === 'Admin')
          if (result === "Admin") {
            dispatch({ type: 'USER_SIGN_IN_ADMIN', payload: true })
          } else {
            dispatch({ type: 'USER_SIGN_IN_ADMIN', payload: false })
          } 
        } else {
          dispatch({ type: 'USER_SIGN_IN_ADMIN', payload: false })
        }
        getUserDetailsAsync(user.attributes.sub)
  
        getUserCart(user.attributes.sub)
        getUserOrders(user.attributes.sub)
        getUserBillingAddress(user.attributes.sub)
        getUserShippingAddress(user.attributes.sub)

        setFormErrors(null)
        setIsSubmitting(false)
        return props.history.push('/rumcakemenue')
      } catch (err) {
        if (state.userIsAuthenticated === true) {
          dispatch({ type: 'USER_SIGN_IN_SESSION', payload: {} })
          dispatch({ type: 'USER_SIGN_IN_ADMIN', payload: false })
          alert(err.message)
        }
        setFormErrors(err)
        dispatch({ type: 'USER_SIGN_IN_SESSION', payload: {} })
        dispatch({ type: 'USER_SIGN_IN_ADMIN', payload: false })
        console.log('>>>-SignIn-formErrors->', formErrors)
        setIsSubmitting(false)
      }
  }

  return (
    <main style={{ margin: '0 1%', paddingTop: "6rem" }}>
      <MDBCard
        className='my-2 px-2 mx-auto pink lighten-5'
        style={{ fontWeight: 300, maxWidth: '400px' }}
      >
        <MDBCardBody style={{ paddingTop: 0 }}>
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <form onSubmit={handleSignIn}>
                  <h2 className='h1-responsive font-weight-bold text-center my-5'>
                    Sign In
                  </h2>

                  <div className='grey-text'>
                    <MDBInput
                      label='Your email'
                      name='email'
                      type='email'
                      required
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                      className={
                        email && email.match(validations.EMAIL)
                          ? 'is-valid'
                          : 'is-invalid'
                      }
                    >
                      <div className='valid-feedback'>Valid email.</div>
                      <div className='invalid-feedback'>
                        Enter a valid email.
                      </div>
                    </MDBInput>
                    <MDBInput
                      label='Your password'
                      name='password'
                      type='password'
                      required
                      value={password}
                      onChange={event => setPassword(event.target.value)}
                      className={
                        password && password.match(validations.PASSWORD)
                          ? 'is-valid'
                          : 'is-invalid'
                      }
                    >
                      <div className='valid-feedback'>
                        Enter a valid password.
                      </div>
                      <div className='invalid-feedback'>
                        Enter a valid password. Minimum 8 characters,
                        <p>
                          one capital letter, one number, and one of
                          !,@,#,$,%,^,&,*,(,).
                        </p>
                      </div>
                    </MDBInput>
                  </div>

                  <div className='text-center'>
                    <MDBBtn
                      type='submit'
                      color='primary'
                      disabled={!email || !password}
                    >
                      {isSubmitting ? (
                        <MDBIcon icon='spinner' pulse size='1x' fixed />
                      ) : (
                        'Sign In'
                      )}
                    </MDBBtn>
                  </div>
                </form>
                <MDBModalFooter>
                  <div className='font-weight-light'>
                    <p>
                      Not a member?
                      <a href='/signup' className='blue-text ml-1'>
                        Sign up!
                      </a>
                    </p>
                    <p>
                      <a href='/forgot-password' className='blue-text ml-1'>
                        Forgot Password?
                      </a>
                    </p>
                  </div>
                </MDBModalFooter>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCardBody>
      </MDBCard>
    </main>
  )
}

export default SignIn
