// src/pages/account/AccountDetails.js

import React, { useState, useEffect, useContext } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { Context } from '../../state/Context'

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBIcon,
  MDBCard,
  MDBCardBody
} from 'mdbreact'

import { getUser } from '../../graphql/queries'
import { updateUser } from '../../graphql/mutations'

//===========================================================================
//===========================================================================

const AccountDetails = props => {
  const { state, dispatch } = useContext(Context)
  //console.log('>>>-AccountDetails-state.userDetails->',state.userDetails)

  const [userAsync, setUserAsync] = useState({})
  const [nickname, setNickname] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [ofAge, setOfAge] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => { getUserDetailsAsync(state.userDetails.id) }, [])

  // get user details from Async
  const getUserDetailsAsync = async id => {
    try {
      const input = { id: id }
      const result = await API.graphql(graphqlOperation(getUser, input))
      //console.log('>>>-SignIn-getUserDetailsAsync-result.data.getUser->',result.data.getUser)
      if (result.data.getUser) {
        dispatch({ type: 'USER_ACCOUNT_DETAILS', payload: result.data.getUser })
        setUserAsync(result.data.getUser)
        setNickname(result.data.getUser.nickname)
        setFirstName(result.data.getUser.firstName)
        setLastName(result.data.getUser.lastName)
        setPhone(result.data.getUser.phone)
        setOfAge(result.data.getUser.ofAge)
      } 
    } catch (err) {
      if (state.userIsAuthenticated === true) {
        dispatch({ type: 'USER_ACCOUNT_DETAILS_FAILURE', payload: {} })
        alert(err.message)
    }
      console.log('error fetching user details...', err)
      dispatch({ type: 'USER_ACCOUNT_DETAILS_FAILURE', payload: {} })
    }
  }

  const handleUpdateUserDetails = async event => {
    event.preventDefault()
    setIsSubmitting(true)
    //console.log('>>>-AccountDetails-handleUpdateUserDetails->',{nickname,firstName,lastName,phone,ofAge})
    const input = {
      // DO NOT EDIT in the form
      id: state.userDetails.id,
      // expectedVersion: state.userDetails.version,
      cognitoID: userAsync.cognitoID,
      chId: userAsync.chId,
      usernameAWS: userAsync.usernameAWS,
      enabled: userAsync.enabled,
      userStatus: userAsync.userStatus,
      ownerAWS: userAsync.ownerAWS,
      email: userAsync.email,
      registered: userAsync.registered,
      tandCs: userAsync.tandCs,
      // EDIT in the form
      nickname: (nickname === '' ? 'missing' : nickname),
      firstName: (firstName === '' ? 'missing' : firstName),
      lastName: (lastName === '' ? 'missing' : lastName),
      phone: (phone === '' ? 'missing' : phone),
      ofAge: ofAge
    }
    //console.log(">>>-AccountDetails-handleUpdateUserDetails-input->",input)
    await API.graphql(graphqlOperation(updateUser, { input }))
    setIsSubmitting(false)
    return props.history.push('/rumcakemenue')
  }

  const handleCheck = () => {
    if (ofAge) {setOfAge(false)} else {setOfAge(true)}
  }

  const handleResetDetails = event => {
    event.preventDefault()
    getUserDetailsAsync(state.userDetails.id)
  }


  return (
    <main style={{ margin: '0 1%', paddingTop: '6rem' }}>
      <MDBCard
        className='my-2 px-2 mx-auto teal lighten-5'
        style={{ fontWeight: 300, maxWidth: '500px' }}
      >
        <MDBCardBody style={{ paddingTop: 0 }}>
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <form onSubmit={handleUpdateUserDetails}>
                  <h2 className='h1-responsive font-weight-bold text-center mt-5'>
                    Account Details
                  </h2>
                  <MDBInput
                    label='Nickname (optional)'
                    type='text'
                    outline
                    value={nickname === 'missing' ? '' : nickname}
                    onChange={e => setNickname(e.target.value.toString())}
                  />
                  <MDBInput
                    label='First Name'
                    type='text'
                    outline
                    value={firstName === 'missing' ? '' : firstName}
                    onChange={e => setFirstName(e.target.value)}
                  />
                  <MDBInput
                    label='Last Name'
                    type='text'
                    outline
                    value={lastName === 'missing' ? '' : lastName}
                    onChange={e => setLastName(e.target.value)}
                  />
                  <MDBInput
                    label='Phone'
                    type='text'
                    outline
                    value={phone === 'missing' ? '' : phone}
                    onChange={e => setPhone(e.target.value.toString())}
                  />
                  <p />
                  <MDBInput 
                    label="We only sell to you if you are 18 or older." 
                    type="checkbox" 
                    id="ofAge"
                    checked={ofAge}
                    //value={ofAge}
                    onChange={() => handleCheck()}
                  />
                  
                  <div className='text-center mt-4'>
                    <MDBBtn color='primary' type='submit'>
                      {isSubmitting ? (
                        <MDBIcon icon='spinner' pulse size='1x' fixed />
                      ) : (
                        'Update Details'
                      )}
                    </MDBBtn>
                    <MDBBtn color='secondary' onClick={handleResetDetails}>
                      Reset Details
                    </MDBBtn>
                  </div>

                </form>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCardBody>
      </MDBCard>
    </main>
  )
}

export default AccountDetails
