//src/pages/Footer.js

import React, { useState } from "react";

import SalesPoliciesModal from "./SalesPoliciesModal";
import PricesModal from "./PricesModal";
import DeliveriesModal from "./DeliveriesModal";
import TermsOfUseModal from "./TermsOfUseModal";
import PrivacyPoliciesModal from "./PrivacyPoliciesModal";
//import LegalModal from './LegalModal'

import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBFooter,
  MDBBtn,
  MDBBtnGroup,
} from "mdbreact";

//===========================================================================
//===========================================================================

export default function Footer() {
  const [isSalesPoliciesOpen, setIsSalesPoliciesOpen] = useState(false);
  const [isPricesModalOpen, setIsPricesModalOpen] = useState(false);
  const [isDeliveriesModalOpen, setIsDeliveriesModalOpen] = useState(false);
  const [isTermsOfUseModalOpen, setIsTermsOfUseModalOpen] = useState(false);
  const [isPrivacyPoliciesModalOpen, setIsPrivacyPoliciesModalOpen] = useState(false);
  //const [ isLegalModalOpen, setIsLegalModalOpen ] = useState(false)

  return (
    <MDBFooter color="pink darken-1" className="font-small pt-4 mt-4">
      <MDBContainer className="text-center text-md-left">
        <MDBRow className="text-center text-md-left mt-3 pb-3">
          <MDBCol md="3" lg="3" xl="3" className="mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">
              Christy&apos;s Rum Cakes
            </h6>
            <p>
              We have generations of rum cake expertise and the perfect mix of
              top quality ingredients, imagination, and love. You will taste it
              in every bite.
            </p>
          </MDBCol>
          <hr className="w-100 clearfix d-md-none" />
          <MDBCol md="2" lg="2" xl="2" className="mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">
              Sales & Shipping
            </h6>
            <MDBBtnGroup vertical>
              <MDBBtn
                size="sm"
                color="pink darken-4"
                onClick={() => {
                  setIsPricesModalOpen(!isPricesModalOpen);
                }}
              >
                Prices
              </MDBBtn>
              <PricesModal
                isOpen={isPricesModalOpen}
                toggle={() => {
                  setIsPricesModalOpen(!isPricesModalOpen);
                }}
              />
              <MDBBtn
                size="sm"
                color="pink darken-4"
                onClick={() => {
                  setIsSalesPoliciesOpen(!isSalesPoliciesOpen);
                }}
              >
                Sales Policies
              </MDBBtn>
              <SalesPoliciesModal
                isOpen={isSalesPoliciesOpen}
                toggle={() => {
                  setIsSalesPoliciesOpen(!isSalesPoliciesOpen);
                }}
              />
              <MDBBtn
                size="sm"
                color="pink darken-4"
                onClick={() => {
                  setIsDeliveriesModalOpen(!isDeliveriesModalOpen);
                }}
              >
                Deliveries
              </MDBBtn>
              <DeliveriesModal
                isOpen={isDeliveriesModalOpen}
                toggle={() => {
                  setIsDeliveriesModalOpen(!isDeliveriesModalOpen);
                }}
              />
            </MDBBtnGroup>
          </MDBCol>
          <hr className="w-100 clearfix d-md-none" />
          <MDBCol md="3" lg="2" xl="2" className="mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">
              Legal Stuff
            </h6>
            <MDBBtnGroup vertical>
              <MDBBtn
                size="sm"
                color="pink darken-4"
                onClick={() => {
                  setIsTermsOfUseModalOpen(!isTermsOfUseModalOpen);
                }}
              >
                Terms of Use
              </MDBBtn>
              <TermsOfUseModal
                isOpen={isTermsOfUseModalOpen}
                toggle={() => {
                  setIsTermsOfUseModalOpen(!isTermsOfUseModalOpen);
                }}
              />
              <MDBBtn
                size="sm"
                color="pink darken-4"
                onClick={() => {
                  setIsPrivacyPoliciesModalOpen(!isPrivacyPoliciesModalOpen);
                }}
              >
                Privacy Policies
              </MDBBtn>
              <PrivacyPoliciesModal
                isOpen={isPrivacyPoliciesModalOpen}
                toggle={() => {
                  setIsPrivacyPoliciesModalOpen(!isPrivacyPoliciesModalOpen);
                }}
              />
              {/* <MDBBtn 
                size='sm' 
                color="mdb-color"
                onClick={() => {setIsLegalModalOpen( !isLegalModalOpen )}}
              >Legal</MDBBtn>
                <LegalModal 
                  isOpen={isLegalModalOpen} 
                  toggle={() => {setIsLegalModalOpen( !isLegalModalOpen )}}
                /> */}
            </MDBBtnGroup>
          </MDBCol>
          <hr className="w-100 clearfix d-md-none" />
          <MDBCol md="4" lg="3" xl="3" className="mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
            <p>
              <i className="fa fa-home mr-3" /> Secaucus, NJ 07094, USA
            </p>
            <p>
              <i className="fa fa-envelope mr-3" /> christy@christysrumcakes.com
            </p>
            <p>
              <i className="fa fa-phone mr-3" /> +1 212 752 3363
            </p>
            <p>
              <i className="fa fa-print mr-3" /> +1 646 485 8799
            </p>
          </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow className="d-flex align-items-center">
          <MDBCol md="8" lg="8">
            <p className="text-center text-md-left white-text">
              &copy; {new Date().getFullYear()} Copyright:{" "}
              <a href="https://www.christysrumcakes.com">
                {" "}
                christysrumcakes.com{" "}
              </a>
            </p>
          </MDBCol>
          <MDBCol md="4" lg="4" className="ml-lg-0">
            <div className="text-center text-md-right">
              <ul className="list-unstyled list-inline">
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/christysrumcakes/"
                    target="blank"
                    className="btn-floating btn-sm rgba-white-slight mx-1"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="#!"
                    className="btn-floating btn-sm rgba-white-slight mx-1"
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="#!"
                    className="btn-floating btn-sm rgba-white-slight mx-1"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="#!"
                    className="btn-floating btn-sm rgba-white-slight mx-1"
                  >
                    <i className="fab fa-google-plus" />
                  </a>
                </li>
              </ul>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBFooter>
  );
}
