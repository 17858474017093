// src/state/Reducer.js

export function Reducer(state, { type, payload }) {
  //console.log('>>>-Reducer-state->', state)
  //console.log('>>>-Reducer-type->', type)
  //console.log('>>>-Reducer-payload->', payload)
  switch (type) {
    case "USER_SIGN_UP_UNCONFIRMED":
      return {
        ...state,
        userDetails: payload,
        stateState: "sign up unconfirmed",
        userIsAdmin: false,
        userIsKnown: true,
        userIsAuthenticated: false,
        userIsSubcribed: false,
      };
    case "USER_SIGN_UP_FAILURE":
      return {
        ...state,
        userDetails: payload,
        stateState: "sign up failure",
        userIsAdmin: false,
        userIsKnown: false,
        userIsAuthenticated: false,
        userIsSubcribed: false,
      };
    case "USER_SIGN_UP_CONFIRMED":
      return {
        ...state,
        userDetails: payload,
        stateState: "sign up confirmed",
        userIsAdmin: false,
        userIsKnown: true,
        userIsAuthenticated: false,
        userIsSubcribed: false,
      };
    case "USER_SIGN_UP_CREATE_DETAILS":
      return {
        ...state,
        userDetails: payload,
        stateState: "appsync user details created",
      };
    case "USER_SIGN_UP_CREATE_DETAILS_FAILURE":
    return {
      ...state,
      userDetails: payload,
      stateState: "appsync user details failed",
      userIsAdmin: false,
      userIsKnown: false,
      userIsAuthenticated: false,
      userIsSubcribed: false,
    };

    case "USER_SIGN_IN_SESSION":
      return {
        ...state,
        userSession: payload,
        stateState: "signed in",
        userIsKnown: true,
        userIsAuthenticated: true,
      };
    
    case "USER_SIGN_IN_DETAILS":
      return {
        ...state,
        userDetails: payload,
        stateState: "sign in SUCCESS",
        userIsKnown: true,
        userIsAuthenticated: true,
      };
      case "USER_SIGN_IN_DETAILS_FAILURE":
      return {
        ...state,
        userDetails: payload,
        stateState: "sign in FAILURE",
        userIsAdmin: false,
        userIsKnown: false,
        userIsAuthenticated: false,
      };
      case "USER_SIGN_IN_ADMIN":
      return {
        ...state,
        userIsAdmin: payload,
        stateState: "signed in",
        userIsKnown: true,
        userIsAuthenticated: true,
      };

    case "USER_SIGN_OUT":
      return {
        ...state,
        userSession: payload,
        userDetails: payload,
        userCart: [],
        userOrders: [],
        stateState: "signed out",
        userIsKnown: false,
        userIsAuthenticated: false,
        userIsSubcribed: false,
        userIsAdmin: false,
      };

    case "USER_FORGOT_PASSWORD":
      return {
        ...state,
        userSession: payload,
        stateState: "forgot password",
        userForgotPassword: true,
        userIsAuthenticated: false,
      };

    case "USER_FORGOT_PASSWORD_CONFIRM":
      return {
        ...state,
        userSession: payload,
        stateState: "forgot password confirmed",
        userForgotPassword: false,
        userIsAuthenticated: true,
      };

    case "USER_CHANGE_EMAIL":
      return {
        ...state,
        userSession: payload,
        stateState: "change email",
        userChangeEmail: true,
      };

    case "USER_CHANGE_EMAIL_CONFIRM":
      return {
        ...state,
        userSession: payload,
        stateState: "change email confirmed",
        userChangeEmail: false,
        userIsAuthenticated: true,
      };

    case "USER_ACCOUNT_DETAILS":
      return {
        ...state,
        userDetails: payload,
        stateState: "account details update",
        userIsKnown: true,
        userIsAuthenticated: true,
      };
    case "USER_ACCOUNT_DETAILS_FAILURE":
      return {
        ...state,
        userDetails: payload,
        stateState: "account details FAILURE",
        userIsAdmin: false,
        userIsKnown: false,
        userIsAuthenticated: false,
      };

    case "SELECTED_BILLING_ADDRESS":
      return {
        ...state,
        billingAddress: payload,
        stateState: "select billing address",
      };
    case "SELECTED_SHIPPING_ADDRESS":
      return {
        ...state,
        shippingAddress: payload,
        stateState: "select shipping address",
      };

    case "INITIALIZE_USER_CART":
      return {
        ...state,
        userCart: payload,
        stateState: "initialize cart",
      };
    case "LOAD_CART_PRODUCTS":
      return {
        ...state,
        userCart: payload,
        stateState: "loaded cart items",
      };
    case "REMOVE_CART_PRODUCT":
      const removeItem = payload;
      const keepItems = state.userCart.filter(
        item => item.id !== removeItem.id
      );
      return {
        ...state,
        userCart: keepItems,
        stateState: "removed cart item",
      };
    case "UPDATE_CART_PRODUCT":
      const updatedItem = payload;
      const unchangedItems = state.userCart.filter(
        item => item.id !== updatedItem.id
      );
      return {
        ...state,
        userCart: [...unchangedItems, updatedItem],
        stateState: "removed cart item",
      };
    case "PURCHASED_CART_PRODUCTS":
      return {
        ...state,
        userCart: payload,
        stateState: "purchased cart items",
      };

    case "LOAD_ORDER_BOOK":
      return {
        ...state,
        userOrders: payload,
        stateState: "new order",
      };
    case "UPDATE_ORDER_BOOK":
      return {
        ...state,
        userOrders: payload,
        stateState: "update order",
      };

    default:
      return state;
  }
}
