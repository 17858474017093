//scr/admin/users/AdminOrders

import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../../state/Context'
import { API, graphqlOperation } from "aws-amplify";
import { subDays, addDays } from 'date-fns'

import AdminModalUpdateOrderStatus from './AdminModalUpdateOrderStatus'
import AdminModalEditOrder from './AdminModalEditOrder'
import DataTable from '../../../components/DataTable'

import {
  MDBCard,
  MDBCardHeader,
  MDBContainer, 
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBBtn
} from "mdbreact";

import { adminListOrder } from '../../../utilities/lists'
import { listOrders } from "../../../graphql/queries";

//===========================================================================
const USERS_ORDER_FILTER_LIMIT = 50;
const START_DATE = subDays( new Date(), 181 * 4 );
const END_DATE = addDays( new Date(), 1 );
//===========================================================================

export default function AdminOrders({ selectedUser }) {
  //console.log('>>>-AdminOrders-selectedUser.id->',selectedUser.id)
  const { state } = useContext(Context)
  const [ userOrders, setUserOrders ] = useState([])
  const [ dataTableHeaders, setDataTableHeaders ] = useState([]);
  const [ dataTableBody, setDataTableBody ] = useState([]);
  
  const [ selectedOrder, setSelectedOrder ] = useState({})
  const [ editOrder, setEditOrder ] = useState({})
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isUpdateOrderStatusModalOpen, setIsUpdateOrderStatusModalOpen ] = useState(false)
  const [ isEditOrderModalOpen, setIsEditOrderModalOpen ] = useState(false)

  useEffect(() => { 
    if (Object.keys(selectedUser).length > 0) {
      getUserOrders(selectedUser.id)
    } else {
      return
    } 
  },[selectedUser])

  const handleSelectedOrder = (item) => {
    //console.log('>>>-AdminOrders-handleSelectedUser-id->',id)
    setSelectedOrder(item)
  }
  const handleEditOrder = (item) => {
    //console.log('>>>-AdminOrders-handleEditUser-id->',id)
    setEditOrder(item)
  }

  function makeOrderRows(data) {
    if (data.length > 0) {
      return data.map(item => ({
        actions: (
          <React.Fragment>
            <MDBBtn
              color="green"
              size='sm'
              id={item.id}
              onClick={() => handleSelectedOrder(item)}
              style={{ spacing: 1, margin: 1, padding: 1 }}
              disabled={isLoading}
            >
              Select
            </MDBBtn>
            <MDBBtn
              color="red"
              size='sm'
              id={item.id}
              onClick={() => handleEditOrder(item)}
              style={{ spacing: 1, margin: 1, padding: 1 }}
              disabled={isLoading}
            >
              Edit
            </MDBBtn>
          </React.Fragment>
        ),
        quantity: item.quantity,
        total: item.total,
        discount: item.discount,
        shipMethod: item.shipMethod,
        shipper: item.shipper,
        stripeStatus: item.stripeStatus,
        processState: item.processState,
        status: item.status,
        createdAt: item.createdAt,
        billingAddressId: item.billingAddressId,
        shippingAddressId: item.shippingAddressId,
        id: item.id
      }))
    } else {
      return {}
    }
  }

  const getUserOrders = async (id) => {
    setIsLoading(true)
    //console.log(">>>-getUserOrders-selectedUser.id->", id)
    if (state.userIsAuthenticated && state.userIsAdmin) {
      //"f51bfd31-a1d5-4fa6-9fb9-f2f6266b9ca0  ---@aitoconsulting.com"
      //"01b3fda3-0b03-49f8-931c-337cbbe7abad  ---@christysrumcakes.com"
      //"d805a82a-fd3b-4f10-b600-18d65dfe440e  ---@christyhealth.com"
      const ModelOrderFilterInput = { 
        cognitoId: { eq: id },
        processState: { eq: "ordered" },
        createdAt: { between: [START_DATE, END_DATE] } 
      }
      try {
        const result = await API.graphql(graphqlOperation(listOrders, {
          limit:USERS_ORDER_FILTER_LIMIT,
          filter: ModelOrderFilterInput
        }));
        const orderList = result.data.listOrders.items
        //console.log(">>>-AdminOrders-getUserOrders-orderList->", orderList)
        setDataTableHeaders(adminListOrder.columns.map(col => col.label))
        setDataTableBody(makeOrderRows(orderList))
      } catch (err) {
        alert(`In AdminOrders-listOrders-catch ${err.message}`)
        setDataTableHeaders([])
        setDataTableBody([])
        console.log(">>>-AdminOrders-error fetching listOrders...", err);
      }
    }
    setIsLoading(false)
  }



  

  return (
    <MDBCard>
      <MDBCardHeader tag="h4" className="text-center font-weight-bold text-uppercase py-2">
        <MDBContainer>
          <MDBRow>

            <MDBCol size="6" md="4">
              <p>Orders</p>
            </MDBCol>

            <MDBCol size="6" md="4">
              <MDBBtn
                color="primary"
                size='lg'
                onClick={() => setIsEditOrderModalOpen(!isEditOrderModalOpen)}
              >
                Edit Order Details
              </MDBBtn>
              <AdminModalEditOrder 
                isOpen={isEditOrderModalOpen}
                toggle={() => setIsEditOrderModalOpen(!isEditOrderModalOpen)}
              />
              </MDBCol>

              <MDBCol size="6" md="4">
              <MDBBtn
                color="primary"
                size='lg'
                onClick={() => setIsUpdateOrderStatusModalOpen(!isUpdateOrderStatusModalOpen)}
              >
                Update Order Status
              </MDBBtn>
              <AdminModalUpdateOrderStatus 
                isOpen={isUpdateOrderStatusModalOpen}
                toggle={() => setIsUpdateOrderStatusModalOpen(!isUpdateOrderStatusModalOpen)}
              />
            </MDBCol>

          </MDBRow>
            
        </MDBContainer>
      </MDBCardHeader>
      <MDBCardBody>
        {(Object.keys(selectedUser).length > 0) 
          ? (<div className="container">
              <table className="table">
                <thead>
                  <tr>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Email</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{selectedUser.firstName}</td>
                    <td>{selectedUser.lastName}</td>
                    <td>{selectedUser.email}</td>
                    <td>{selectedUser.phone}</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          ) : 
          (
          <div><h4>Select a user.</h4></div>
          )
        }
        {(Object.keys(selectedUser).length > 0 && Object.keys(dataTableBody).length > 0) 
          ? (<div>
              <hr/>

              <DataTable 
                dataTableHeaders={dataTableHeaders} 
                dataTableBody={dataTableBody} 
              />
            </div>
          ) : (
            (Object.keys(selectedUser).length > 0 && Object.keys(dataTableBody).length < 1) ? (
              <div><h4>No orders.</h4></div>
            ) : (
              null
            )
          )
        }
        
        <p className="mt-2 red">User orders by status</p>
        <p className="mt-2 red">Manage orders from status to status</p>
        <p className="mt-2 red">disable buttons given conditions</p>
        <p className="mt-2 red">Manage orders</p>

      </MDBCardBody>
    </MDBCard>
  )
}