// src/components/PromoCouponCode.js

import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Context } from "../state/Context";

import { MDBCard, MDBCol, MDBInput, MDBBtn } from "mdbreact";

import { cakeList } from '../utilities/lists'
import { dateDiffInDays, discount } from '../utilities/functions'

import { updateProduct } from '../graphql/mutations'

//===========================================================================
//===========================================================================

export default function PromoCouponCode ({ cartProducts, setCartProducts }) {
    const { dispatch } = useContext(Context);
    //console.log('>>>-PromoCouponCode-cartProducts->', cartProducts)
    //console.log('>>>-PromoCouponCode-setCartProducts->', setCartProducts)
    const [ couponCode, setCouponCode ] = useState('')
    const [ crcCoupon, setCrcCoupon ] = useState([])
    const [ daysToExpiry, setDaysToExpiry ] = useState(null)
    const [ isValidCoupon, setIsValidCoupon ] = useState(false)    
    const [ lineItemCoupon, setLineItemCoupon ] = useState([])
    const [ couponAlreadyUsed, setCouponAlreadyUsed ] = useState(false)
    const [ feedbackToUser, setFeedbackToUser ] = useState("Enter a coupon ID and we’ll validate it.")

    const [ isSubmitting, setIsSubmitting ] = useState(null)

    useEffect(() => { testOurCoupon() },[couponCode])
    useEffect(() => { testCouponExpiry() },[crcCoupon,crcCoupon])
    useEffect(() => { testCouponValidation() },[couponCode,crcCoupon,daysToExpiry,lineItemCoupon,couponAlreadyUsed])
    useEffect(() => { getCouponLineItem() },[couponCode,crcCoupon,daysToExpiry,isValidCoupon,couponAlreadyUsed])
    useEffect(() => { userFeedback() },[couponCode,crcCoupon,daysToExpiry,isValidCoupon,lineItemCoupon,couponAlreadyUsed])

    const testOurCoupon = () => {
        //console.log('>>>-PromoCouponCode-testOurCoupon-couponCode->',couponCode)
        if (couponCode.length > 0) {
            const weKnowIt = cakeList.filter( item => {
                if (item.coupon.couponID === couponCode.toString()) {
                    return item
                }
            })
            //console.log('>>>-PromoCouponCode-testOurCoupon-weKnowIt->', weKnowIt)
            if (weKnowIt.length === 0) {
                setCrcCoupon([])
                setDaysToExpiry(null)
                setIsValidCoupon(false)
                setLineItemCoupon([])
                setCouponAlreadyUsed(false)
            } else {
                setCrcCoupon(weKnowIt)
            }
        }
    }
    const testCouponExpiry = () => {
        //console.log('>>>-PromoCouponCode-testCouponExpiry-crcCoupon->', crcCoupon)
        if (crcCoupon.length > 0) {
            const daysToExpiry = dateDiffInDays((new Date()), (new Date(crcCoupon[0].coupon.couponExpiry)))
            setDaysToExpiry(daysToExpiry)
        }
    }
    const testCouponValidation = () => {
        //console.log('>>>-PromoCouponCode-testCouponValidation-daysToExpiry->', daysToExpiry)
        if (crcCoupon.length > 0 && daysToExpiry >= 0) {
            setIsValidCoupon(true)
        } else if (crcCoupon.length > 0 && daysToExpiry < 0) {
            setIsValidCoupon(false)
        } 
    }
    const getCouponLineItem = () => {
        //console.log('>>>-PromoCouponCode-getCouponLineItem-isValidCoupon->', isValidCoupon)
        if (isValidCoupon && crcCoupon.length > 0 && daysToExpiry >= 0) {
            const matchItem = cartProducts.filter( item => {
                if (item.productName === crcCoupon[0].name) {
                    if (item.couponID === crcCoupon[0].coupon.couponID) {
                        setCouponAlreadyUsed(true)
                    } else {
                        setCouponAlreadyUsed(false)
                    }
                    setLineItemCoupon(item)
                    return item
                }
            })
            setLineItemCoupon(matchItem)
            //console.log('>>>-PromoCouponCode-getCouponLineItem-matchItem->',matchItem.length)
        }
    }
    const handleApplyCoupon = async (event) => {
        event.preventDefault()
        setIsSubmitting(true)
        //console.log('>>>-PromoCouponCode-handleApplyCoupon-lineItemCoupon.length->', lineItemCoupon.length)
        //console.log('>>>-PromoCouponCode-handleApplyCoupon-couponCode.length->', couponCode.length)
        //console.log('>>>-PromoCouponCode-handleApplyCoupon-crcCoupon.length->', crcCoupon.length)
        //console.log('>>>-PromoCouponCode-handleApplyCoupon-daysToExpiry->', daysToExpiry)
        //console.log('>>>-PromoCouponCode-handleApplyCoupon-!couponAlreadyUsed->', !couponAlreadyUsed)
        if (couponCode.length > 0 && 
            crcCoupon.length > 0  && 
            daysToExpiry >= 0 && 
            lineItemCoupon.length > 0 
            && !couponAlreadyUsed) {
            //console.log('>>>-PromoCouponCode-handleApplyCoupon-couponExpiry->', (crcCoupon[0].coupon.couponExpiry) + 'T00:00:00.00Z')
            const updateInput = {
                id: lineItemCoupon[0].id,
                expectedVersion: lineItemCoupon[0].version,
                cognitoId: lineItemCoupon[0].cognitoId,
                chId: lineItemCoupon[0].chId,
                usernameAWS: lineItemCoupon[0].usernameAWS,
                ownerAWS: lineItemCoupon[0].ownerAWS,
                productName: lineItemCoupon[0].productName,
                quantity: lineItemCoupon[0].quantity,
                unitPrice: lineItemCoupon[0].unitPrice,

                couponType: crcCoupon[0].coupon.couponType,
                couponLocation: crcCoupon[0].coupon.couponLocation,
                couponID: crcCoupon[0].coupon.couponID,
                couponUnit: crcCoupon[0].coupon.couponUnit,
                couponQuantity: crcCoupon[0].coupon.couponQuantity,
                couponExpiry: ((crcCoupon[0].coupon.couponExpiry) + 'T00:00:00.00Z')
            }
            //console.log('>>>-PromoCouponCode-handleApplyCoupon-updateInput->',updateInput)
            await API.graphql(graphqlOperation(updateProduct,  { input: updateInput }))
            dispatch({ type: "UPDATE_CART_PRODUCT", payload: updateInput });
            setFeedbackToUser(`Your coupon has beed applied.`)
        }
        setIsSubmitting(false)
    }
    const userFeedback = () => {
        //console.log('>>>-PromoCouponCode-handleApplyCoupon-lineItemCoupon.length->', lineItemCoupon.length)
        // apply most required condtionals to least...
        // couponID has been entered, crcCoupon exists, positive days to expiry, user has item in cart, and user has not applied the coupon
        if (couponCode.length > 0 && crcCoupon.length > 0  && daysToExpiry >= 0 && lineItemCoupon.length > 0 && !couponAlreadyUsed) {
            setFeedbackToUser(`The coupon is for ${crcCoupon[0].name} rum cakes and active for ${daysToExpiry} more days.  Apply the coupon now and save $${
                Number(lineItemCoupon[0].quantity) * Number(lineItemCoupon[0].unitPrice) * (discount(crcCoupon[0].coupon.couponUnit,crcCoupon[0].coupon.couponQuantity))
            } by completing the purchase now.`)
        } else if (couponCode.length > 0 && crcCoupon.length > 0 && daysToExpiry >= 0 && lineItemCoupon.length === 0 && !couponAlreadyUsed) {
            setFeedbackToUser(`We know this coupon! It's for ${crcCoupon[0].name} rum cakes and active for ${daysToExpiry} more days.  You don't have any in your cart!  Hurry, add some now.`)
        } else if (couponCode.length > 0 && crcCoupon.length > 0  && daysToExpiry >= 0 && lineItemCoupon.length > 0 && couponAlreadyUsed) {
            setFeedbackToUser(`You have already applied this coupon for ${crcCoupon[0].name} rum cakes in your cart.  You're saving $${
                Number(lineItemCoupon[0].quantity) * Number(lineItemCoupon[0].unitPrice) * (discount(crcCoupon[0].coupon.couponUnit,crcCoupon[0].coupon.couponQuantity))
            } if you purchase now.`)
        }  else if (couponCode.length > 0 && crcCoupon.length > 0  && daysToExpiry < 0) {
            setFeedbackToUser(`We know this coupon however, it expired ${Math.abs(daysToExpiry)} days ago.`)
        } else if (couponCode.length > 0 && crcCoupon.length === 0) {
            setFeedbackToUser("We don't know that coupon ID!")
        } else if (couponCode.length === 0) {
            setFeedbackToUser("Enter a coupon ID and we’ll validate it.")
        }
    }

    return (
        <MDBCard
        className="my-1 px-1 mx-auto pink lighten-5"
        style={{ fontWeight: 300, maxWidth: "600px" }}
        >
        <h2 className='h2-responsive font-weight-bold text-center mt-2'>
            Have a coupon?  Enter the ID here.
        </h2>
        <ul className="list-inline font-small">
            <li className="list-inline-item p-0 m-0 s-0">
                <MDBInput 
                    label="Coupon ID" 
                    value={couponCode}
                    onChange={e => setCouponCode(e.target.value)}
                />
            </li>
            <li className="list-inline-item p-0 m-0 s-0">
                <MDBBtn
                    id='applyCoupon'
                    size='sm'
                    color={!(couponCode.length > 0 && crcCoupon.length > 0  && daysToExpiry >= 0 && lineItemCoupon.length > 0 && !couponAlreadyUsed) ? 'red' : 'green'}
                    disabled={!(couponCode.length > 0 && crcCoupon.length > 0  && daysToExpiry >= 0 && lineItemCoupon.length > 0 && !couponAlreadyUsed) || isSubmitting}
                    //type="submit"
                    onClick={e => handleApplyCoupon(e)}
                >
                    {!(couponCode.length > 0 && crcCoupon.length > 0  && daysToExpiry >= 0 && lineItemCoupon.length > 0 && !couponAlreadyUsed) ? 'Validate It' : 'Apply It'}
                </MDBBtn>
            </li>
        </ul>

        <MDBCol>
            <p>{feedbackToUser}</p>
        </MDBCol>
        </MDBCard>
    );
}
