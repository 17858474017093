/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    enabled
    userStatus
    nickname
    firstName
    lastName
    phone
    email
    registered
    tandCs
    ofAge
    createdAt
    updatedAt
    orders {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        stripeID
        stripeStatus
        total
        quantity
        shipper
        shipMethod
        discount
        specialID
        processState
        status
        createdAt
        updatedAt
        billingAddressId
        shippingAddressId
      }
      nextToken
    }
    posts {
      items {
        id
        likes
        postText
        createdAt
        updatedAt
      }
      nextToken
    }
    reminders {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        name
        description
        deadline
        createdAt
        updatedAt
      }
      nextToken
    }
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    enabled
    userStatus
    nickname
    firstName
    lastName
    phone
    email
    registered
    tandCs
    ofAge
    createdAt
    updatedAt
    orders {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        stripeID
        stripeStatus
        total
        quantity
        shipper
        shipMethod
        discount
        specialID
        processState
        status
        createdAt
        updatedAt
        billingAddressId
        shippingAddressId
      }
      nextToken
    }
    posts {
      items {
        id
        likes
        postText
        createdAt
        updatedAt
      }
      nextToken
    }
    reminders {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        name
        description
        deadline
        createdAt
        updatedAt
      }
      nextToken
    }
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    enabled
    userStatus
    nickname
    firstName
    lastName
    phone
    email
    registered
    tandCs
    ofAge
    createdAt
    updatedAt
    orders {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        stripeID
        stripeStatus
        total
        quantity
        shipper
        shipMethod
        discount
        specialID
        processState
        status
        createdAt
        updatedAt
        billingAddressId
        shippingAddressId
      }
      nextToken
    }
    posts {
      items {
        id
        likes
        postText
        createdAt
        updatedAt
      }
      nextToken
    }
    reminders {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        name
        description
        deadline
        createdAt
        updatedAt
      }
      nextToken
    }
  }
}
`;
export const createAddress = `mutation CreateAddress($input: CreateAddressInput!) {
  createAddress(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    isBilling
    isShipping
    addressTitle
    street
    street2
    city
    stateGeo
    mailCode
    country
    geoLat
    geoLng
    createdAt
    updatedAt
  }
}
`;
export const updateAddress = `mutation UpdateAddress($input: UpdateAddressInput!) {
  updateAddress(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    isBilling
    isShipping
    addressTitle
    street
    street2
    city
    stateGeo
    mailCode
    country
    geoLat
    geoLng
    createdAt
    updatedAt
  }
}
`;
export const deleteAddress = `mutation DeleteAddress($input: DeleteAddressInput!) {
  deleteAddress(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    isBilling
    isShipping
    addressTitle
    street
    street2
    city
    stateGeo
    mailCode
    country
    geoLat
    geoLng
    createdAt
    updatedAt
  }
}
`;
export const createOrder = `mutation CreateOrder($input: CreateOrderInput!) {
  createOrder(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    stripeID
    stripeStatus
    total
    quantity
    shipper
    shipMethod
    discount
    specialID
    processState
    status
    createdAt
    updatedAt
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
    products {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        productName
        quantity
        unitPrice
        hasAlcohol
        isNormalSize
        addGarnish
        couponType
        couponLocation
        couponID
        couponUnit
        couponQuantity
        couponExpiry
        processState
        status
        createdAt
        updatedAt
      }
      nextToken
    }
    billingAddressId
    shippingAddressId
  }
}
`;
export const updateOrder = `mutation UpdateOrder($input: UpdateOrderInput!) {
  updateOrder(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    stripeID
    stripeStatus
    total
    quantity
    shipper
    shipMethod
    discount
    specialID
    processState
    status
    createdAt
    updatedAt
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
    products {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        productName
        quantity
        unitPrice
        hasAlcohol
        isNormalSize
        addGarnish
        couponType
        couponLocation
        couponID
        couponUnit
        couponQuantity
        couponExpiry
        processState
        status
        createdAt
        updatedAt
      }
      nextToken
    }
    billingAddressId
    shippingAddressId
  }
}
`;
export const deleteOrder = `mutation DeleteOrder($input: DeleteOrderInput!) {
  deleteOrder(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    stripeID
    stripeStatus
    total
    quantity
    shipper
    shipMethod
    discount
    specialID
    processState
    status
    createdAt
    updatedAt
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
    products {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        productName
        quantity
        unitPrice
        hasAlcohol
        isNormalSize
        addGarnish
        couponType
        couponLocation
        couponID
        couponUnit
        couponQuantity
        couponExpiry
        processState
        status
        createdAt
        updatedAt
      }
      nextToken
    }
    billingAddressId
    shippingAddressId
  }
}
`;
export const createProduct = `mutation CreateProduct($input: CreateProductInput!) {
  createProduct(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    productName
    quantity
    unitPrice
    hasAlcohol
    isNormalSize
    addGarnish
    couponType
    couponLocation
    couponID
    couponUnit
    couponQuantity
    couponExpiry
    processState
    status
    createdAt
    updatedAt
    order {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      stripeID
      stripeStatus
      total
      quantity
      shipper
      shipMethod
      discount
      specialID
      processState
      status
      createdAt
      updatedAt
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
      products {
        nextToken
      }
      billingAddressId
      shippingAddressId
    }
  }
}
`;
export const updateProduct = `mutation UpdateProduct($input: UpdateProductInput!) {
  updateProduct(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    productName
    quantity
    unitPrice
    hasAlcohol
    isNormalSize
    addGarnish
    couponType
    couponLocation
    couponID
    couponUnit
    couponQuantity
    couponExpiry
    processState
    status
    createdAt
    updatedAt
    order {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      stripeID
      stripeStatus
      total
      quantity
      shipper
      shipMethod
      discount
      specialID
      processState
      status
      createdAt
      updatedAt
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
      products {
        nextToken
      }
      billingAddressId
      shippingAddressId
    }
  }
}
`;
export const deleteProduct = `mutation DeleteProduct($input: DeleteProductInput!) {
  deleteProduct(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    productName
    quantity
    unitPrice
    hasAlcohol
    isNormalSize
    addGarnish
    couponType
    couponLocation
    couponID
    couponUnit
    couponQuantity
    couponExpiry
    processState
    status
    createdAt
    updatedAt
    order {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      stripeID
      stripeStatus
      total
      quantity
      shipper
      shipMethod
      discount
      specialID
      processState
      status
      createdAt
      updatedAt
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
      products {
        nextToken
      }
      billingAddressId
      shippingAddressId
    }
  }
}
`;
export const createPost = `mutation CreatePost($input: CreatePostInput!) {
  createPost(input: $input) {
    id
    likes
    postText
    createdAt
    updatedAt
    comments {
      items {
        id
        commentText
        author
        likes
        createdAt
        updatedAt
      }
      nextToken
    }
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
  }
}
`;
export const updatePost = `mutation UpdatePost($input: UpdatePostInput!) {
  updatePost(input: $input) {
    id
    likes
    postText
    createdAt
    updatedAt
    comments {
      items {
        id
        commentText
        author
        likes
        createdAt
        updatedAt
      }
      nextToken
    }
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
  }
}
`;
export const deletePost = `mutation DeletePost($input: DeletePostInput!) {
  deletePost(input: $input) {
    id
    likes
    postText
    createdAt
    updatedAt
    comments {
      items {
        id
        commentText
        author
        likes
        createdAt
        updatedAt
      }
      nextToken
    }
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
  }
}
`;
export const createComment = `mutation CreateComment($input: CreateCommentInput!) {
  createComment(input: $input) {
    id
    commentText
    author
    likes
    createdAt
    updatedAt
    post {
      id
      likes
      postText
      createdAt
      updatedAt
      comments {
        nextToken
      }
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
    }
  }
}
`;
export const updateComment = `mutation UpdateComment($input: UpdateCommentInput!) {
  updateComment(input: $input) {
    id
    commentText
    author
    likes
    createdAt
    updatedAt
    post {
      id
      likes
      postText
      createdAt
      updatedAt
      comments {
        nextToken
      }
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
    }
  }
}
`;
export const deleteComment = `mutation DeleteComment($input: DeleteCommentInput!) {
  deleteComment(input: $input) {
    id
    commentText
    author
    likes
    createdAt
    updatedAt
    post {
      id
      likes
      postText
      createdAt
      updatedAt
      comments {
        nextToken
      }
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
    }
  }
}
`;
export const createLike = `mutation CreateLike($input: CreateLikeInput!) {
  createLike(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    content
    liked
    postId
    createdBy
    createdAt
    updatedAt
  }
}
`;
export const updateLike = `mutation UpdateLike($input: UpdateLikeInput!) {
  updateLike(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    content
    liked
    postId
    createdBy
    createdAt
    updatedAt
  }
}
`;
export const deleteLike = `mutation DeleteLike($input: DeleteLikeInput!) {
  deleteLike(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    content
    liked
    postId
    createdBy
    createdAt
    updatedAt
  }
}
`;
export const createFollowing = `mutation CreateFollowing($input: CreateFollowingInput!) {
  createFollowing(input: $input) {
    id
    followerId
    followingId
    createdAt
    updatedAt
  }
}
`;
export const updateFollowing = `mutation UpdateFollowing($input: UpdateFollowingInput!) {
  updateFollowing(input: $input) {
    id
    followerId
    followingId
    createdAt
    updatedAt
  }
}
`;
export const deleteFollowing = `mutation DeleteFollowing($input: DeleteFollowingInput!) {
  deleteFollowing(input: $input) {
    id
    followerId
    followingId
    createdAt
    updatedAt
  }
}
`;
export const createReminder = `mutation CreateReminder($input: CreateReminderInput!) {
  createReminder(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    name
    description
    deadline
    createdAt
    updatedAt
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
  }
}
`;
export const updateReminder = `mutation UpdateReminder($input: UpdateReminderInput!) {
  updateReminder(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    name
    description
    deadline
    createdAt
    updatedAt
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
  }
}
`;
export const deleteReminder = `mutation DeleteReminder($input: DeleteReminderInput!) {
  deleteReminder(input: $input) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    name
    description
    deadline
    createdAt
    updatedAt
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
  }
}
`;