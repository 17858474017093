// src/pages/account/Addresses.js

import React, { useState, useEffect, useContext } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import { Context } from '../../state/Context'
//import { filterAddressesByIsBilling, filterAddressesByIsShipping } from '../../utilities/functions'

import AddAddressModal from './AddAddressModal'

import {
  MDBCol,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBCard,
  MDBInput
} from 'mdbreact'

import { getBillingAddr, getShippingAddr } from '../../utilities/functions'

import { listAddresss } from '../../graphql/queries'
import { updateAddress, deleteAddress } from '../../graphql/mutations'
import { onCreateAddress, onDeleteAddress, onUpdateAddress } from '../../graphql/subscriptions'

//===========================================================================
//===========================================================================

export default function Addresses () {
    const { state, dispatch } = useContext(Context)
    //console.log('>>>-Addresses-state.billingAddress->', Boolean(state.billingAddress))
    //console.log('>>>-Addresses-state.shippingAddress->', Boolean(state.shippingAddress))
    const [ofAge, setOfAge] = useState(state.userDetails.ofAge)
    const [addresses, setAddresses] = useState([])
    const [addAddress, setAddAddress] = useState({})
    const [editAddress, setEditAddress] = useState({})
    const [isAddAddressOpen, setIsAddAddressOpen] = useState(false)

    const [billingAddressID, setBillingAddressID] = useState('')
    const [shippingAddressID, setShippingAddressID] = useState('')
    const [isSetting, setIsSetting] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(
        () => {
            const addrs = listAddressesAsync()
            setAddresses( addrs )
        },[ofAge, addAddress, editAddress]
    )

    useEffect( () => {
        listAddressesAsync()
        //console.log('>>>-Addresses-useEffect-addresses->', addresses)

        const input = addAddress
        const createAddressListener = API.graphql(
            graphqlOperation(onCreateAddress, { input })
        ).subscribe({
            next: data => {
            setAddAddress(data.value.data.onCreateAddress)
            // console.log('>>>-START-useEffect-create__listener-BEFORE-set__>')
            setAddresses(prevData => {
                // console.log('>>>-START-useEffect-createNotelistener-IN-setFullNotes->')
                const oldData = prevData.filter(row => row.id !== addAddress.id)
                const updatedData = [...oldData, addAddress]
                return updatedData
            })
            }
        })
        const deleteAddressListener = API.graphql(
            graphqlOperation(onDeleteAddress)
        ).subscribe({
            next: data => {
            const deletedData = data.value.data.onDeleteAddress
            // console.log('>>>-START-useEffect-deleteNotelistener-BEFORE-setFullNotes-fullNotes>',fullNotes)
            setAddresses(prevData => {
                // console.log('>>>-START-useEffect-deleteNotelistener-IN-setFullNotes->')
                const updatedData = prevData.filter(
                row => row.id !== deletedData.id
                )
                // console.log(">>>-Notes-useEffect-deleteNotelistener-updatedNotes->",updatedNotes)
                return updatedData
            })
            }
        })
        const updateAddressListener = API.graphql(graphqlOperation(onUpdateAddress)).subscribe({
            next: data => {
                // console.log(">>>-Notes-useEffect-updateNotelistener-noteData->",noteData)
                const updatedRow = data.value.data.onUpdateAddress
                // console.log('>>>-START-useEffect-updateNotelistener-BEFORE-setFullNotes-fullNotes>',fullNotes)
                setAddresses(prevData => {
                    // console.log('>>>-START-useEffect-updateNotelistener-IN-setFullNotes->')
                    const index = prevData.findIndex(row => row.id === updatedRow.id)
                    const updatedData = [
                        ...prevData.slice(0, index),
                        updatedRow,
                        ...prevData.slice(index + 1)
                    ]
                    // console.log(">>>-Notes-useEffect-updateNotelistener-updatedNotes->",updatedNotes)                    
                    return updatedData
                })
            }
        })

        setIsSetting(false)
        setIsDeleting(false)
        setIsEditing(false)
        return () => {
            createAddressListener.unsubscribe()
            deleteAddressListener.unsubscribe()
            updateAddressListener.unsubscribe()
        }
    },[ofAge, addAddress, editAddress])

    const listAddressesAsync = async () => {
        try {
            const result = await API.graphql(graphqlOperation(listAddresss))
            const result2 = result.data.listAddresss.items
            //console.log('>>>-Addresses-listAddressesAsync-result2->', result2)
            setAddresses(result2)
            // console.log('>>>-Addresses-listAddressesAsync-addresses->', addresses)
            if ( result2.length === 0 ) {
                // note: returning a payload of undefined does not instaniciate the payload object
                //console.log('>>>-Addresses-listAddressesAsync-result2.length===0->',result2.length)
                setBillingAddressID('')
                dispatch({ type: 'SELECTED_BILLING_ADDRESS', payload: {} })
                setShippingAddressID('')
                dispatch({ type: 'SELECTED_SHIPPING_ADDRESS', payload: {} })
            } else if ( result2.length === 1 ) {
                // note: setBillingAddressID & setBillingAddressID given t/f of isBilling & isShipping
                //console.log('>>>-Addresses-listAddressesAsync-result2.length===1->',result2.length)
                setBillingAddressID('billing' + result2[0].id)
                dispatch({ type: 'SELECTED_BILLING_ADDRESS', payload: result2[0] })
                setShippingAddressID('shipping' + result2[0].id)
                dispatch({ type: 'SELECTED_SHIPPING_ADDRESS', payload: result2[0] })
            } else if ( result2.length > 1 ) {
                // note: returning a payload of undefined does not instaniciate the payload object
                //console.log('>>>-Addresses-listAddressesAsync-result2.length>1->',result2.length)
                //console.log('>>>-Addresses-listAddressesAsync-result2->',result2)
                //console.log('>>>-Addresses-listAddressesAsync-getBillingAddr(result2,isBilling)->', getBillingAddr(result2,true))
                // find the address that isBilling
                // find the address that isShipping
                const bAdder = getBillingAddr(result2,true)[0]
                //console.log('>>>-Addresses-listAddressesAsync-bAdder->',bAdder)
                setBillingAddressID('billing' + bAdder.id)
                dispatch({ type: 'SELECTED_BILLING_ADDRESS', payload: bAdder })
                const sAdder = getShippingAddr(result2,true)[0]
                //console.log('>>>-Addresses-listAddressesAsync-sAdder->',sAdder)
                setShippingAddressID('shipping' + sAdder.id)
                dispatch({ type: 'SELECTED_SHIPPING_ADDRESS', payload: sAdder })
            }
            setIsSetting(false)
            setIsDeleting(false)
            setIsEditing(false)
        } catch (err) {
            console.log('<<< error fetching listAddressesAsync >>>', err)
        }
    }


    const changeBillingAddr = async (id) => {
        setIsEditing(true)
        //console.log('>>>-Addresses-changeBillingAddr-old-billingAddressID->','billing' + state.billingAddress.id)
        //console.log('>>>-Addresses-changeBillingAddr-new-id->', id)
        //console.log('>>>-Addresses-changeBillingAddr-addresses->', addresses)
        const updateOldAddr = addresses.filter(row => row.id === state.billingAddress.id)[0]
        const updateNewAddr = addresses.filter(row => row.id === id.replace('billing', ''))[0]

        if (updateOldAddr && updateNewAddr) {
            setBillingAddressID(id)
            //setEditAddress(updateOldAddr)
            //console.log('>>>-Addresses-changeBillingAddr-updateOldAddr->',updateOldAddr)
            const oldInput = {
                id:updateOldAddr.id,
                isBilling: false
            }
            //console.log('>>>-Addresses-changeBillingAddr-oldInput->',oldInput)
            await API.graphql(graphqlOperation(updateAddress,  { input: oldInput }))
            //console.log('>>>-Addresses-changeBillingAddr-id->', id)

            //setEditAddress(updateNewAddr)
            //console.log('>>>-Addresses-changeBillingAddr-updateNewAddr->',updateNewAddr)
            const newInput = {
                id: updateNewAddr.id,
                isBilling: true
            }
            //console.log('>>>-Addresses-changeBillingAddr-newInput->',newInput)
            await API.graphql(graphqlOperation(updateAddress,  { input: newInput }))

            dispatch({ type: 'SELECTED_BILLING_ADDRESS', payload: updateNewAddr })
            
            setIsSetting(false)
            setIsDeleting(false)
            setIsEditing(false)
        } else {
            setIsSetting(false)
            setIsDeleting(false)
            setIsEditing(false)
            return
        }
        
    }

    const changeShippingAddr = async (id) => {
        setIsEditing(true)
        //console.log('>>>-Addresses-changeShippingAddr-old-shippingAddressID->','shipping' + state.shippingAddress.id)
        //console.log('>>>-Addresses-changeShippingAddr-new-id->', id)
        const updateOldAddr = addresses.filter(row => row.id === state.shippingAddress.id)[0]
        const updateNewAddr = addresses.filter(row => row.id === id.replace('shipping', ''))[0]
        
        if (updateOldAddr) {
            //setEditAddress(updateOldAddr)
            //console.log('>>>-Addresses-changeShippingAddr-updateOldAddr->',updateOldAddr)
            const oldInput = {
                id:updateOldAddr.id,
                isShipping: false
            }
            //console.log('>>>-Addresses-changeShippingAddr-oldInput->',oldInput)
            await API.graphql(graphqlOperation(updateAddress,  { input: oldInput })) 

            setShippingAddressID(id)
            //setEditAddress(updateNewAddr)
            //console.log('>>>-Addresses-changeShippingAddr-updateNewAddr->',updateNewAddr)
            const newInput = {
                id: updateNewAddr.id,
                isShipping: true
            }
            //console.log('>>>-Addresses-changeShippingAddr-newInput->',newInput)
            await API.graphql(graphqlOperation(updateAddress,  { input: newInput }))
            
            dispatch({ type: 'SELECTED_SHIPPING_ADDRESS', payload: updateNewAddr })
            setIsSetting(false)
            setIsDeleting(false)
            setIsEditing(false)
        } else {
            setIsSetting(false)
            setIsDeleting(false)
            setIsEditing(false)
            return
        }
    }

    const handleDeleteAddress = async id => {
        setIsDeleting(true)
        //console.log('>>>-Addresses-handleEditAddress-id->', id)
        //console.log('>>>-AddressList-handleEditAddress-billingAddressID->', billingAddressID)
        //console.log('>>>-AddressList-handleEditAddress-shippingAddressID->', shippingAddressID)
        //console.log('>>>-AddressList-handleEditAddress-addresses->', addresses)
        const addressToDelete = addresses.find(row => row.id === id)
        //console.log(">>>-Addresses-handleDeleteRow-addressToDelete->", addressToDelete);
        const input = {
          id: addressToDelete.id,
        }
        //console.log(">>>-Addresses-handleDeleteRow-input->", input);
        if ('billing' + id === billingAddressID && 'shipping' + id === shippingAddressID) {
            // if id === billID && id === shipID 
            // 1) delete address
            // 2) run listAddressesAsync to reset addresses
            // 3) if another addr then update it to bill and ship addr
            const newAddr = addresses.filter(
                addr => addr.id !== id
                )
            if (!Array.isArray(newAddr) || newAddr.length === 0) {
                // set bill & ship addr to {}
                //console.log('>>>-id!=billingAddressID!=shippingAddressID-newAddr-empty->',newAddr)
                setBillingAddressID('')
                dispatch({ type: 'SELECTED_BILLING_ADDRESS', payload: {} })
                await API.graphql(graphqlOperation(deleteAddress, { input }))
                setShippingAddressID('')
                dispatch({ type: 'SELECTED_SHIPPING_ADDRESS', payload: {} })
                listAddressesAsync()
            } else {
                // set bill & ship addr to newAddr
                //console.log('>>>-id!=billingAddressID!=shippingAddressID-newAddr[0]-not empty->',newAddr[0])
                setBillingAddressID('billing' + newAddr[0].id)
                dispatch({ type: 'SELECTED_BILLING_ADDRESS', payload: newAddr[0] })
                await API.graphql(graphqlOperation(deleteAddress, { input }))
                setShippingAddressID('shipping' + newAddr[0].id)
                dispatch({ type: 'SELECTED_SHIPPING_ADDRESS', payload: newAddr[0] })
                const newInput = {
                    id: newAddr[0].id,
                    isBilling: true,
                    isShipping: true
                }
                //console.log('>>>-Addresses-changeShippingAddr-newInput->',newInput)
                await API.graphql(graphqlOperation(updateAddress,  { input: newInput }))
                listAddressesAsync()
            }
            setIsDeleting(false)
            return
        } else if ('billing' + id !== billingAddressID && 'shipping' + id !== shippingAddressID) {
            // if id !== billID && id !== shipID 
            // 1) delete address
            // 2) run listAddressesAsync() to refreash state.addresses
            await API.graphql(graphqlOperation(deleteAddress, { input }))
            listAddressesAsync()
            //console.log('>>>-id!=billingAddressID!=shippingAddressID-addresses->',addresses)
            setIsDeleting(false)
            return

        } else if ('billing' + id === billingAddressID && 'shipping' + id !== shippingAddressID) {
            //console.log('>>>-Addresses-changeBillingAddr-state.shippingAddress->',state.shippingAddress)
            dispatch({ type: 'SELECTED_BILLING_ADDRESS', payload: state.shippingAddress })
            setBillingAddressID('billing' + state.shippingAddress.id)
            await API.graphql(graphqlOperation(deleteAddress, { input }))
            const newInput = {
                id: state.shippingAddress.id,
                isBilling: true,
                isShipping: true
            }
            //console.log('>>>-Addresses-changeBillingAddr-newInput->',newInput)
            await API.graphql(graphqlOperation(updateAddress,  { input: newInput }))
            listAddressesAsync()
            //console.log('>>>-id=billingAddressID&&id!=shippingAddressID-addresses->',addresses)
            setIsDeleting(false)
            return

        } else if ('billing' + id !== billingAddressID && 'shipping' + id === shippingAddressID) {
            dispatch({ type: 'SELECTED_SHIPPING_ADDRESS', payload:  state.billingAddress })
            setShippingAddressID('shipping' + state.billingAddress.id)
            await API.graphql(graphqlOperation(deleteAddress, { input }))
            const newInput = {
                id: state.billingAddress.id,
                isBilling: true,
                isShipping: true
            }
            //console.log('>>>-Addresses-changeShippingAddr-newInput->',newInput)
            await API.graphql(graphqlOperation(updateAddress,  { input: newInput }))
            listAddressesAsync()
            //console.log('>>>-id!=billingAddressID!&&id=shippingAddressID-addresses->',addresses)
            setIsDeleting(false)
            return



        } 
        setIsSetting(false)
        setIsDeleting(false)
        setIsEditing(false)
    }
    
    // const handleEditAddress = id => {
    //     setEditAddress(true)
    //     console.log('>>>-AddressList-handleEditAddress-id->',id)
    //     console.log('>>>-AddressList-handleEditAddress-billingAddressID->',billingAddressID)
    //     console.log('>>>-AddressList-handleEditAddress-shippingAddressID->',shippingAddressID)
    //     const addressToEdit = addresses.find(row => row.id === id)
    //     console.log(">>>-Notes-handleDeleteRow-addressToEdit.id->", addressToEdit.id);
    //     setIsSetting(false)
    //     setIsDeleting(false)
    //     setIsEditing(false)
    // }

  return (
        <MDBCard
            className='my-1 px-1 mx-auto pink lighten-5'
            style={{ fontWeight: 300, maxWidth: '600px' }}
        >
            <MDBCol>
                <h2 className='h2-responsive font-weight-bold text-center mt-2'>
                    Addresses
                </h2>

    {/* check for age requriement          */}
            <div className='text-center my-4'>
            {
                (ofAge === 'missing' || false) ? (
                    <MDBInput
                        label='Check box if you are 18 or older.'
                        type='checkbox'
                        id='ofAge'
                        value={ofAge}
                        checked={!(ofAge === 'missing' || false)}
                        onChange={e => setOfAge(e.target.value !== true)}
                    />
                ) : (
                    Boolean(addresses[0]) ? (
                        <>
                        <MDBBtn
                        color='secondary'
                        onClick={() => {
                            setIsAddAddressOpen(!isAddAddressOpen)
                        }}
                        >
                        Add an Address
                        </MDBBtn>
                        <AddAddressModal
                        isOpen={isAddAddressOpen}
                        toggle={() => {
                            setIsAddAddressOpen(!isAddAddressOpen)
                        }}
                        />

                        <p>Confirm your billing and shipping addresses.</p>

                        <MDBTable small scrollY maxHeight='325px' autoWidth>
                            <MDBTableHead color='green' textWhite>
                            <tr>
                                <th className="p-0 m-0 s-0 text-left" width="1%"/>
                                <th className="p-0 m-0 s-0 text-center" width="1%">Type</th>
                                <th className="p-0 m-0 s-0 text-center" width="15%">Name</th>
                                <th className="p-0 m-0 s-0 text-center" width="88%">Address Details</th>
                            </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                            {addresses.map(item => (
                                <tr key={item.id}>
                                <td className="p-0 m-0 s-0 text-left" width="1%">
                                    {/* <MDBBtn
                                        color='info'
                                        size='sm'
                                        style={{ spacing: 3, margin: 3, padding: 3 }}
                                        disabled={isSetting || isDeleting || isEditing}
                                        id={item.id}
                                        onClick={e => handleEditAddress(e.target.id)}
                                    >
                                        Edit
                                    </MDBBtn> */}
                                    <MDBBtn
                                        color='warning'
                                        size='sm'
                                        style={{ spacing: 3, margin: 3, padding: 3 }}
                                        disabled={isSetting || isDeleting || isEditing}
                                        id={item.id}
                                        onClick={e => handleDeleteAddress(e.target.id)}
                                        >
                                        Remove
                                    </MDBBtn>
                                </td>
                                <td className="p-0 m-0 s-0 text-left" width="1%">
                                    <MDBCol>
                                    <MDBInput
                                        label='Billing'
                                        type='radio'
                                        id={'billing' + item.id}
                                        onChange={e => changeBillingAddr(e.target.id)}
                                        checked={billingAddressID === 'billing' + item.id}
                                    />
                                    <MDBInput
                                        label='Shipping'
                                        type='radio'
                                        id={'shipping' + item.id}
                                        onChange={e => changeShippingAddr(e.target.id)}
                                        checked={shippingAddressID === 'shipping' + item.id}
                                    />
                                    </MDBCol>
                                </td>
                                <td className="p-0 m-0 s-0 text-left" width="15%">
                                    {item.addressTitle === 'missing'
                                    ? null
                                    : item.addressTitle}
                                </td>
                                <td className="p-0 m-0 s-0 text-left" width="88%">
                                    <ul>
                                    <li>{item.street}</li>
                                    {item.street2 === 'missing' ? null : (
                                        <li>{item.street2}</li>
                                    )}
                                    <li>
                                        {item.city}
                                        {', '}
                                        {item.stateGeo}
                                    </li>
                                    <li>
                                        {item.mailCode}{' '}
                                        {item.country === 'missing' ? '' : item.country}
                                    </li>
                                    </ul>
                                </td>
                                </tr>
                            ))}
                            </MDBTableBody>
                        </MDBTable>
                        
                        </>
                    ) : (
                        <>
                        <p>
                        Add an address.  
                        If your billing and shipping addresses are different you will need to add both.  
                        You can add up to 10 addresses.
                        </p>
                        <MDBBtn
                        color='secondary'
                        onClick={() => {
                            setIsAddAddressOpen(!isAddAddressOpen)
                        }}
                        >
                        Add an Address
                        </MDBBtn>
                        <AddAddressModal
                        
                        isOpen={isAddAddressOpen}
                        toggle={() => {
                            setIsAddAddressOpen(!isAddAddressOpen)
                        }}
                        />
                        </>
                    )
                )
            }
            </div>
            </MDBCol>
        </MDBCard>


  )
}
