// src/utilities/functions.js

export function cakeAmount(cake) {
  if (cake === "Original") {
    return 27.0;
  } else if (cake === "Dark Chocolate") {
    return 30.0;
  } else if (cake === "Bananas Foster") {
    return 35.0;
  } else if (cake === "Ginger Lime") {
    return 32.0;
  } else if (cake === "Pineapple Upside Down") {
    return 35.0;
  } else if (cake === "Coconut") {
    return 30.0;
  } else if (cake === "Spiced Apple") {
    return 35.0;
  } else if (cake === "Cinnamon Bun") {
    return 35.0;
  } else if (cake === "Apple Cinnamon") {
    return 40.0;
  } else if (cake === "Butter Maple Pecan") {
    return 37.0;
  } else if (cake === "Cranberry Lemon") {
    return 37.0;
  } else if (cake === "Four Mini Sampler") {
    return 25.0;
  } else if (cake === "Four Mini Holiday Sampler") {
    return 25.0;
  } else if (cake === "Dark Chocolate Heart") {
    return 25.0;
  } else if (cake === "Red Velvet Heart") {
    return 30.0;
  } else if (cake === "Garnish") {
    return 6.5;
  } else if (cake === "TEST") {
    return 1.0;
  } else {
    return null;
  }
}

export function shippingAmount(shipCat, nbr) {
  switch (shipCat) {
    case ("noShipping"):
      switch (nbr) {
        case 1: return 3.00;
        case 2: return 5.00;
        case 3: return 7.00;
        case 4: return 8.50;
        default: return (8.5 + 1 * (nbr - 4))
      };
    case ("standard"):
      switch (nbr) {
        case 1: return 18.00;
        case 2: return 23.00;
        case 3: return 38.00;
        case 4: return 45.00;
        case 5: return 60.00;
        case 6: return 72.00;
        default: return (72 + 10 * (nbr - 6))
      };
    case "priority": return (26.0 * nbr);
    case "overnight": return (30.0 * nbr);
    case "allOther": return (50.0 * nbr);
    default: return 18.00;
  }
}

// ed = end date (expiry, deadline) 
// sd = start date (e.g. today, New Date())
const _MS_PER_DAY = 1000 * 60 * 60 * 24;
export function dateDiffInDays(ed, sd) {
  // Discard the time and time-zone information.
  const d1 = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
  const d2 = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
  return Math.floor((d2 - d1) / _MS_PER_DAY);
}

export function discount(unit, quantity) {
  if (unit && quantity) {
    if (unit.toLowerCase() === "percent") {
        return (Number(quantity) / 100).toString()
    } else {
        return quantity
    }
  } else {
    return 0.0
  }
}

export function replaceWithMissing(varValue) {
  if (varValue.toString().length === 0) {
    return "missing";
  } else if (varValue.toString() === "") {
    return "missing";
  } else if (varValue === "missing") {
    return varValue;
  } else {
    return varValue;
  }
}

export function replaceMissing(varValue) {
  if (varValue === "missing") {
    return "";
  } else if (varValue === true) {
    return "";
  } else if (varValue === false) {
    return "";
  }
}

export function getBillingAddr(array, name) {
  if (filterAddressesByIsBilling(array, name)[0]) {
    return filterAddressesByIsBilling(array, name);
  } else {
    return {};
  }
}

export function getShippingAddr(array, name) {
  if (filterAddressesByIsShipping(array, name)[0]) {
    return filterAddressesByIsShipping(array, name);
  } else {
    return {};
  }
}

export function getSum(total, num) {
  return total + Number(num);
}

export function filterCartByProductName(array, value) {
  if (Array.isArray(array)) {
    return array.filter(function(el) {
      return el.productName === value;
    });
  } else {
    return [];
  }
}

export function filterOrderByProductName(array, value) {
  if (Array.isArray(array)) {
    return array.filter(function(el) {
      return 0;
    });
  } else {
    return [];
  }
}

export function filterAddressesByIsBilling(array, value) {
  if (Array.isArray(array)) {
    return array.filter(function(el) {
      return el.isBilling === value;
    });
  } else {
    return [];
  }
}

export function filterAddressesByIsShipping(array, value) {
  if (Array.isArray(array)) {
    return array.filter(function(el) {
      return el.isShipping === value;
    });
  } else {
    return [];
  }
}
