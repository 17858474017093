// src/nav/LeftNav.js

import React, { useState } from "react";

import { 
    MDBContainer, 
    MDBRow,
    MDBSideNav, 
    MDBSideNavNav,
    MDBSideNavCat,
    MDBSideNavLink,
    MDBIcon, 
    MDBBtn } from "mdbreact";

//===========================================================================
//===========================================================================

export default function LeftNav() {
    const [ isOpenLeftNav, setIsOpenLeftNav ] = useState(true)

    return (
        <main>
        <MDBContainer>
            <MDBRow>
                <MDBBtn onClick={() => setIsOpenLeftNav(!isOpenLeftNav)}>
                    <MDBIcon icon="bars" size="2x" />
                </MDBBtn>
            </MDBRow>
            <MDBSideNav
                hidden
                triggerOpening={isOpenLeftNav}
                breakWidth={1300}
                className="deep-purple darken-3"
            >
                <br></br>
                <h3>CRC Administration Menus</h3>  
                <hr color='white'/>
                
                <MDBSideNavNav>
                    <MDBSideNavCat
                        name="Dashboard"
                        id="dashboard"
                        icon="tachometer-alt"
                    >
                        <MDBSideNavLink to="/admincurrent"><MDBIcon icon="clock" />{' '}Current</MDBSideNavLink>
                        <MDBSideNavLink to="/adminhistory"><MDBIcon icon="calendar" />{' '}History</MDBSideNavLink>
                    </MDBSideNavCat>

                    <MDBSideNavCat
                        name="Management"
                        id="management"
                        icon="cogs"
                    >
                        <MDBSideNavLink to="/adminusers"><MDBIcon icon="user" />{' '}Users</MDBSideNavLink>
                        <MDBSideNavLink to="/adminsystem"><MDBIcon icon="tools" />{' '}System</MDBSideNavLink>
                    </MDBSideNavCat>
                </MDBSideNavNav>
            </MDBSideNav>
        </MDBContainer>
        </main>
    );

}
