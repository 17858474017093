// src/components/TestimonialsCarousel.js

import React from "react";
import TestimonialsCarouselItem from './TestimonialsCarouselItem'
import { commentList } from '../utilities/lists'

import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBRow
} from "mdbreact";

const TestimonialsCarousel = () => {
  return (
    <div className="p-0 col-example text-left w-175">
      <MDBCarousel
        activeItem={1}
        length={3}
        interval={10000}
        slide={true}
        showControls={true}
        multiItem
        testimonial
      >
        <MDBCarouselInner>
            <MDBRow>

              <MDBCarouselItem itemId="1">
              {
                commentList.slice(0,3).map(comment => (
                  <TestimonialsCarouselItem 
                    key={comment.id}
                    name={comment.userName}
                    post={comment.post}
                    stars={comment.stars} 
                  />))
              }
              </MDBCarouselItem>

              <MDBCarouselItem itemId="2">
              {
                commentList.slice(3,6).map(comment => (
                  <TestimonialsCarouselItem 
                    key={comment.id}
                    name={comment.userName}
                    post={comment.post}
                    stars={comment.stars} 
                  />))
              }
              </MDBCarouselItem>

              <MDBCarouselItem itemId="3">
              {
                commentList.slice(6,9).map(comment => (
                  <TestimonialsCarouselItem 
                    key={comment.id}
                    name={comment.userName}
                    post={comment.post}
                    stars={comment.stars} 
                  />))
              }
              </MDBCarouselItem>

            </MDBRow>
          </MDBCarouselInner>
      </MDBCarousel>
    </div>
  );
};

export default TestimonialsCarousel;
