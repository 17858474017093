/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = `subscription OnCreateUser {
  onCreateUser {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    enabled
    userStatus
    nickname
    firstName
    lastName
    phone
    email
    registered
    tandCs
    ofAge
    createdAt
    updatedAt
    orders {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        stripeID
        stripeStatus
        total
        quantity
        shipper
        shipMethod
        discount
        specialID
        processState
        status
        createdAt
        updatedAt
        billingAddressId
        shippingAddressId
      }
      nextToken
    }
    posts {
      items {
        id
        likes
        postText
        createdAt
        updatedAt
      }
      nextToken
    }
    reminders {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        name
        description
        deadline
        createdAt
        updatedAt
      }
      nextToken
    }
  }
}
`;
export const onUpdateUser = `subscription OnUpdateUser {
  onUpdateUser {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    enabled
    userStatus
    nickname
    firstName
    lastName
    phone
    email
    registered
    tandCs
    ofAge
    createdAt
    updatedAt
    orders {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        stripeID
        stripeStatus
        total
        quantity
        shipper
        shipMethod
        discount
        specialID
        processState
        status
        createdAt
        updatedAt
        billingAddressId
        shippingAddressId
      }
      nextToken
    }
    posts {
      items {
        id
        likes
        postText
        createdAt
        updatedAt
      }
      nextToken
    }
    reminders {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        name
        description
        deadline
        createdAt
        updatedAt
      }
      nextToken
    }
  }
}
`;
export const onDeleteUser = `subscription OnDeleteUser {
  onDeleteUser {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    enabled
    userStatus
    nickname
    firstName
    lastName
    phone
    email
    registered
    tandCs
    ofAge
    createdAt
    updatedAt
    orders {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        stripeID
        stripeStatus
        total
        quantity
        shipper
        shipMethod
        discount
        specialID
        processState
        status
        createdAt
        updatedAt
        billingAddressId
        shippingAddressId
      }
      nextToken
    }
    posts {
      items {
        id
        likes
        postText
        createdAt
        updatedAt
      }
      nextToken
    }
    reminders {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        name
        description
        deadline
        createdAt
        updatedAt
      }
      nextToken
    }
  }
}
`;
export const onCreateAddress = `subscription OnCreateAddress {
  onCreateAddress {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    isBilling
    isShipping
    addressTitle
    street
    street2
    city
    stateGeo
    mailCode
    country
    geoLat
    geoLng
    createdAt
    updatedAt
  }
}
`;
export const onUpdateAddress = `subscription OnUpdateAddress {
  onUpdateAddress {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    isBilling
    isShipping
    addressTitle
    street
    street2
    city
    stateGeo
    mailCode
    country
    geoLat
    geoLng
    createdAt
    updatedAt
  }
}
`;
export const onDeleteAddress = `subscription OnDeleteAddress {
  onDeleteAddress {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    isBilling
    isShipping
    addressTitle
    street
    street2
    city
    stateGeo
    mailCode
    country
    geoLat
    geoLng
    createdAt
    updatedAt
  }
}
`;
export const onCreateOrder = `subscription OnCreateOrder {
  onCreateOrder {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    stripeID
    stripeStatus
    total
    quantity
    shipper
    shipMethod
    discount
    specialID
    processState
    status
    createdAt
    updatedAt
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
    products {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        productName
        quantity
        unitPrice
        hasAlcohol
        isNormalSize
        addGarnish
        couponType
        couponLocation
        couponID
        couponUnit
        couponQuantity
        couponExpiry
        processState
        status
        createdAt
        updatedAt
      }
      nextToken
    }
    billingAddressId
    shippingAddressId
  }
}
`;
export const onUpdateOrder = `subscription OnUpdateOrder {
  onUpdateOrder {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    stripeID
    stripeStatus
    total
    quantity
    shipper
    shipMethod
    discount
    specialID
    processState
    status
    createdAt
    updatedAt
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
    products {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        productName
        quantity
        unitPrice
        hasAlcohol
        isNormalSize
        addGarnish
        couponType
        couponLocation
        couponID
        couponUnit
        couponQuantity
        couponExpiry
        processState
        status
        createdAt
        updatedAt
      }
      nextToken
    }
    billingAddressId
    shippingAddressId
  }
}
`;
export const onDeleteOrder = `subscription OnDeleteOrder {
  onDeleteOrder {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    stripeID
    stripeStatus
    total
    quantity
    shipper
    shipMethod
    discount
    specialID
    processState
    status
    createdAt
    updatedAt
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
    products {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        productName
        quantity
        unitPrice
        hasAlcohol
        isNormalSize
        addGarnish
        couponType
        couponLocation
        couponID
        couponUnit
        couponQuantity
        couponExpiry
        processState
        status
        createdAt
        updatedAt
      }
      nextToken
    }
    billingAddressId
    shippingAddressId
  }
}
`;
export const onCreateProduct = `subscription OnCreateProduct {
  onCreateProduct {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    productName
    quantity
    unitPrice
    hasAlcohol
    isNormalSize
    addGarnish
    couponType
    couponLocation
    couponID
    couponUnit
    couponQuantity
    couponExpiry
    processState
    status
    createdAt
    updatedAt
    order {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      stripeID
      stripeStatus
      total
      quantity
      shipper
      shipMethod
      discount
      specialID
      processState
      status
      createdAt
      updatedAt
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
      products {
        nextToken
      }
      billingAddressId
      shippingAddressId
    }
  }
}
`;
export const onUpdateProduct = `subscription OnUpdateProduct {
  onUpdateProduct {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    productName
    quantity
    unitPrice
    hasAlcohol
    isNormalSize
    addGarnish
    couponType
    couponLocation
    couponID
    couponUnit
    couponQuantity
    couponExpiry
    processState
    status
    createdAt
    updatedAt
    order {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      stripeID
      stripeStatus
      total
      quantity
      shipper
      shipMethod
      discount
      specialID
      processState
      status
      createdAt
      updatedAt
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
      products {
        nextToken
      }
      billingAddressId
      shippingAddressId
    }
  }
}
`;
export const onDeleteProduct = `subscription OnDeleteProduct {
  onDeleteProduct {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    productName
    quantity
    unitPrice
    hasAlcohol
    isNormalSize
    addGarnish
    couponType
    couponLocation
    couponID
    couponUnit
    couponQuantity
    couponExpiry
    processState
    status
    createdAt
    updatedAt
    order {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      stripeID
      stripeStatus
      total
      quantity
      shipper
      shipMethod
      discount
      specialID
      processState
      status
      createdAt
      updatedAt
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
      products {
        nextToken
      }
      billingAddressId
      shippingAddressId
    }
  }
}
`;
export const onCreatePost = `subscription OnCreatePost {
  onCreatePost {
    id
    likes
    postText
    createdAt
    updatedAt
    comments {
      items {
        id
        commentText
        author
        likes
        createdAt
        updatedAt
      }
      nextToken
    }
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
  }
}
`;
export const onUpdatePost = `subscription OnUpdatePost {
  onUpdatePost {
    id
    likes
    postText
    createdAt
    updatedAt
    comments {
      items {
        id
        commentText
        author
        likes
        createdAt
        updatedAt
      }
      nextToken
    }
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
  }
}
`;
export const onDeletePost = `subscription OnDeletePost {
  onDeletePost {
    id
    likes
    postText
    createdAt
    updatedAt
    comments {
      items {
        id
        commentText
        author
        likes
        createdAt
        updatedAt
      }
      nextToken
    }
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
  }
}
`;
export const onCreateComment = `subscription OnCreateComment {
  onCreateComment {
    id
    commentText
    author
    likes
    createdAt
    updatedAt
    post {
      id
      likes
      postText
      createdAt
      updatedAt
      comments {
        nextToken
      }
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
    }
  }
}
`;
export const onUpdateComment = `subscription OnUpdateComment {
  onUpdateComment {
    id
    commentText
    author
    likes
    createdAt
    updatedAt
    post {
      id
      likes
      postText
      createdAt
      updatedAt
      comments {
        nextToken
      }
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
    }
  }
}
`;
export const onDeleteComment = `subscription OnDeleteComment {
  onDeleteComment {
    id
    commentText
    author
    likes
    createdAt
    updatedAt
    post {
      id
      likes
      postText
      createdAt
      updatedAt
      comments {
        nextToken
      }
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
    }
  }
}
`;
export const onCreateLike = `subscription OnCreateLike {
  onCreateLike {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    content
    liked
    postId
    createdBy
    createdAt
    updatedAt
  }
}
`;
export const onUpdateLike = `subscription OnUpdateLike {
  onUpdateLike {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    content
    liked
    postId
    createdBy
    createdAt
    updatedAt
  }
}
`;
export const onDeleteLike = `subscription OnDeleteLike {
  onDeleteLike {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    content
    liked
    postId
    createdBy
    createdAt
    updatedAt
  }
}
`;
export const onCreateFollowing = `subscription OnCreateFollowing {
  onCreateFollowing {
    id
    followerId
    followingId
    createdAt
    updatedAt
  }
}
`;
export const onUpdateFollowing = `subscription OnUpdateFollowing {
  onUpdateFollowing {
    id
    followerId
    followingId
    createdAt
    updatedAt
  }
}
`;
export const onDeleteFollowing = `subscription OnDeleteFollowing {
  onDeleteFollowing {
    id
    followerId
    followingId
    createdAt
    updatedAt
  }
}
`;
export const onCreateReminder = `subscription OnCreateReminder {
  onCreateReminder {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    name
    description
    deadline
    createdAt
    updatedAt
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
  }
}
`;
export const onUpdateReminder = `subscription OnUpdateReminder {
  onUpdateReminder {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    name
    description
    deadline
    createdAt
    updatedAt
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
  }
}
`;
export const onDeleteReminder = `subscription OnDeleteReminder {
  onDeleteReminder {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    name
    description
    deadline
    createdAt
    updatedAt
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
  }
}
`;