// src/pages/footer/SalesPoliciesModal.ja

import React from 'react'

import { shippingAmount } from '../../utilities/functions'

import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from 'mdbreact'

//===========================================================================
//===========================================================================

const SalesPoliciesModal = ({ isOpen, toggle }) => {
  // console.log('>>>-MoreModal-{ isOpen, toggle, cakeName }->',{isOpen,toggle,cakeName})

  return (
    <MDBModal
      size='small'
      isOpen={isOpen}
      toggle={toggle}
      centered
      className='modal-notify modal-info text-white'
    >
      <MDBModalHeader>Christy&apos;s Rum Cake</MDBModalHeader>
      <MDBModalBody>
        <h3>Sales Policies</h3>
        <hr />
        <p>
          All cake prices exclude shipping, taxes, or handling. The total
          charged will be the sum of cake price and flat order processing fee.
          The order processing fee pays for the third party cost: shipping,
          taxes, and local logistics.
        </p>
        <p>
          - Shipping: We average the various shipping categories for various
          shippers.
        </p>
        <p>- Taxes: We average the State taxes charged for internet sales.</p>
        <p>
          - Local logistics is the average cost of transporting from the cake
          manufacturing site to the shippers site.
        </p>
        <p>Flat rates for shipping, taxes and processing are:</p>
        <p>
          - ${shippingAmount("standard", 1).toFixed(2)} – standard shipping in contiguous USA (delivery 4 – 7 days
          from approved charge)
        </p>
        <p>
          - ${shippingAmount("priority", 1).toFixed(2)} – Priority shipping in contiguous USA (delivery 2 – 4 days
          from approved charge)
        </p>
        <p>
          - ${shippingAmount("overnight", 1).toFixed(2)} – Overnight shipping in contiguous USA (delivery 1 day from
          approved charge)
        </p>
        <p>- ${shippingAmount("allOther", 1).toFixed(2)} – all other shipping</p>
        <p>
          Prices, shipping and order processing is per cake. We accept card
          processed transactions only. We only sell to persons 18 years of age
          or older. Buyer is required to contact Christy&apos;s Rum Cake for
          orders of more than one cake.
        </p>
      </MDBModalBody>

      <MDBModalFooter>
        <MDBBtn color='primary' onClick={toggle}>
          Close
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  )
}

export default SalesPoliciesModal
