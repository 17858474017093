/* eslint-disable react-hooks/exhaustive-deps */
// src/admin/ListUsers.js

import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../state/Context';
import { API, graphqlOperation } from "aws-amplify";

import AdminUsers from './AdminUsers';
import AdminModalAddUser from './AdminModalAddUser';
import DataTable from '../../../components/DataTable'

import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol
} from "mdbreact";

import { adminListUsers } from '../../../utilities/lists';
import { listUsers } from "../../../graphql/queries";

//===========================================================================
const LISTUSERS_FILTER_LIMIT = 1000;
//===========================================================================

export default function ListUsers() {
  const { state } = useContext(Context);
  //console.log('>>>-ListUsers-state->', state)
  const [isLoading, setIsLoading] = useState(false);
  const [dataTableHeaders, setDataTableHeaders] = useState([]);
  const [dataTableBody, setDataTableBody] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [editUser, setEditUser] = useState({});
  const [isAdminAddUserOpen, setIsAdminAddUserOpen] = useState(false);

  useEffect(() => { getUsers() }, []);

  async function getUsers () {
    setIsLoading(true)
    if (state.userIsAuthenticated === true && state.userIsAdmin === true) {
      try {
        const result = await API.graphql(graphqlOperation(
          listUsers, {
          limit: LISTUSERS_FILTER_LIMIT
          }
        ));
        setDataTableHeaders(adminListUsers.columns.map(col => col.label))
        setDataTableBody(makeUserRows(result.data.listUsers.items))
      } catch (err) {
        if (state.userIsAuthenticated === true && state.useIsAdmin === true) {
          alert(`In ListUsers-getUsers-catch-state.userIsAuthenticated === true && state.useIsAdmin === true ${err.message}`)
        }
        alert(`In ListUsers-getUsers-catch ${err.message}`)
        //setUsers([])
        setDataTableHeaders([])
        setDataTableBody([])
        console.log(">>>-ListUsers-error fetching getUsers...", err);
      }
    } else {
      //console.log('>>>-ListUsers-getUsers-state.userIsAdmin->',state.userIsAdmin)
      return
    }
    setIsLoading(false)
  }
  
  function handleSelectedUser(user) {
    //console.log('>>>-ListUsers-handleSelectedUser-user->',user)
    setSelectedUser(user)
  }
  function handleEditUser(user) {
    //console.log('>>>-ListUsers-handleEditUser-user->',user)
    setEditUser(user)
  }

  function makeUserRows(data) {
    //console.log('>>>-makeUserRows-data->',data)
    if (data.length > 0) {
      return data.map(item => ({
        actions: (
          <React.Fragment>
            <MDBBtn
              color="green"
              size='sm'
              id={item.id}
              onClick={() => handleSelectedUser(item)}
              style={{ spacing: 1, margin: 1, padding: 1 }}
              disabled={isLoading}
            >
              Select
            </MDBBtn>
            <MDBBtn
              color="red"
              size='sm'
              id={item.id}
              onClick={() => handleEditUser(item)}
              style={{ spacing: 1, margin: 1, padding: 1 }}
              disabled={isLoading}
            >
              Edit
            </MDBBtn>
          </React.Fragment>
        ),
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        phone: item.phone,
        ofAge: item.ofAge.toString() || false,
        registered: item.registered.toString() || false,
        tandCs: item.tandCs.toString() || false
      }))
    } else {
      return {}
    }
  }
  
  return (
    <MDBCard>
      <MDBCardHeader tag="h2" className="text-center font-weight-bold text-uppercase py-2">
        <MDBContainer>
          <MDBRow>
            <MDBCol size="9" md="6">
              Users Table
            </MDBCol>
            <MDBCol size="9" md="6">

              <MDBBtn
                color="primary"
                size='lg'
                onClick={() => setIsAdminAddUserOpen(!isAdminAddUserOpen)}
              >
                Add New User
              </MDBBtn>
              <AdminModalAddUser
                isOpen={isAdminAddUserOpen}
                toggle={() => setIsAdminAddUserOpen(!isAdminAddUserOpen)}
              />

            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBCardHeader>
      <MDBCardBody>

        <DataTable 
          dataTableHeaders={dataTableHeaders} 
          dataTableBody={dataTableBody} 
        />
        <AdminUsers selectedUser={selectedUser} />

      </MDBCardBody>
    </MDBCard>
  )
}
