// src/pages/shop/PayButton.js

import React, { useContext } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { Context } from '../../state/Context'
import StripeCheckout from 'react-stripe-checkout'

import { createOrder, updateProduct } from '../../graphql/mutations'

const stripeConfig = {
  currency: 'USD',
  // publishableAPIKey: "pk_test_qPuEaI4URr1EygKZMZFhVmia00eYeXT2xX",
  publishableAPIKey: 'pk_live_E76sIJ260WWJqQxK7bU2bKX300buDuoGPG'
}

//===========================================================================
//===========================================================================

const PayButton = ({ toggle, order }) => {
  const { state, dispatch } = useContext(Context)
  // console.log(">>>-PayButton-state->", state);

  const updateProductToOrdered = async (productId, orderId) => {
    // console.log(">>>-PayButton-updateProductToOrdered-productId->",productId);
    // console.log(">>>-PayButton-updateProductToOrdered-orderId->",orderId);
    try {
      const prodLineItem = {
        id: productId,
        processState: 'ordered',
        productOrderId: orderId
      }
      // console.log(">>>-PayButton-updateProductToOrdered-prodLineItem->",prodLineItem);
      await API.graphql(
        graphqlOperation(updateProduct, { input: prodLineItem })
      )
    } catch (err) {
      if (state.userIsAuthenticated === true) {
        alert(err.message)
      }
      console.error('>>>-PayButton-addOrder-err->', err)
    }
  }

  const handleCharge = async token => {
    // console.log(">>>-PayButton-handleCharge-token->", token);
    const orderDateTime = new Date()
    // console.log(">>>-PayButton-handleCharge-orderDateTime->", orderDateTime);

    try {
      // process NOT FREE subscription payment via Stripe
      // API TEST name stripecrcdev
      // API PROD name stripecrcprod
      const result = await API.post('stripecrcprod', '/charge', {
        body: {
          token,
          charge: {
            currency: stripeConfig.currency,
            amount: parseInt(order.amount),
            description: order.description
          }
        }
      })
      // console.log(">>>-PayButton-handleCharge-result->", result);

      // if pay = succeeded
      // if billing = shipping 1 address else 2 addresses

      if (result.status === 'succeeded') {
        const newOrder = {
          orderUserId: state.userDetails.id,
          cognitoId: state.userDetails.cognitoId,
          chId: state.userDetails.chId,
          usernameAWS: state.userDetails.usernameAWS,
          ownerAWS: state.userDetails.ownerAWS,

          stripeID: result.id,
          stripeStatus: result.status,
          // products: {items: state.userCart},
          // Addresses: {items: [state.billingAddress, state.shippingAddress]},
          total: order.totalCakeCost,
          quantity: order.totalUnits,
          shipper: 'USPS',
          shipMethod: order.shipMethod,
          discount: order.discount,
          specialID: 'none',
          processState: 'ordered',
          status: 'active',
          billingAddressId: state.billingAddress.id,
          shippingAddressId: state.shippingAddress.id
        }
        // console.log(">>>-PayButton-handleCharge-newOrder->", newOrder);
        const orderToAsync = await API.graphql(
          graphqlOperation(createOrder, { input: newOrder })
        )
        const newestOrder = orderToAsync.data.createOrder
        // console.log(">>>-PayButton-handleCharge-newestOrder.id->", newestOrder.id);
        // console.log(">>>-PayButton-handleCharge-state.userCart->", state.userCart);
        // dispatch({ type: "UPDATE_ORDER_BOOK", payload: [newOrder] });
        dispatch({ type: 'PURCHASED_CART_PRODUCTS', payload: [] })

        // update products.processState to ordered
        state.userCart.map(prod => {
          updateProductToOrdered(prod.id, newestOrder.id)
        })

        // alert(`Thank you for your order id: ${orderToAsync.id}.  An email is on the way.`)
        toggle()
      }
    } catch (err) {
      alert(err.message)
      console.error('>>>-PayButton-handleCharge-err->', err)
    }
  }

  return (
    <StripeCheckout
      token={handleCharge}
      email={state.userDetails.email}
      name={order.name}
      currency={stripeConfig.currency}
      amount={parseInt(order.amount)}
      stripeKey={stripeConfig.publishableAPIKey}
      // billingAddress={state.billingAddress}
      // shippingAddress={state.shippingAddress}
      locale='auto'
      allowRememberMe={false}
    />
  )
}

export default PayButton
