// src/components/ShippingRatesTable.js

import React, { useContext } from 'react'
import { Context } from '../state/Context'

import { shippingAmount, getSum } from '../utilities/functions'

import {
  MDBCard,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBInput
} from 'mdbreact'

//===========================================================================
//===========================================================================

export default function ShippingRatesTable ({ shipping, setShipping }) {
  const { state } = useContext(Context)

  const totalUnits = Array.isArray(state.userCart)
    ? state.userCart.map(item => item.quantity).reduce(getSum, 0)
    : 0

  return (
    <MDBCard
      className='my-1 px-1 mx-auto pink lighten-5'
      style={{ fontWeight: 300, maxWidth: '600px' }}
    >
      <MDBCol>
        <h2 className='h2-responsive font-weight-bold text-center mt-2'>
          Flat Rate: Shipping, Taxes and Handling
        </h2>
        <MDBTable className='p-0 m-0 s-0 text-left' small>
          <MDBTableHead color='deep-purple lighten-4'>
            <tr>
              <th className='pt-2 m-0 s-0 text-left' width='100%'>
                <h5>Rates for {totalUnits} cakes.</h5>
              </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr>
              <td className='p-0 m-0 s-0 text-left' width='10%'>
                <MDBInput
                  onClick={e => setShipping(e.target.id)}
                  checked={shipping === 'noShipping'}
                  label={
                    '$' +
                    shippingAmount('noShipping', totalUnits).toFixed(2) +
                    ' - Contact Christy before purchase.'
                  }
                  type='radio'
                  id='noShipping'
                />
              </td>
            </tr>
            <tr>
              <td className='p-0 m-0 s-0 text-left' width='10%'>
                <MDBInput
                  onClick={e => setShipping(e.target.id)}
                  checked={shipping === 'standard'}
                  label={
                    '$' +
                    shippingAmount('standard', totalUnits).toFixed(2) +
                    ' - Standard Continental USA (3 - 6 days)'
                  }
                  type='radio'
                  id='standard'
                />
              </td>
            </tr>
            <tr>
              <td className='p-0 m-0 s-0 text-left' width='10%'>
                <MDBInput
                  onClick={e => setShipping(e.target.id)}
                  checked={shipping === 'priority'}
                  label={
                    '$' +
                    shippingAmount('priority', totalUnits).toFixed(2) +
                    ' - Priority Continental USA (2 - 4 days)'
                  }
                  type='radio'
                  id='priority'
                />
              </td>
            </tr>
            <tr>
              <td className='p-0 m-0 s-0 text-left' width='10%'>
                <MDBInput
                  onClick={e => setShipping(e.target.id)}
                  checked={shipping === 'overnight'}
                  label={
                    '$' +
                    shippingAmount('overnight', totalUnits).toFixed(2) +
                    ' - Overnight Continental USA'
                  }
                  type='radio'
                  id='overnight'
                />
              </td>
            </tr>
            <tr>
              <td className='p-0 m-0 s-0 text-left' width='10%'>
                <MDBInput
                  onClick={e => setShipping(e.target.id)}
                  checked={shipping === 'allOther'}
                  label={
                    '$' +
                    shippingAmount('allOther', totalUnits).toFixed(2) +
                    ' - All Other'
                  }
                  type='radio'
                  id='allOther'
                />
              </td>
            </tr>
          </MDBTableBody>
        </MDBTable>
      </MDBCol>
    </MDBCard>
  )
}
