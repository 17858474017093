// src/pages/RumCakeMenue.js

import React, { useState, useContext, useEffect } from "react";
import { Context } from "../state/Context";
//import { Auth, API, graphqlOperation } from "aws-amplify";

import { cakeList } from "../utilities/lists";
import { filterCartByProductName } from "../utilities/functions";
import CakeCard from "./CakeCard";
import CakeCarousel from "../components/CakeCarousel";
import TestimonialsCarousel from '../components/TestimonialsCarousel';
import CouponsFacebook from "../components/CouponsFacebook";
import NewCake from "../components/NewCake";

import {
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBInput,
  MDBIcon
} from "mdbreact";

//===========================================================================
//===========================================================================

export default function RumCakeMenue(props) {
  const { state, dispatch } = useContext(Context);
  //console.log('>>>-RumCakeMenue-state->', state)
  const [cakeName, setCakeName] = useState("Original");
  const selectedCake = cakeList.filter(function(cake) {return cake.name === cakeName})[0];

  useEffect(() => { setStateAddresses() },[]);
  //useEffect(() => { getOrders() },[])

  // const handleSignOut = async () => {
  //   await Auth.signOut()
  //   dispatch({ type: 'USER_SIGN_OUT', payload: {} })
  //   return props.history.push('/signin')
  // }

  const setStateAddresses = () => {
    dispatch({ type: 'SELECTED_BILLING_ADDRESS', payload: {} })
    dispatch({ type: 'SELECTED_SHIPPING_ADDRESS', payload: {} })
  };

  function getCartProductQuantity(name) {
    //console.log('>>>-RumCakeMenue-getCartProductQuantity-state.userCart->', state.userCart)
    if (
      state.userIsAuthenticated === true && 
      state.userCart.length > 0 && 
      filterCartByProductName(state.userCart, name)[0]
      ) {
      return filterCartByProductName(state.userCart, name)[0].quantity;
    } else {
      return null;
    }
  };

  function getOrderBookQuantity(name) {
    //console.log('>>>-RumCakeMenue-getOrderBookQuantity-name->', name)
    // getOrder where name = 'x' and processState = 'y'
    if (
      state.userIsAuthenticated === true && 
      state.userOrders.length > 0 && 
      true
    ) {
      return null
    } else {
      return null
    }
  };

  return (
    <main style={{ margin: "0 1%", paddingTop: "6rem" }}>
      <MDBCard
        className="mx-auto pink lighten-5"
        style={{ fontWeight: 300, maxWidth: "98%" }}
      >
        <MDBCardBody>
          <h2 className="h1-responsive font-weight-bold text-center my-1">
            Welcome to Christy's Rum Cakes
          </h2>
          <h5 className="text-center w-responsive mx-auto mb-2">
            Ooohhh yum! This is it... original, from scratch, with imagination
            and generations of love.
          </h5>
          
          <hr />
          <div className="d-flex justify-content-center">
          <div className="p-0 m-0 s-0 col-example text-left">
            <CakeCarousel />

            <MDBTable className="p-0 m-0 s-0 text-left" small>
              <MDBTableHead color="deep-purple lighten-4">
                <tr>

                  <th className="p-1 m-1 s-1 text-center" width="70%">
                    Rum Cake Menu
                  </th>
                  
                  <th className="p-1 m-1 s-1 text-center" width="15%">
                  </th>

                  <th className="p-1 m-1 s-1 text-center" width="5%">
                    <MDBIcon icon="cart-plus" />
                  </th>

                  <th className="p-1 m-1 s-1 text-center" width="5%">
                    <MDBIcon icon="truck" />
                  </th>

                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {cakeList.map(row => (
                  <tr key={row.id}>

                    <td className="p-0 m-0 s-0 text-left" width="65%">
                      <MDBInput
                        className="p-0 m-0 s-0 text-left"
                        size="lg"
                        label={row.name}
                        type="radio"
                        id={row.name}
                        onClick={e => setCakeName(e.target.id)}
                        checked={cakeName === row.name}
                      />
                    </td>
                    
                    {row.coupon.couponType === "Coupon" ? (
                        <CouponsFacebook expiry={row.coupon.couponExpiry} />
                      ) : (
                        <NewCake lable={row.coupon.couponType} />
                    )}

                    <td 
                      className="p-0 m-0 s-0 text-center" 
                      width="5%"
                      bgcolor={getCartProductQuantity(row.name) < 1 ? null : "#E0FFFF"}
                    >
                      <strong>{getCartProductQuantity(row.name)}</strong>
                    </td>

                    <td 
                      className="p-0 m-0 s-0 text-center" 
                      width="5%"
                      bgcolor={getOrderBookQuantity(row.name) < 1 ? null : "lightgreen"}
                    >
                      <strong>{getOrderBookQuantity(row.name)}</strong>
                    </td>

                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
            <p>
              From 12 to 16 slices per cake depending on what your mummy taught you
              about sharing.
            </p>
          </div>

          <div className="p-0 m-0 s-0 col-example text-left">
            <CakeCard selectedCake={selectedCake} />
          </div>

          </div>

          <hr />
          <h2 className="h1-responsive font-weight-bold text-center my-1">
          Join us in giving back and giving life.
          </h2>
          <h5 className="text-center w-responsive mx-auto mb-2">
            Because of <a href="https://losangeles.cbslocal.com/video/4013868-woman-who-made-surgical-history-preparing-for-kidney-transplant/" target="blank" >Christine&apos;s amazing life path</a>, we are giving 10% to organ donation and transplant charities.
          </h5>
          <hr />
          <TestimonialsCarousel />
          
        </MDBCardBody>
      </MDBCard>
    </main>
  );
}
