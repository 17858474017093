//src/pages/footer/SalesPoliciesModal.ja

import React from 'react';

import { cakeAmount, shippingAmount } from '../../utilities/functions'

import { 
  MDBBtn, 
  MDBModal,
  MDBModalBody, 
  MDBModalHeader, 
  MDBModalFooter } from 'mdbreact';

//===========================================================================
//===========================================================================

const PricesModal = ({ isOpen, toggle }) => {
  //console.log('>>>-MoreModal-{ isOpen, toggle, cakeName }->',{isOpen,toggle,cakeName})

    return (
        <MDBModal 
          size="small" 
          isOpen={isOpen} 
          toggle={toggle} 
          centered
          className="modal-notify modal-info text-white" 
        >  
        <MDBModalHeader>Christy&apos;s Rum Cake</MDBModalHeader>
        <MDBModalBody>
          <h3>
          Prices Policies
          </h3>
          <hr/>
          <p>
          All prices posted on the web page are in US dollars and are subject to change by Christy&apos;s Rum Cakes at any time.  
          All items in a user&apos;s shopping cart before a price change will be honored for one month after the price change effective date.  
          Christy&apos;s Rum Cakes is solely responsible for both determination of price changes and effective dates.
          </p>
          
        </MDBModalBody>

        <MDBModalFooter>
            <MDBBtn 
                color="primary" 
                onClick={toggle}>
                Close
            </MDBBtn>
        </MDBModalFooter>
        </MDBModal>
    );
}

export default PricesModal