import React, { useReducer } from 'react'

import { ContextProvider, initialState } from './state/Context'
import { Reducer } from './state/Reducer'

import TopNav from './nav/TopNav'
import Routes from './nav/Routes'

import Footer from './pages/footer/Footer'

import './App.css'

//===========================================================================
//===========================================================================

export default function App() {
  const [state, dispatch] = useReducer(Reducer, initialState)

  return (
    <ContextProvider value={{ state, dispatch }}>
      <div className='App'>
        <TopNav />
        <Routes />
        <Footer />
      </div>
    </ContextProvider>
  )
}