// src/pages/shop/CartModal.js

import React, { useState, useContext } from 'react'
import { Context } from '../../state/Context'

import Addresses from '../account/Addresses'

import ShippingRatesTable from '../../components/ShippingRatesTable'
import CartContents from '../../components/CartContents'
import PromoCouponCode from '../../components/PromoCouponCode'

import { cakeAmount, shippingAmount, getSum, discount } from '../../utilities/functions'
import PayButton from './PayButton'

import {
  MDBCol,
  MDBRow,
  MDBCardBody,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from 'mdbreact'

//===========================================================================
//===========================================================================

export default function CartModal ({ isOpen, toggle, cartProducts }) {
  // console.log('>>>-CartModal-cartProducts->',cartProducts)
  const { state } = useContext(Context)
  const [ shipping, setShipping ] = useState('standard')
  // const [ hasBillingAddress, setHasBillingAddress] = useState(false)
  // const [ hasShippingAddress, setHasShippingAddress] = useState(false)

  const discountPerCake = item => {
    // console.log('>>>-CartContents-discountPerCake-item->', item)
    const disc = (
      Number(item.unitPrice) *
      discount(item.couponUnit, Number(item.couponQuantity))
    ).toFixed(2)
    // console.log('>>>-CartContents-discountPerCake-discount->', disc)
    return disc
  }

  const totalUnits = Array.isArray(state.userCart)
    ? state.userCart.map(item => item.quantity).reduce(getSum, 0)
    : []

  const totalCakeCost = Array.isArray(state.userCart)
    ? state.userCart
        .map(item => item.quantity * ( 
          item.unitPrice 
          - discountPerCake(item)
          + (item.addGarnish ? cakeAmount("Garnish") : 0)
          ))
        .reduce(getSum, 0)
        .toFixed(2)
    : [];

  const totalAllOtherCost =
    totalUnits < 1 ? 0 : shippingAmount(shipping, totalUnits).toFixed(2)

  return (
    <MDBModal isOpen={isOpen} toggle={toggle} backdrop={false} frame size="fluid">
      <MDBModalHeader className='pink darken-1 text-white' toggle={toggle}>
        Your Shopping Cart
      </MDBModalHeader>
      <MDBModalBody>
        <MDBCardBody>
          <MDBRow>
            <MDBCol>
              <ShippingRatesTable
                shipping={shipping}
                setShipping={setShipping}
              />

              <hr />

              <PromoCouponCode
                cartProducts={cartProducts}
                // setCartProducts={() => setCartProducts}
              />
            </MDBCol>
            <MDBCol>
              <Addresses />
            </MDBCol>
          </MDBRow>
          <hr />
          <MDBRow>
            <MDBCol>
              <CartContents
                shipping={shipping}
                cartProducts={cartProducts}
                // setCartProducts={() => setCartProducts}
              />
            </MDBCol>
          </MDBRow>
          <hr />
          <MDBRow>
            <MDBCol>
              <h6>
                You are buying {totalUnits} cakes.
                <strong>
                  {' '}
                  Christy will charge: $
                  {(Number(totalCakeCost) + Number(totalAllOtherCost)).toFixed(
                    2
                  )}
                </strong>
              </h6>
              <h6>
                Click the Pay button and get ready to taste the yummy! BTW: We
                don't save your card information.
              </h6>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBModalBody>

      <MDBModalFooter className='justify-content-end'>
        {((
          state.userIsAuthenticated && 
          (Object.entries(state.billingAddress).length > 0 && state.billingAddress.constructor === Object) &&
          (Object.entries(state.shippingAddress).length > 0 && state.shippingAddress.constructor === Object) &&
          (state.userCart.length > 0)) 
          ) ? (
          <PayButton
            toggle={toggle}
            order={{
              name: `Total charge $${(
                Number(totalCakeCost) + Number(totalAllOtherCost)
              ).toFixed(2)}`,
              amount: (
                (Number(totalCakeCost) + Number(totalAllOtherCost)).toFixed(2) *
                100
              ).toString(),
              description: `Christys Rum Cakes ${Number(totalCakeCost).toFixed(
                2
              )} plus all other ${Number(totalAllOtherCost).toFixed(2)}`,
              shipped: true,
              shipper: 'USPS',
              shipMethod: shipping,
              totalCakeCost: (Number(totalCakeCost) + Number(totalAllOtherCost)).toFixed(2).toString(),
              totalUnits: totalUnits,
              discount: 0
            }}
          />
        ) : (
          <div className='mr-5'>
          {
            ((Object.entries(state.billingAddress).length === 0 && state.billingAddress.constructor === Object) &&
            (Object.entries(state.shippingAddress).length === 0 && state.shippingAddress.constructor === Object)) ? (
              <h5 className='red'>No Address Registered.</h5>
            ) : (
              null
            )
          }
          {
            (state.userCart.length === 0) ? (
              <h5 className='red'>No Items in Cart.</h5>
            ) : (
              null
            )
          }
          </div>
        )}
        <MDBBtn color='primary' outline onClick={toggle}>
          Close
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  )
}
