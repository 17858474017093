//scr/components/CouponsFacebook.js

import React from "react";
import { dateDiffInDays } from '../utilities/functions';

//===========================================================================
//===========================================================================

const CouponsFacebook = ({ expiry }) => {
    const daysToExpiry = dateDiffInDays((new Date()), (new Date(expiry)));
    //console.log('>>>-CouponsFacebook-daysToExpiry->',daysToExpiry);

    return (
        <td 
            className="p-0 m-0 s-0 text-center" 
            width="20%"
        >
        <p className="m-0 s-0 p-0">
            {
                (daysToExpiry >= 0) ? (
                    <a
                        className="mr-1"
                        href="https://www.facebook.com/christysrumcakes/"
                        target="blank"
                    >
                        <i className="fab fa-facebook-f" />
                        <span className="badge badge-pill badge-danger ml-1">
                        Coupon
                        </span>
                    </a>
                ) : (
                    ""
                )
            }
        </p>
        </td>
    )
}

export default CouponsFacebook