// src/utilities/validations.js
//
// https://regexr.com/
// https://www.regextester.com/
// https://www.freeformatter.com/regex-tester.html
//

export const validations = {
    // eslint-disable-next-line
    NOTHING: /^(.*)/,
    ANYTHING: /^(.+)/,
    EMAIL: /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PASSWORD: /^(.+){8}$/,
    USA_STATE_ABRV: /^[A-Z]{2}$/,
    PHONE_USA: /^\([\d]{3}\)?[\s-]?[\d]{3}[\s-]?[\d]{4}$/,
    // 2223334444 | 222-333-4444 | (222)-333-4444
    // PHONE_USA: /^\(?[\d]{3}\)?[\s-]?[\d]{3}[\s-]?[\d]{4}$/,
    // 12223334444 | 1-222-333-4444 | 1-(222)-333-4444
    YEAR: /^(19|20)[\d]{2,2}$/,
    // Year 1900-2099
    DATEddmmyyyy: /^([1-9]|0[1-9]|[12][0-9]|3[01])\D([1-9]|0[1-9]|1[012])\D(19[0-9][0-9]|20[0-9][0-9])$/,
    // Date (dd mm yyyy, d/m/yyyy, etc.):
    DATEyyyymmdd: /^(19[0-9][0-9]|20[0-9][0-9])\D([1-9]|0[1-9]|[12][0-9]|3[01])\D([1-9]|0[1-9]|1[012])$/,
    // Date (yyyy-dd-mm, yyyy dd mm, yyyy/m/d, etc.):
    TIMEhhmmampm: /\b((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))/,
    // Time (HH:MM AM or PM or am or pm) 
    INTEGER: /^-?\d*$/,
    INTEGER_GE0: /^\d*$/,
    INTEGER_LE0: /^-\d*$/,
    REAL: /^-?\d+((,\d+)+)?(.\d+)?(.\d+)?(,\d+)?/,
    REAL_GE0: /^\d+((,\d+)+)?(.\d+)?(.\d+)?(,\d+)?/,
    REAL_LE0: /^-\d+((,\d+)+)?(.\d+)?(.\d+)?(,\d+)?/,
    SYSTOLIC: /^\b([5-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|2[5][0])\b/,
    DIASTOLIC: /^\b([5-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|2[5][0])\b/,
    PULSE: /^\b([5-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|2[5][0])\b/,
    TEMPERATURE: /^\b(9[0-9]|10[0-9])\b/,
    AMBIENT: /^-?([0-9]|[0-9][0-9]|[0-1][0-9][0-9])(\.\d*)?$/,
    UNIT: /^\w+$/,
    GLUCOSE: /^\b([0-9]|[0-9][0-9]|[0-3][0-5][0-9])\b/,
    OXYGENATION: /^\b([5-9][0-9]|100)\b/,
    RESPIRATION: /^\b([5-9]|[1-6][0-9]|70)\b/,
    WEIGHTLBS: /^\b([0-9]|[0-9][0-9]|[0-4][0-9][0-9]|500)\b/,
    HEIGHTINS: /^\b([0-9]|[0-9][0-9]|[0-1][0-1][0-9]|120)\b/
  }
  