import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { Auth } from 'aws-amplify';
import { Context } from '../state/Context';

import LeftNav from './LeftNav';

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  NavItem,
  MDBNavLink,
  NavLink,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon
} from 'mdbreact';

//===========================================================================
//===========================================================================

export default function TopNav () {
  const { state, dispatch } = useContext(Context);
  // console.log('>>>-DoubleNav-state->', state)
  const handleSignOut = async () => {
    await Auth.signOut();
    dispatch({ type: 'USER_SIGN_OUT', payload: {} });
    return <Redirect to='/rumcakemenue' />
  };

  return (
    <MDBNavbar color='pink darken-3' dark expand='md' fixed='top' scrolling>
      <MDBCollapse id='navbarCollapse3' navbar>
        <MDBNavbarNav left>
          <MDBNavItem>
            <MDBNavLink to='/rumcakemenue'>
              <MDBNavbarBrand>
                <img src='logo32x32.PNG' className='img-fluid' alt='logo' />
              </MDBNavbarBrand>
              <MDBNavbarBrand>
                <strong className='white-text'>Christy's Rum Cakes</strong>
              </MDBNavbarBrand>
            </MDBNavLink>
          </MDBNavItem>
          
          { state.userIsAuthenticated && state.userIsAdmin ? (
            <LeftNav />
            ) : (
            null
            )
          }
        </MDBNavbarNav>

        <MDBNavbarNav right>
          {state.userIsAuthenticated && (
            <React.Fragment>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <MDBIcon icon='user' />
                  </MDBDropdownToggle>
                  <MDBDropdownMenu className='dropdown-default'>
                    
                    <MDBDropdownItem>
                      <NavLink to='/addresses'>
                        <div style={{ color: 'black' }}>Addresses</div>
                      </NavLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <NavLink to='/comments'>
                        <div style={{ color: 'black' }}>Comments</div>
                      </NavLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <NavLink to='/orders'>
                        <div style={{ color: 'black' }}>Orders</div>
                      </NavLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <NavLink to='/account'>
                        <div style={{ color: 'black' }}>Account Details</div>
                      </NavLink>
                    </MDBDropdownItem>

                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              <NavItem>
                <NavLink to='/' onClick={handleSignOut}>
                  <MDBIcon icon='sign-out-alt' size='lg' />{' '}
                  <div className='d-none d-md-inline'>Sign Out</div>
                </NavLink>
              </NavItem>
            </React.Fragment>
          )}

          {!state.userIsAuthenticated && (
            <React.Fragment>
              <NavItem>
                <NavLink to='/signin'>
                  <MDBIcon icon='sign-in-alt' size='lg' />{' '}
                  <div className='d-none d-md-inline'>Sign In</div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to='/signup'>
                  <MDBIcon icon='user-plus' size='lg' />{' '}
                  <div className='d-none d-md-inline'>Sign Up</div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to='/'>
                  <MDBIcon icon='envelope' size='lg' />{' '}
                  <div className='d-none d-md-inline'>Contact Us</div>
                </NavLink>
              </NavItem>
            </React.Fragment>
          )}

          <React.Fragment>
            <a
              href="https://www.facebook.com/christysrumcakes/"
              target="blank"
              className="btn-floating btn-sm rgba-white-slight m-1 p-0 s-0"
            >
              <i className="fab fa-facebook-f" />
            </a>
          </React.Fragment>
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  )
}
