// src/admin/dashboard/Current.js

import React from "react";

import { 
    MDBContainer, 
 } from "mdbreact";

//===========================================================================
//===========================================================================

export default function Current() {

  return (
    <main style={{ margin: "0 1%", paddingTop: "6rem" }}>
      <MDBContainer className="mx-auto green lighten-5" style={{ maxWidth: '98%' }}>
        <h1>Current</h1>
        <ul>
          <li>units by business process status</li>
          <li>user and site use stats</li>
          <li>dollars and estimated costs by business process status</li>
        </ul>
      </MDBContainer>
    </main>
  )
}
