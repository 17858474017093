/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    enabled
    userStatus
    nickname
    firstName
    lastName
    phone
    email
    registered
    tandCs
    ofAge
    createdAt
    updatedAt
    orders {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        stripeID
        stripeStatus
        total
        quantity
        shipper
        shipMethod
        discount
        specialID
        processState
        status
        createdAt
        updatedAt
        billingAddressId
        shippingAddressId
      }
      nextToken
    }
    posts {
      items {
        id
        likes
        postText
        createdAt
        updatedAt
      }
      nextToken
    }
    reminders {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        name
        description
        deadline
        createdAt
        updatedAt
      }
      nextToken
    }
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getAddress = `query GetAddress($id: ID!) {
  getAddress(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    isBilling
    isShipping
    addressTitle
    street
    street2
    city
    stateGeo
    mailCode
    country
    geoLat
    geoLng
    createdAt
    updatedAt
  }
}
`;
export const listAddresss = `query ListAddresss(
  $filter: ModelAddressFilterInput
  $limit: Int
  $nextToken: String
) {
  listAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      isBilling
      isShipping
      addressTitle
      street
      street2
      city
      stateGeo
      mailCode
      country
      geoLat
      geoLng
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getOrder = `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    stripeID
    stripeStatus
    total
    quantity
    shipper
    shipMethod
    discount
    specialID
    processState
    status
    createdAt
    updatedAt
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
    products {
      items {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        productName
        quantity
        unitPrice
        hasAlcohol
        isNormalSize
        addGarnish
        couponType
        couponLocation
        couponID
        couponUnit
        couponQuantity
        couponExpiry
        processState
        status
        createdAt
        updatedAt
      }
      nextToken
    }
    billingAddressId
    shippingAddressId
  }
}
`;
export const listOrders = `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      stripeID
      stripeStatus
      total
      quantity
      shipper
      shipMethod
      discount
      specialID
      processState
      status
      createdAt
      updatedAt
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
      products {
        nextToken
      }
      billingAddressId
      shippingAddressId
    }
    nextToken
  }
}
`;
export const getProduct = `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    productName
    quantity
    unitPrice
    hasAlcohol
    isNormalSize
    addGarnish
    couponType
    couponLocation
    couponID
    couponUnit
    couponQuantity
    couponExpiry
    processState
    status
    createdAt
    updatedAt
    order {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      stripeID
      stripeStatus
      total
      quantity
      shipper
      shipMethod
      discount
      specialID
      processState
      status
      createdAt
      updatedAt
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
      products {
        nextToken
      }
      billingAddressId
      shippingAddressId
    }
  }
}
`;
export const listProducts = `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      productName
      quantity
      unitPrice
      hasAlcohol
      isNormalSize
      addGarnish
      couponType
      couponLocation
      couponID
      couponUnit
      couponQuantity
      couponExpiry
      processState
      status
      createdAt
      updatedAt
      order {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        stripeID
        stripeStatus
        total
        quantity
        shipper
        shipMethod
        discount
        specialID
        processState
        status
        createdAt
        updatedAt
        billingAddressId
        shippingAddressId
      }
    }
    nextToken
  }
}
`;
export const getPost = `query GetPost($id: ID!) {
  getPost(id: $id) {
    id
    likes
    postText
    createdAt
    updatedAt
    comments {
      items {
        id
        commentText
        author
        likes
        createdAt
        updatedAt
      }
      nextToken
    }
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
  }
}
`;
export const listPosts = `query ListPosts(
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      likes
      postText
      createdAt
      updatedAt
      comments {
        nextToken
      }
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
    }
    nextToken
  }
}
`;
export const getComment = `query GetComment($id: ID!) {
  getComment(id: $id) {
    id
    commentText
    author
    likes
    createdAt
    updatedAt
    post {
      id
      likes
      postText
      createdAt
      updatedAt
      comments {
        nextToken
      }
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
    }
  }
}
`;
export const listComments = `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      commentText
      author
      likes
      createdAt
      updatedAt
      post {
        id
        likes
        postText
        createdAt
        updatedAt
      }
    }
    nextToken
  }
}
`;
export const getLike = `query GetLike($id: ID!) {
  getLike(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    content
    liked
    postId
    createdBy
    createdAt
    updatedAt
  }
}
`;
export const listLikes = `query ListLikes(
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      content
      liked
      postId
      createdBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getFollowing = `query GetFollowing($id: ID!) {
  getFollowing(id: $id) {
    id
    followerId
    followingId
    createdAt
    updatedAt
  }
}
`;
export const listFollowings = `query ListFollowings(
  $filter: ModelFollowingFilterInput
  $limit: Int
  $nextToken: String
) {
  listFollowings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      followerId
      followingId
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getReminder = `query GetReminder($id: ID!) {
  getReminder(id: $id) {
    id
    cognitoId
    chId
    usernameAWS
    ownerAWS
    name
    description
    deadline
    createdAt
    updatedAt
    user {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      enabled
      userStatus
      nickname
      firstName
      lastName
      phone
      email
      registered
      tandCs
      ofAge
      createdAt
      updatedAt
      orders {
        nextToken
      }
      posts {
        nextToken
      }
      reminders {
        nextToken
      }
    }
  }
}
`;
export const listReminders = `query ListReminders(
  $filter: ModelReminderFilterInput
  $limit: Int
  $nextToken: String
) {
  listReminders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      name
      description
      deadline
      createdAt
      updatedAt
      user {
        id
        cognitoId
        chId
        usernameAWS
        ownerAWS
        enabled
        userStatus
        nickname
        firstName
        lastName
        phone
        email
        registered
        tandCs
        ofAge
        createdAt
        updatedAt
      }
    }
    nextToken
  }
}
`;
export const likesByUser = `query LikesByUser(
  $createdBy: ID
  $createdAt: ModelStringKeyConditionInput
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  likesByUser(
    createdBy: $createdBy
    createdAt: $createdAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      chId
      usernameAWS
      ownerAWS
      content
      liked
      postId
      createdBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const listFollowing = `query ListFollowing(
  $followerId: ID
  $createdAt: ModelStringKeyConditionInput
  $filter: ModelFollowingFilterInput
  $limit: Int
  $nextToken: String
) {
  listFollowing(
    followerId: $followerId
    createdAt: $createdAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      followerId
      followingId
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;