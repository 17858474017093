//src/pages/footer/TermsOfUseModal.ja

import React from 'react';

import { 
  MDBBtn, 
  MDBModal,
  MDBModalBody, 
  MDBModalHeader, 
  MDBModalFooter } from 'mdbreact';

//===========================================================================
//===========================================================================

const TermsOfUseModal = ({ isOpen, toggle }) => {
  //console.log('>>>-MoreModal-{ isOpen, toggle, cakeName }->',{isOpen,toggle,cakeName})

    return (
        <MDBModal 
          size="small" 
          isOpen={isOpen} 
          toggle={toggle} 
          centered
          className="modal-notify modal-info text-white" 
        >  
        <MDBModalHeader>Christy&apos;s Rum Cake</MDBModalHeader>
        <MDBModalBody>
          <h3>
          Terms and Conditions of Use
          </h3>
          <hr/>
          <p>
          User Agreement for Access to the Christy’s Rum Cakes World Wide Website
          </p>
          <p>
          Christy’s Rum Cakes., its subsidiaries, and affiliated companies (collectively referred to as "CRC") require that any visitor ("User") to its World Wide Websites ("Site") adhere to the following rules and regulations. By accessing any Site, User indicates User's acceptance of and agreement to these terms and conditions, as amended from time to time by CRC.
          </p>
          <p>
          General Disclaimers
          </p>
          <p>
          This Site, including all software, functions, materials, and information, and any products or services made available through this Site, are provided "as is" without warranties of any kind, either express or implied, unless otherwise expressly stated herein. CRC disclaims all warranties express or implied, including but not limited to warranties of quiet enjoyment and non-infringement, as well as any implied warranties of merchantability or fitness for a particular purpose as to any computer programs, or the design or informational content of the Site.
          </p>
          <p>
          CRC does not warrant or make any representations regarding operation of the Site. CRC does not warrant or make any representation regarding the validity, accuracy, or reliability of the results of the use of the materials on the Site or any other World Wide Web resource or site linked to this Site. The materials on this Site may be out of date, and CRC makes no commitment to update the materials on this Site.
          </p>
          <p>
          CRC does not and cannot guarantee or warrant that the files available for downloading from this Site, if any, will be free from infection, viruses, worms, Trojan horses, or other code that manifests contaminating or destructive properties. CRC does not warrant that the Site, software, materials, products, or service will be uninterrupted or error free or that any defects in the Site or any element thereof will be corrected. Applicable law may not allow the disclaiming of implied warranties, so the above disclaimer may not apply to individual Users as it relates to implied warranties.
          </p>
          <p>
          In no event shall CRC, its agents or affiliates, or any third parties mentioned within the Site be liable for any damages whatsoever (including, without limitation, incidental and consequential damages, lost profits, or damages resulting from lost data or business interruption) resulting from or arising out of the use or inability to use the Site and the materials contained therein; all whether based on contract, tort, or any other legal theory, and whether or not WRI is advised of the possibility of such damages.
          </p>
          <p>
          Links
          </p>
          <p>
          On the Site, CRC may include links to World Wide Websites or resources for User convenience which are not created or controlled by CRC ("Linked sites"). WRI does not endorse or accept responsibility for any such Linked site or resource User may access through CRC’s Site. Linked sites are wholly independent from CRC; as a result, CRC has no control over any products, services, materials, or other information contained in or available through these third-party websites. The quality and dependability of these third-party websites may vary as they are updated or altered. Therefore, access to any third-party websites through CRC's Site, regardless of whether the third-party site is a Linked site or not, is entirely at the User's own risk.
          </p>
          <p>
          Indemnity
          </p>
          <p>
          User agrees to indemnify and hold CRC, its agents, affiliates, and assigns, harmless from and against any and all claims, losses, liability, cost, or expense (including but not limited to reasonable attorney's fees) relating to or arising from: 1) the violation or breach of these Terms and Conditions; 2) violation or infringement of any third-party's rights, including but not limited to copyrights and proprietary and privacy rights, resulting from User's breach of these Terms and Conditions; or 3) any violation of applicable federal, state, or local law, regulation, or ordinance resulting from User's breach of these Terms and Conditions.
          </p>
          <p>
          Trademark Information
          </p>
          <p>
          CRC's trademarks may be used or reproduced publicly only with permission from CRC. Fair use of CRC's trademarks in advertising or promotion of CRC products requires proper acknowledgment. All other brands are the property of their respective owners.
          </p>
          <p>
          Copyright and Ownership of Materials
          </p>
          <p>
          Except where otherwise noted, all materials, software, and information on the Site, as well as any unique visual or functional elements of the Site, are copyrighted and are protected by worldwide copyright laws and treaty provisions. They may not be copied, reproduced, modified, uploaded, posted, transmitted, or distributed in any way, in whole or in part, without CRC's prior written permission.
          </p>
          <p>
          Additional General Terms
          </p>
          <p>
          Users are encouraged to peruse this Site, but must recognize that its content is protected by international copyright, trademark and other intellectual property laws and may not be mirrored, redistributed, printed, publicly performed or displayed, reproduced in bulk, or archived without advance written permission. Access to the Site may be blocked without notice to subnets violating these Terms of Use or otherwise at the discretion of CRC.
          </p>
          <p>
          This Site was created, and is maintained, in the State of Illinois, United States of America. CRC makes no claims that the materials contained on the Site are appropriate or may be downloaded outside of the United States. Access to the Site and the materials therein (including software) may not be legal by certain persons or in certain countries. If User accesses this Site from outside the United States, that access is made at User's own risk. User is responsible for compliance with the laws of User's jurisdiction.
          </p>
          <p>
          Use of the Site, the Terms and Conditions of use, and all other aspects of the operation and functionality of the Site shall be governed by and construed under the laws of the State of New Jersey, without giving effect to its rules regarding conflicts of laws. CRC and User hereby irrevocably consent to the jurisdiction of the courts located in Illinois for any action, claim, or cause arising out of or related to the use of the Site and any products or services obtained through the Site. If any provision of these Terms and Conditions is found to be invalid by a court of competent jurisdiction, the invalidity of such provision shall not impair the validity of the remaining Terms and Conditions. No waiver of any provision of the Terms and Conditions shall be deemed a further waiver of any other provision thereof.
          </p>
          <p>
          CRC takes privacy and data protection seriously, and is committed to safeguarding data you share with us. To learn more, please see our Privacy Policy.
          </p>
        </MDBModalBody>

        <MDBModalFooter>
            <MDBBtn 
                color="primary" 
                onClick={toggle}>
                Close
            </MDBBtn>
        </MDBModalFooter>
        </MDBModal>
    );
}

export default TermsOfUseModal