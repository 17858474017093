//scr/admin/users/AdminAddresses

import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../../state/Context'
import { API, graphqlOperation } from "aws-amplify";
import { subDays, addDays } from 'date-fns'

import AdminModalAddAddress from './AdminModalAddAddress'
import DataTable from '../../../components/DataTable'

import {
  MDBCard,
  MDBCardHeader,
  MDBContainer, 
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBBtn
} from "mdbreact";

import { adminListAddresses } from '../../../utilities/lists'
import { listAddresss } from "../../../graphql/queries";


export default function AdminAddresses({ selectedUser }) {
  const { state } = useContext(Context)
  const [ selectedAddress, setSelectedAddress ] = useState([])
  const [ editAddress, setEditAddress ] = useState([])
  const [ dataTableHeaders, setDataTableHeaders ] = useState([]);
  const [ dataTableBody, setDataTableBody ] = useState([]);
  
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isAddAddressModalOpen, setIsAddAddressModalOpen ] = useState(false)

  useEffect(() => { 
    if (Object.keys(selectedUser).length > 0) {
      getUserAddresses(selectedUser.id)
    } else {
      return
    } 
  },[selectedUser])

  const handleSelectedAddress = (item) => {
    //console.log('>>>-AdminAddresses-handleSelectedUser-item->',item)
    setSelectedAddress(item)
  }
  const handleEditAddress = (item) => {
    //console.log('>>>-AdminAddresses-handleEditUser-item->',item)
    setEditAddress(item)
  }
  const makeAddressRows = data => {
    if (data.length > 0) {
      return data.map(item => ({
        
        actions: (
          <React.Fragment>
          <MDBBtn
            color="green"
            size='sm'
            id={item.id}
            onClick={() => handleSelectedAddress(item)}
            style={{ spacing: 1, margin: 1, padding: 1 }}
            disabled={isLoading}
          >
            Select
          </MDBBtn>
          <MDBBtn
            color="red"
            size='sm'
            id={item.id}
            onClick={() => handleEditAddress(item)}
            style={{ spacing: 1, margin: 1, padding: 1 }}
            disabled={isLoading}
          >
            Edit
          </MDBBtn>
          </React.Fragment>
        ),
        addressTitle: item.addressTitle,
        street: item.street,
        street2: item.street2,
        city: item.city,
        stateGeo: item.stateGeo,
        mailCode: item.mailCode,
        country: item.country
      }))
    } else {
      return {}
    }
  }

  const getUserAddresses = async (id) => {
    setIsLoading(true)
    if (state.userIsAuthenticated && state.userIsAdmin) {
      const startDate = subDays( new Date(), 181 * 4 )
      const endDate = addDays( new Date(), 1 )
      //"f51bfd31-a1d5-4fa6-9fb9-f2f6266b9ca0  ---@aitoconsulting.com"
      //"01b3fda3-0b03-49f8-931c-337cbbe7abad  ---@christysrumcakes.com"
      //"d805a82a-fd3b-4f10-b600-18d65dfe440e  ---@christyhealth.com"
      const ModelAddressFilterInput = { 
        cognitoId: { eq: id },
        createdAt: { between: [startDate, endDate] } 
      }
      try {
        const result = await API.graphql(graphqlOperation(listAddresss, {
          limit:10,
          filter: ModelAddressFilterInput
        }));
        const addrList = result.data.listAddresss.items
        //console.log(">>>-AdminAddresses-getUserProducts-addrList->", addrList)
        setDataTableHeaders(adminListAddresses.columns.map(col => col.label))
        setDataTableBody(makeAddressRows(addrList))
        //setUserAddresses(result.data.listAddresss.items)
      } catch (err) {
        setDataTableHeaders([])
        setDataTableBody([])
        console.log(">>>-AdminAddresses-error fetching listAddresss...", err);
        alert(`In AdminAddresses-listAddresss-catch ${err.message}`)
      }
    }
    setIsLoading(false)
  }

  return (
    <MDBCard>
      <MDBCardHeader tag="h4" className="text-center font-weight-bold text-uppercase py-2">
        <MDBContainer>
          <MDBRow>
            <MDBCol size="9" md="6">
              <p>Addresses</p>
            </MDBCol>
            <MDBCol size="9" md="6">
              <MDBBtn
                color="primary"
                size='lg'
                onClick={() => setIsAddAddressModalOpen(!isAddAddressModalOpen)}
              >
                Add New Address
              </MDBBtn>
              <AdminModalAddAddress 
                isOpen={isAddAddressModalOpen}
                toggle={() => setIsAddAddressModalOpen(!isAddAddressModalOpen)}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBCardHeader>
      <MDBCardBody>
        {(Object.keys(selectedUser).length > 0) 
          ? (<div className="container">
              <table className="table">
                <thead>
                  <tr>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Email</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{selectedUser.firstName}</td>
                    <td>{selectedUser.lastName}</td>
                    <td>{selectedUser.email}</td>
                    <td>{selectedUser.phone}</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          ) : (
          <div><h4>Select a user.</h4></div>
          )
        }
        {
          (Object.keys(selectedUser).length > 0 && Object.keys(dataTableBody).length > 0) ? (
            <div>
              <hr/>
              <DataTable 
                dataTableHeaders={dataTableHeaders} 
                dataTableBody={dataTableBody} 
              />
            </div>
          ) : (
            (Object.keys(selectedUser).length > 0 && Object.keys(dataTableBody).length < 1) ? (
              <div><h4>No products in users cart.</h4></div>
            ) : (
              null
            )
          )
        }
        
        <p className="mt-2 red">Manage address</p>

      </MDBCardBody>
    </MDBCard>
  )
}


