// src/pages/shop/Orders.js

import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../state/Context'
import { API, graphqlOperation } from "aws-amplify";
import { format as FNSformat, addDays } from 'date-fns'

import {
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  NavLink,
  MDBBtn
} from 'mdbreact'

import { listOrders, getOrder } from "../../graphql/queries";

//===========================================================================
//===========================================================================

export default function Orders() {
  const { state, dispatch } = useContext(Context);
  //console.log('>>>-Orders-state.userOrders->', state.userOrders)
  const [orderBook, setOrderBook] = useState([]);
  const [orderProducts, setOrderProducts] = useState(false)
  useEffect(() => {
    const result = async () => {
      if (state.userIsAuthenticated === true) {
        try {
          const result = await API.graphql(graphqlOperation(listOrders));
          const result2 = result.data.listOrders.items;
          setOrderBook(result2);
          //console.log('>>>-Orders-listOrderBookAppsync-result2->', result2)
          dispatch({ type: "LOAD_ORDER_BOOK", payload: result2 });
        } catch (err) {
          alert(err.message)
          console.log(">>>-Orders-error fetching getAddress...", err);
        }
      } else {
        return
      }
    }
  }, [dispatch, state.userIsAuthenticated]);

  const handleGetOrderProducts = async (id) => {
    //console.log('>>>-Orders-handleGetOrderProducts-id->',id)
    const result = await API.graphql(graphqlOperation(getOrder, { id }))
    //console.log('>>>-Orders-handleGetOrderProducts-result->',result)
    const result2 = result.data.getOrder.products.items
    //console.log('>>>-Orders-handleGetOrderProducts-result2->',result2)
    setOrderProducts(result2)
  }

  return (
    <main style={{ margin: "0 1%", paddingTop: "6rem" }}>
      <MDBCard
        className='mx-auto pink lighten-5'
        style={{ fontWeight: 300, maxWidth: '98%' }}
      >
        <MDBCardBody>
          <h2 className='h1-responsive font-weight-bold text-center my-1'>
            Your Open Orders
            </h2>
          <p className='text-center w-responsive mx-auto mb-2'>
            We're working hard to get the yumminess to you.
            </p>
          <p className='text-center w-responsive mx-auto mb-2'>
            Contact us if you have any questions.
            </p>
        </MDBCardBody>

        {
          (orderBook.length > 0) ? (
            <div>
              <MDBTable className='text-center my-2' small>
                <MDBTableHead color="primary-color" textWhite>
                  <tr>
                    <th></th>
                    <th>Order Id</th>
                    <th>Purchase Date*</th>
                    <th>Quantity</th>
                    <th>Total</th>
                    <th>Shipping</th>
                    <th>Status</th>
                    <th>Process</th>
                    <th>Expected Delivery*</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {orderBook.map(item => (
                    <tr key={item.id}>
                      <td>
                        <MDBBtn
                          color="blue"
                          size='sm'
                          id={item.id}
                          onClick={e => handleGetOrderProducts(e.target.id)}
                          style={{ spacing: 1, margin: 1, padding: 1 }}
                        >
                          Details
                            </MDBBtn>
                      </td>
                      <td>{item.id}</td>
                      <td>{FNSformat(new Date(item.createdAt), 'yyyy-MM-dd')}</td>
                      <td>{item.quantity}</td>
                      <td>${item.total.toFixed(2)}</td>
                      <td>{item.shipMethod}</td>
                      <td>{item.status}</td>
                      <td>{item.processState}</td>
                      <td>{FNSformat(addDays(new Date(item.createdAt), 4), 'yyyy-MM-dd')}</td>
                    </tr>
                  )
                  )}
                </MDBTableBody>
              </MDBTable>
              <div>
                <p>* Date format is yyyy-mm-dd</p>
              </div>
              {
                (orderBook.length > 0 && orderProducts.length < 1) ? (
                  <div>
                    <h5 className='text-center my-2'>Want the details? click the button.</h5>
                  </div>
                ) : (
                    null
                  )
              }
            </div>
          ) : (
              <div>
                <h5 className='text-center my-2'>You have no open orders.</h5>
              </div>
            )
        }

        {
          (orderProducts.length > 0) ? (
            <div>

              <hr />
              <MDBTable className='text-center my-2' small>
                <MDBTableHead color="secondary-color" textWhite>
                  <tr>
                    <th>Purchase Date*</th>
                    <th>Cake</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Size</th>
                    <th>Alcohol</th>
                    <th>Garnish</th>
                    <th>Coupon</th>
                    <th>Discount</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {orderProducts.map(item => (
                    <tr key={item.id}>
                      <td>{FNSformat(new Date(item.createdAt), 'yyyy-MM-dd')}</td>
                      <td>{item.productName}</td>
                      <td>{item.quantity}</td>
                      <td>${item.unitPrice}</td>
                      <td>{item.productName.search("Mini") > 0 ? 'mini' : 'normal'}</td>
                      <td>{item.hasAlcohol ? 'yes' : 'no'}</td>
                      <td>{item.addGarnish ? 'yes' : 'no'}</td>
                      <td>{item.couponType}</td>
                      <td>{item.couponQuantity}%</td>
                    </tr>
                  )
                  )}
                </MDBTableBody>
              </MDBTable>
            </div>
          ) : (
              null
            )
        }

        <hr />
        <NavLink to='/rumcakemenue' className='s-0 p-0 m-0'>
          <MDBBtn color='green' size='sm'>
            Go To Menu Page
            </MDBBtn>
        </NavLink>

      </MDBCard>
    </main>
  )
}