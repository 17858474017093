
import React from "react";

const NewCake = ({ lable }) => {

    return (
        <td 
            className="p-0 m-0 s-0 text-center" 
            width="15%"
        >            
        <p className="m-0 s-0 p-0">
            <span className="badge badge-pill badge-success ml-1">{lable}</span>
        </p>
        </td>
    )
}

export default NewCake