// src/utilities/lists.js

import { cakeAmount } from "./functions";

export const cakeList = [
  {
    id: 1,
    name: "Original",
    price: cakeAmount("Original"),
    imagePath300: "images/Original300.jpg",
    imagePath600: "images/Original600.jpg",
    garnishes: "",
    sizes: ["regular","mini"],
    coupon: {
      couponType: "Coupon",
      couponLocation: "facebook",
      couponID: "8116491632888194521",
      couponUnit: "Percent",
      couponQuantity: "15",
      couponExpiry: "2019-10-10"
    },
    description:
      "Made from scratch with premium ingredients, this moist delicious vanilla rum cake is a top shelf original.",
  },
  {
    id: 2,
    name: "Dark Chocolate",
    price: cakeAmount("Dark Chocolate"),
    imagePath300: "images/DarkChocolate300.jpg",
    imagePath600: "images/DarkChocolate600.jpg",
    garnishes: "",
    sizes: ["regular","mini"],
    coupon: {
      couponType: "Coupon",
      couponLocation: "facebook",
      couponID: "4121272542865338333",
      couponUnit: "Percent",
      couponQuantity: "15",
      couponExpiry: "2019-10-10"
    },
    description:
      "Made from scratch with fresh ingredients and premium dark chocolate with a buttery and real rum sauce.",
  },
  {
    id: 3,
    name: "Bananas Foster",
    price: cakeAmount("Bananas Foster"),
    imagePath300: "images/BananasFoster300.jpg",
    imagePath600: "images/BananasFoster600.jpg",
    garnishes: "",
    sizes: ["regular","mini"],
    coupon: {
      couponType: "Coupon",
      couponLocation: "facebook",
      couponID: "3359501940720522637",
      couponUnit: "percent",
      couponQuantity: "15",
      couponExpiry: "2019-10-10"
    },
    description:
      "Caramelized bananas top this banana rum cake producing a smooth and sweet tasty treat.",
  },
  {
    id: 4,
    name: "Ginger Lime",
    price: cakeAmount("Ginger Lime"),
    imagePath300: "images/GingerLime300.jpg",
    imagePath600: "images/GingerLime600.jpg",
    garnishes: "",
    sizes: ["regular","mini"],
    coupon: {
      couponType: "Coupon",
      couponLocation: "facebook",
      couponID: "5875205429321172897",
      couponUnit: "percent",
      couponQuantity: "15",
      couponExpiry: "2019-10-10"
    },
    description:
      "Crystallized ginger bits along with lime zest and juice makes this a moist and zingy cake that will test your taste buds with tingle and delight.",
  },
  {
    id: 5,
    name: "Coconut",
    price: cakeAmount("Coconut"),
    imagePath300: "images/Coconut300.jpg",
    imagePath600: "images/Coconut600.jpg",
    garnishes: "",
    sizes: ["regular","mini"],
    coupon: {
      couponType: "Coupon",
      couponLocation: "facebook",
      couponID: "7752985497517278006",
      couponUnit: "percent",
      couponQuantity: "15",
      couponExpiry: "2019-10-10"
    },
    description:
      "If you like coconut, try coconut soaked in rum.  There are so many coconut flakes in this moist coconut rum cake you will think you are on a beach with a delicious cocktail.",
  },
  {
    id: 6,
    name: "Spiced Apple",
    price: cakeAmount("Spiced Apple"),
    imagePath300: "images/SpicedApple300.jpg",
    imagePath600: "images/SpicedApple600.jpg",
    garnishes: "",
    sizes: ["regular","mini"],
    coupon: {
      couponType: "Coupon",
      couponLocation: "facebook",
      couponID: "1178969077236784993",
      couponUnit: "percent",
      couponQuantity: "15",
      couponExpiry: "2019-10-10"
    },
    description:
      "Loads of delicious spiced apple pieces are swirled throughout this cake.  You will yield to the eye-closing deliciousness.",
  },
  {
    id: 7,
    name: "Cinnamon Bun",
    price: cakeAmount("Cinnamon Bun"),
    imagePath300: "images/CinnamonBun300.jpg",
    imagePath600: "images/CinnamonBun600.jpg",
    garnishes: "",
    sizes: ["regular","mini"],
    coupon: {
      couponType: "Coupon",
      couponLocation: "facebook",
      couponID: "3376166476843835844",
      couponUnit: "percent",
      couponQuantity: "15",
      couponExpiry: "2019-10-10"
    },
    description:
      "All the cinnamon bun flavors of cream cheese and layers of cinnamon sugar swirls.  Just close your eyes and shiver with delight...",
  },
  {
    id: 8,
    name: "Apple Cinnamon",
    price: cakeAmount("Apple Cinnamon"),
    imagePath300: "images/AppleCinnamon300.jpg",
    imagePath600: "images/AppleCinnamon600.jpg",
    garnishes: "",
    sizes: ["regular","mini"],
    coupon: {
      couponType: "Coupon",
      couponLocation: "facebook",
      couponID: "8282539994401140249",
      couponUnit: "percent",
      couponQuantity: "15",
      couponExpiry: "2019-10-10"
    },
    description:
      "Like the Spiced Apple and Cinnamon Bun?  Try this with your coffee – just once.  We dare you!  You’ll be hooked.",
  },{
    id: 9,
    name: "Butter Maple Pecan",
    price: cakeAmount("Butter Maple Pecan"),
    imagePath300: "images/ButterMaplePecan300.jpg",
    imagePath600: "images/ButterMaplePecan600.jpg",
    garnishes: "Candied pecans: 1 cup normal cake size",
    sizes: ["regular","mini"],
    coupon: {
      couponType: "New",
      couponLocation: "",
      couponID: "",
      couponUnit: "",
      couponQuantity: "",
      couponExpiry: ""
    },
    description:
      "Delicious maple syrup along with crushed pecans and rum, make this a divine rum cake for any occasions this fall.",
  },
  {
    id: 10,
    name: "Cranberry Lemon",
    price: cakeAmount("Cranberry Lemon"),
    imagePath300: "images/CranberryLemon300.jpg",
    imagePath600: "images/CranberryLemon600.jpg",
    garnishes: "Sugared cranberries: 1 cup normal cake size",
    sizes: ["regular","mini"],
    coupon: {
      couponType: "New",
      couponLocation: "",
      couponID: "",
      couponUnit: "",
      couponQuantity: "",
      couponExpiry: ""
    },
    description:
      "This moist rum cake is loaded with fresh cranberries along with fresh squeezed lemon juice and zest. It’s a great dessert for the entire fall and winter season.",
  },
  {
    id: 11,
    name: "Dark Chocolate Heart",
    price: cakeAmount("Dark Chocolate Heart"),
    imagePath300: "images/DarkChocolateHeart300.jpg",
    imagePath600: "images/DarkChocolateHeart600.jpg",
    garnishes: "",
    sizes: ["regular","mini"],
    coupon: {
      couponType: "New",
      couponLocation: "",
      couponID: "",
      couponUnit: "",
      couponQuantity: "",
      couponExpiry: ""
    },
    description:
      "This moist rum cake is loaded with fresh cranberries along with fresh squeezed lemon juice and zest. It’s a great dessert for the entire fall and winter season.",
  },
  {
    id: 12,
    name: "Red Velvet Heart",
    price: cakeAmount("Red Velvet Heart"),
    imagePath300: "images/RedVelvetHeart300.jpg",
    imagePath600: "images/RedVelvetHeart600.jpg",
    garnishes: "",
    sizes: ["regular","mini"],
    coupon: {
      couponType: "New",
      couponLocation: "",
      couponID: "",
      couponUnit: "",
      couponQuantity: "",
      couponExpiry: ""
    },
    description:
      "This moist rum cake is loaded with fresh cranberries along with fresh squeezed lemon juice and zest. It’s a great dessert for the entire fall and winter season.",
  },
];


export const commentList = [
  {
    id: 1,
    userName: "Margaret",
    post: "Christy’s Rum Cakes are out of this world!! Shipping is outstanding and prompt, top notch! These Rum cakes take you right to the magic of gorgeous Montego Bay.",
    stars: 5,
  },
  {
    id: 2,
    userName: "Ron and Debby",
    post: "These cakes are SO great!! Two thumbs up!!",
    stars: 5,
  },
  {
    id: 3,
    userName: "Helen",
    post: "I cannot say enough about Christy’s Rum Cakes. The Apple Cinnamon and Ginger Lime were absolutely fantastic!! Well done, Chef Christy!!",
    stars: 4,
  },
  {
    id: 4,
    userName: "Pam",
    post: "I had to reach out and tell you that my coconut rum birthday cake was the best cake I've ever had.",
    stars: 5,
  },
  {
    id: 5,
    userName: "Jeff",
    post: "The best original rum cake I've ever eaten - GM one of NYC's best italian eateries.",
    stars: 4,
  },
  {
    id: 6,
    userName: "Mitch",
    post: "The chololate rum cake was soOOoo delicious.",
    stars: 5,
  },
  {
    id: 7,
    userName: "Jim",
    post: "After a filling meal, I hesitated.  I'm so glad I tried the ginger lime rum cake.  It was refreashing and light.",
    stars: 4,
  },{
    id: 8,
    userName: "Adrienne",
    post: "best cakes ever... can't find their equal.",
    stars: 5,
  },
  {
    id: 9,
    userName: "Pam",
    post: "Where did it go?  It's already half gone! I'm a coconut freak and this hits the spot.",
    stars: 5,
  },
]

export const adminListUsers = {
  columns: [
    {
      label: 'Action',
      field: 'actions',
      sort: "asc",
      width: 75
    },
    {
      label: 'First Name',
      field: 'firstName',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Last Name',
      field: 'lastName',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Email',
      field: 'email',
      sort: 'asc',
      width: 200
    },
    {
      label: 'Phone',
      field: 'phone',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Of Age',
      field: 'ofAge',
      sort: 'asc',
      width: 75
    },
    {
      label: 'Reg',
      field: 'registered',
      sort: 'asc',
      width: 75
    },
    {
      label: 'T & Cs',
      field: 'tandCs',
      sort: 'asc',
      width: 75
    }
  ]
}

export const adminListCart = {
  columns: [
    {
      label: 'Action',
      field: 'actions',
      sort: "asc",
      width: 65
    },
    {
      label: 'Quantity',
      field: 'quantity',
      sort: 'asc',
      width: 75
    },
    {
      label: 'Product Name',
      field: 'productName',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Alcohol',
      field: 'alcohol',
      sort: 'asc',
      width: 75
    },
    {
      label: 'Garnish',
      field: 'garnish',
      sort: 'asc',
      width: 75
    },
    {
      label: 'Unit Price',
      field: 'unitPrice',
      sort: 'asc',
      width: 60
    },
    {
      label: 'Normal Size',
      field: 'normal size',
      sort: 'asc',
      width: 75
    },
    {
      label: 'Coupon Type',
      field: 'couponType',
      sort: 'asc',
      width: 75
    },
    {
      label: 'Quantity',
      field: 'couponQuantity',
      sort: 'asc',
      width: 75
    },
    {
      label: 'Unit',
      field: 'couponUnit',
      sort: 'asc',
      width: 75
    },
    {
      label: 'Location',
      field: 'couponLocation',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Expiry',
      field: 'couponExpiry',
      sort: 'asc',
      width: 100
    },
    {
      label: 'State',
      field: 'processState',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Created At',
      field: 'createdAt',
      sort: 'asc',
      width: 175
    },
    {
      label: 'id',
      field: 'id',
      sort: 'asc',
      width: 325
    }
  ]
}

export const adminListOrder = {
  columns: [
    {
      label: 'Action',
      field: 'actions',
      sort: "asc",
      width: 100
    },
    {
      label: 'Quantity',
      field: 'quantity',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Total',
      field: 'total',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Discount',
      field: 'discount',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Ship Method',
      field: 'shipMethod',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Shipper',
      field: 'shipper',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Card Status',
      field: 'stripeStatus',
      sort: 'asc',
      width: 100
    },
    {
      label: 'State',
      field: 'processState',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Status',
      field: 'status',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Created At',
      field: 'createdAt',
      sort: 'asc',
      width: 175
    },
    {
      label: 'Billing Address Id',
      field: 'billingAddressId',
      sort: 'asc',
      width: 325
    },
    {
      label: 'Shipping Address Id',
      field: 'shippingAddressId',
      sort: 'asc',
      width: 325
    },
    {
      label: 'id',
      field: 'id',
      sort: 'asc',
      width: 325
    }
  ]
}

export const adminListAddresses = {
  columns: [
    {
      label: 'Action',
      field: 'actions',
      sort: "asc",
      width: 100
    },
    {
      label: 'Title',
      field: 'addressTitle',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Street',
      field: 'street',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Street (2)',
      field: 'street2',
      sort: 'asc',
      width: 100
    },
    {
      label: 'City',
      field: 'city',
      sort: 'asc',
      width: 100
    },
    {
      label: 'State',
      field: 'stateGeo',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Code',
      field: 'mailCode',
      sort: 'asc',
      width: 100
    },
    {
      label: 'Country',
      field: 'country',
      sort: 'asc',
      width: 100
    },
  ]
}