import React from "react";

import {
    MDBCol,
    MDBTestimonial,
    MDBIcon
  } from "mdbreact";

const TestimonialsCarouselItem = ({ ky, name, post, stars }) => {
    // const makeStars = (s) => {
    //     var i;
    //     var starIcons = "";
    //     for (i = 0; i < s; i++) {
    //         starIcons += `<MDBIcon key=${i} icon='star' />`
    //     }
    //     return starIcons
    // }

    return (
        <MDBCol key={ky ? ky : 0} md="4" className="clearfix d-none d-md-block">
            <MDBTestimonial>
            <h4 className="font-weight-bold mt-1">{name} says...</h4>
            <p className="font-weight-normal">
                <MDBIcon icon="quote-left" className="pr-0" />
                ...{post}
            </p>
            <div className="green-text">
                <MDBIcon icon="star" />
                <MDBIcon icon="star" />
                <MDBIcon icon="star" />
                <MDBIcon icon="star" />
                <MDBIcon icon="star" />
            </div>
            </MDBTestimonial>
        </MDBCol>
    )
}

export default TestimonialsCarouselItem
