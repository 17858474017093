//src/admin/users/AdminModalAddUser.ja

import React from "react";

import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn
} from "mdbreact";


const AdminModalAddUser = ({ isOpen, toggle }) => {
  //console.log('>>>-AdminModalAddUser-{ isOpen, toggle }->',{isOpen,toggle})

  return (
    <MDBModal
      size="small"
      isOpen={isOpen}
      toggle={toggle}
      className="modal-notify modal-info text-white"
    >
      <MDBModalHeader>Add a New User</MDBModalHeader>
        <MDBModalBody>
          <p>in Admin Add User Modal...</p>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="primary" onClick={toggle}>
            Close
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
  )
}

export default AdminModalAddUser