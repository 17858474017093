// src/pages/shop/History.js

import React from 'react'

import {
    MDBCard,
    MDBCardBody,
    MDBDataTable
} from 'mdbreact'


export default function Comments() {

    const colArray = {
        columns: [
            {
                label: 'Name',
                field: 'name',
                sort: 'asc'
            },
            {
                label: 'Position',
                field: 'position',
                sort: 'asc'
            },
            {
                label: 'Office',
                field: 'office',
                sort: 'asc'
            },
            {
                label: 'Age',
                field: 'age',
                sort: 'asc'
            },
            {
                label: 'Start date',
                field: 'date',
                sort: 'asc'
            },
            {
                label: 'Salary',
                field: 'salary',
                sort: 'asc'
            }
        ]
    }
    const data = {
        columns: colArray.columns,
        rows: [
            {
                name: 'Yuri Berry',
                position: 'Chief Marketing Officer (CMO)',
                office: 'New York',
                age: '40',
                date: '2009/06/25',
                salary: '$675'
            },
            {
                name: 'Caesar Vance',
                position: 'Pre-Sales Support',
                office: 'New York',
                age: '21',
                date: '2011/12/12',
                salary: '$106'
            },
            {
                name: 'Doris Wilder',
                position: 'Sales Assistant',
                office: 'Sidney',
                age: '23',
                date: '2010/09/20',
                salary: '$85'
            },
        ]
    };
    return (
        <main style={{ margin: "0 1%", paddingTop: "6rem" }}>
            <MDBCard
                className='mx-auto pink lighten-5'
                style={{ fontWeight: 300, maxWidth: '98%' }}
            >
                <MDBCardBody>
                    <h2 className='h1-responsive font-weight-bold text-center my-1'>
                        Your Comments
                </h2>
                    <h6 className='text-center w-responsive mx-auto mb-2'>
                        Tell us how much to loved it.
                </h6>
                    <h6 className='text-center w-responsive mx-auto mb-2'>
                        Ask us to make you something extra special.
                </h6>
                    <h6 className='text-center w-responsive mx-auto mb-2'>
                        Let us know how we can make your experience better.
                </h6>
                    <MDBDataTable
                        striped
                        hover
                        autoWidth
                        small
                        exportToCSV
                        data={data}
                    />
                </MDBCardBody>

            </MDBCard>
        </main>
    )
}