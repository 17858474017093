// src/admin/AdminUsers.js

import React, { useState } from 'react'

import AdminAddresses from './AdminAddresses'
import AdminCarts from './AdminCarts'
import AdminOrders from './AdminOrders'
import AdminConfig from './AdminConfig'

import {
  MDBContainer, 
  MDBTabPane, 
  MDBTabContent, 
  MDBNav, 
  MDBNavItem, 
  MDBNavLink, 
  MDBIcon
} from "mdbreact";


export default function AdminUsers ({ selectedUser }) {
  //console.log('>>>-AdminUsers-selectedUser->', selectedUser)
  const [ tab, setTab ] = useState("cart")

  return (
    <MDBContainer style={{ maxWidth: '100%' }}>
      <MDBNav tabs className="nav-justified" color='indigo'>

        <MDBNavItem>
        <MDBNavLink 
            to="#" 
            active={tab === "cart"} 
            onClick={() => setTab("cart")} 
            role="tab" 
          >
            <MDBIcon icon="shopping-cart" /> Cart
          </MDBNavLink>
        </MDBNavItem>

        <MDBNavItem>
          <MDBNavLink 
            to="#" 
            active={tab === "addresses"} 
            onClick={() => setTab("addresses")} 
            role="tab" 
          >
            <MDBIcon icon="address-card" /> Addresses
          </MDBNavLink>
        </MDBNavItem>

        <MDBNavItem>
          <MDBNavLink 
            to="#" 
            active={tab === "orders"} 
            onClick={() => setTab("orders")} 
            role="tab" 
          >
            <MDBIcon icon="truck" /> Orders
          </MDBNavLink>
        </MDBNavItem>

        <MDBNavItem>
          <MDBNavLink 
            to="#" 
            active={tab === "configuration"} 
            onClick={() => setTab("configuration")} 
            role="tab" 
          >
            <MDBIcon icon="cog" /> Configuration
          </MDBNavLink>
        </MDBNavItem>

      </MDBNav>
      <MDBTabContent
        className="card"
        activeItem={tab}
      >
        <MDBTabPane tabId="addresses" role="tabpanel">
          <AdminAddresses selectedUser={selectedUser} />
        </MDBTabPane>

        <MDBTabPane tabId="cart" role="tabpanel">
          <AdminCarts selectedUser={selectedUser} />
        </MDBTabPane>

        <MDBTabPane tabId="orders" role="tabpanel">
          <AdminOrders selectedUser={selectedUser} />
        </MDBTabPane>

        <MDBTabPane tabId="configuration" role="tabpanel">
          <AdminConfig selectedUser={selectedUser} />
        </MDBTabPane>

        <p className="mt-2 red">disable buttons given conditions</p>
        <p className="mt-2 red">Manage cart products</p>
        <p className="mt-2 red">Schedule user cart contents -> order</p>

      </MDBTabContent>
    </MDBContainer>
  )
}