// src/pages/CakeCard.js

import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazy-load'
import { Auth, API, graphqlOperation } from "aws-amplify";
import { Context } from "../state/Context";

import { cakeAmount } from "../utilities/functions";

import CartModal from "./shop/CartModal";
import MoreModal from "./shop/MoreModal";

import {
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBRow,
  MDBIcon,
  MDBInput
} from "mdbreact";

import { listProducts } from "../graphql/queries";
import { createProduct, updateProduct } from "../graphql/mutations";
import { onCreateProduct, onDeleteProduct, onUpdateProduct } from "../graphql/subscriptions";

//===========================================================================
//===========================================================================

export default function CakeCard({ selectedCake }) {
  const { state, dispatch } = useContext(Context);
  //console.log('>>>-CakeCard-state->', state)
  const [ isCartOpen, setIsCartOpen ] = useState(false);
  const [ isMoreOpen, setIsMoreOpen ] = useState(false);
  //console.log('>>>-CakeCard-selectedCake->', selectedCake)

  const [ cartProducts, setCartProducts ] = useState([]);
  const [ addProduct, setAddProduct ] = useState({});
  const [ quantity, setQuantity ] = useState(1);
  const [ hasAlcohol, setHasAlcohol ] = useState(true);
  const [ isNormalSize, setIsNormalSize ] = useState(true);
  const [ addGarnish, setAddGarnish ] = useState(false);
  //const [ orderBook, setOrderBook ] = useState([]);
  //const [ addOrder, setAddOrder ] = useState({});

  const [ isSubmitting, setIsSubmitting ] = useState(false);

  useEffect(() => {
    listCartProductsAsync();
  }, [addProduct, quantity]);
  // useEffect(() => {
  //   listOrderBookAsync();
  // }, [addProduct, addOrder]);

  useEffect(() => {
    listCartProductsAsync();
    //console.log('>>>-CartContents-useEffect-cartProducts->', cartProducts)
    const input = addProduct;
    const createProductListener = API.graphql(
      graphqlOperation(onCreateProduct, { input })
    ).subscribe({
      next: data => {
        setAddProduct(data.value.data.onCreateProduct);
        // console.log('>>>-START-useEffect-create__listener-BEFORE-set__>')
        setCartProducts(prevData => {
          // console.log('>>>-START-useEffect-createProductListener-IN-cartProducts->')
          const oldData = prevData.filter(row => row.id !== addProduct.id);
          const updatedData = [...oldData, addProduct];
          return updatedData;
        });
      },
    });
    const deleteProductListener = API.graphql(
      graphqlOperation(onDeleteProduct)
    ).subscribe({
      next: data => {
        const deletedData = data.value.data.onDeleteProduct;
        // console.log('>>>-START-useEffect-deleteProductListener-BEFORE-cartProducts>',cartProducts)
        setCartProducts(prevData => {
          // console.log('>>>-START-useEffect-deleteProductListener-IN-cartProducts->')
          const updatedData = prevData.filter(row => row.id !== deletedData.id);
          // console.log(">>>-CartContents-useEffect-deleteProductListener-cartProducts->",cartProducts)
          return updatedData;
        });
      },
    });
    const updateProductListener = API.graphql(graphqlOperation(onUpdateProduct)).subscribe({
      next: data => {
          // console.log(">>>-Notes-useEffect-updateProductListener-cartProducts->",cartProducts)
          const updatedRow = data.value.data.onUpdateProduct
          // console.log('>>>-START-useEffect-updateProductListener-BEFORE-cartProducts>',cartProducts)
          setCartProducts(prevData => {
              // console.log('>>>-START-useEffect-updateProductListener-IN-cartProducts->')
              const index = prevData.findIndex(row => row.id === updatedRow.id)
              const updatedData = [
                  ...prevData.slice(0, index),
                  updatedRow,
                  ...prevData.slice(index + 1)
              ]
              // console.log(">>>-Notes-useEffect-updateProductListener-cartProducts->",cartProducts)                    
              return updatedData
          })
      }
  })

    return () => {
      createProductListener.unsubscribe();
      deleteProductListener.unsubscribe();
      updateProductListener.unsubscribe()
    };
  }, [addProduct]);

  const handleSignOut = async () => {
    await Auth.signOut()
    dispatch({ type: 'USER_SIGN_OUT', payload: {} })
    //return props.history.push('/signin')
  }

  const listCartProductsAsync = async () => {
    if (state.userIsAuthenticated === true) {
      try {
        const result = await API.graphql(graphqlOperation(
          listProducts,{
            limit: 25,
            filter: { processState: { contains: 'carted' } }
          }
          ));
        const result2 = result.data.listProducts.items;
        setCartProducts(result2);
        //console.log('>>>-CakeCard-listCartProductsAsync-result2->', result2)
        dispatch({ type: "LOAD_CART_PRODUCTS", payload: result2 });
      } catch (err) {
        //alert(err.message)
        console.log("error fetching getCart...", err);
        //alert('Please signin to start a new session.')
        handleSignOut()
      }
    } else {
      return
    }
  };

  const handleAddToCart = async event => {
    event.preventDefault();
    setIsSubmitting(true);
    //console.log('>>>-CakeCard-handleAddToCart-{selectedCake,quantity}->',{selectedCake,quantity})
    //console.log('>>>-CakeCard-handleAddToCart-state.userCart->',state.userCart)
    // if selectedCake in userCart edit quantity of product else add Product
    if (state.userCart.length > 0) {
      //console.log('>>>-CakeCard-handleAddToCart-state.userCart.length>0->',state.userCart.length)
      const matchedProduct = state.userCart.filter( item => {
        if (item.productName === selectedCake.name) {
          //console.log('>>>-CakeCard-handleAddToCart-matchedProduct-item->',item)
          return item
        }
      })
      //console.log('>>>-CakeCard-handleAddToCart-matchedProduct->',matchedProduct)
      if (matchedProduct.length > 0) {
        //console.log('>>>-CakeCard-handleAddToCart-matchedProduct-edit-quantity->',quantity)
        try {
          const lineItem = {
            id: matchedProduct[0].id,
            quantity: (Number(matchedProduct[0].quantity) + Number(quantity)).toString()
          };
          //console.log('>>>-CakeCard-handleAddToCart-lineItem->',lineItem)
          await API.graphql(graphqlOperation(updateProduct, { input: lineItem }));
          setIsSubmitting(false);
        } catch (err) {
          console.log("errors editing product quantity in cart! :", err);
          if (state.userIsAuthenticated === true) {
            //alert(err.message)
            //alert('Please signin to start a new session.')
            handleSignOut()
          }
          setIsSubmitting(false);
        }

      } else {
        //console.log('>>>-CakeCard-handleAddToCart-NO matchedProduct-add->')
        try {
          const lineItem = {
            cognitoId: state.userDetails.cognitoId,
            chId: state.userDetails.chId,
            usernameAWS: state.userDetails.usernameAWS,
            ownerAWS: state.userDetails.ownerAWS,
            productName: selectedCake.name,
            quantity: quantity.toString(),
            unitPrice: cakeAmount(selectedCake.name).toString(),
            hasAlcohol: hasAlcohol,
            isNormalSize: isNormalSize,
            addGarnish: addGarnish,
            couponType: 'none',
            couponLocation: 'none',
            couponID: 'none',
            couponUnit: 'Percent',
            couponQuantity: '0',
            couponExpiry: 'none',
            processState: 'carted',
            status: "active"
          };
          //console.log('>>>-CakeCard-handleAddToCart-lineItem->',lineItem)
          await API.graphql(graphqlOperation(createProduct, { input: lineItem }));
          setIsSubmitting(false);
        } catch (err) {
          console.log("errors adding product to other products in cart! :", err);
          if (state.userIsAuthenticated === true) {
            //alert(err.message)
            //alert('Please signin to start a new session.')
            handleSignOut()
          }
          setIsSubmitting(false);
        }
      }

    } else {
      //console.log('>>>-CakeCard-handleAddToCart-state.userCart.length=0->',state.userCart.length)
      try {
        const lineItem = {
          cognitoId: state.userDetails.cognitoId,
          chId: state.userDetails.chId,
          usernameAWS: state.userDetails.usernameAWS,
          ownerAWS: state.userDetails.ownerAWS,
          productName: selectedCake.name,
          quantity: quantity.toString(),
          unitPrice: cakeAmount(selectedCake.name).toString(),
          hasAlcohol: hasAlcohol,
          isNormalSize: isNormalSize,
          addGarnish: addGarnish,
          couponType: 'none',
          couponLocation: 'none',
          couponID: 'none',
          couponUnit: 'Percent',
          couponQuantity: '0',
          couponExpiry: 'none',
          processState: 'carted',
          status: "active"
        };
        //console.log('>>>-CakeCard-handleAddToCart-lineItem->',lineItem)
        await API.graphql(graphqlOperation(createProduct, { input: lineItem }));
        setIsSubmitting(false);
      } catch (err) {
        console.log("errors adding products to empty cart! :", err);
        if (state.userIsAuthenticated === true) {
          //alert(err.message)
          //alert('Please signin to start a new session.')
          handleSignOut()
        }
        setIsSubmitting(false);
      }
    }
    listCartProductsAsync()
    setHasAlcohol(true)
    setIsNormalSize(true)
    setAddGarnish(false)
  };

  return (
    <MDBCol>
      <MDBCard ecommerce pricing narrow style={{ width: "20rem" }}>

        <LazyLoad height={300} offsetVertical={0}>
          <img src={selectedCake.imagePath300} />
        </LazyLoad>

        <MDBCardBody>
          <MDBCardTitle>
            Christy&apos;s {selectedCake.name} Rum Cake
          </MDBCardTitle>
          <MDBCardText>{selectedCake.description}</MDBCardText>
          <hr />
          <div className="text-center p-0 m-0 s-0">
            <ul className="list-inline p-0 m-0 s-0 font-small">
              
              <li className="list-inline-item p-0 m-0 s-0">
                <h6>Want yours alcohol free?</h6>
                <MDBInput 
                  gap 
                  onClick={() => setHasAlcohol(!hasAlcohol)} 
                  checked={hasAlcohol ? true : false} 
                  label="Rum for me please." 
                  type="radio"
                  id="alcohol" 
                />
                <MDBInput 
                  gap 
                  onClick={() => setHasAlcohol(!hasAlcohol)} 
                  checked={hasAlcohol ? false : true} 
                  label="Alcohol free thank you." 
                  type="radio"
                  id="noAlcohol" 
                />
                {/* <hr />
                <h6>What size do you want?</h6>
                <MDBInput 
                  gap 
                  onClick={() => setIsNormalSize(!isNormalSize)} 
                  checked={isNormalSize ? true : false} 
                  label="Normal, 9in diameter." 
                  type="radio"
                  id="normalSize" 
                />
                <MDBInput 
                  gap 
                  onClick={() => setIsNormalSize( !isNormalSize )} 
                  checked={isNormalSize ? false : true} 
                  label="Mini, 3.5in diameter." 
                  type="radio"
                  id="miniSize" 
                /> */}
              </li>
              <hr/>

              {
                selectedCake.garnishes === "" ? (
                  null
                ) : (
                  <>
                  <li>
                    <div className='form-check my-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='garnish'
                        onChange={e => setAddGarnish( !addGarnish )}
                      />
                      <label htmlFor='garnish'>
                        Add garnish for ${cakeAmount("Garnish").toFixed(2)}?
                      </label>
                    </div>
                    <p style={{fontSize:'12px'}}>{addGarnish ? "Adding:" : ""} {selectedCake.garnishes}</p>
                  </li>
                  <hr/>
                  </>
                )
              }


              <li className="list-inline-item p-0 m-0 s-0">
                <h6>
                  Price per regular size ${cakeAmount(selectedCake.name).toFixed(2)}
                </h6>
              </li>

            </ul>
            {state.userIsAuthenticated ? (
              <ul className="list-inline font-small">
                <li className="list-inline-item p-0 m-0 s-0">
                  <MDBBtn
                    className="p-2 m-2 s-1"
                    size="sm"
                    onClick={() => setQuantity(quantity + 1)}
                  >
                    <MDBIcon icon="plus" />
                  </MDBBtn>
                </li>
                <li className="list-inline-item p-0 m-0 s-0">{quantity}</li>
                <li className="list-inline-item p-0 m-0 s-0">
                  <MDBBtn
                    className="p-2 m-2 s-1"
                    disabled={quantity <= 1}
                    size="sm"
                    onClick={() => setQuantity(quantity - 1)}
                  >
                    <MDBIcon icon="minus" />
                  </MDBBtn>
                </li>
                <li className="list-inline-item p-0 m-0 s-0">
                  <MDBBtn
                    className="p-3 ml-3 s-3"
                    size="sm"
                    onClick={event => handleAddToCart(event)}
                  >
                    Add to Cart <MDBIcon size="2x" icon="cart-plus" />
                  </MDBBtn>
                </li>
              </ul>
            ) : null}
          </div>
          <hr />
          <MDBRow className="text-center p-0">
            <MDBBtn
              color="info"
              onClick={() => setIsMoreOpen(!isMoreOpen)}
            >
              More Info
            </MDBBtn>
            <MoreModal
              selectedCake={selectedCake}
              isOpen={isMoreOpen}
              toggle={() => setIsMoreOpen(!isMoreOpen)}
            />

            {(state.userIsAuthenticated && state.userIsKnown) ? (
              <>
                <MDBBtn
                  color="success"
                  onClick={() => setIsCartOpen(!isCartOpen)}
                >
                  Show Cart
                  {/* <MDBBadge color="danger" className="ml-0"></MDBBadge> */}
                </MDBBtn>
                <CartModal
                  isOpen={isCartOpen}
                  toggle={() => setIsCartOpen(!isCartOpen)}
                  cartProducts={cartProducts}
                  setCartProducts={() => setCartProducts}
                  //updateCart={() => listCartProductsAsync()}
                />
              </>
            ) : (
              <Link to="/signin">
                <MDBBtn color="success">Sign In</MDBBtn>
              </Link>
            )}
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  );
}
