//src/pages/footer/DeliveriesModal.ja

import React from 'react';

import { 
  MDBBtn, 
  MDBModal,
  MDBModalBody, 
  MDBModalHeader, 
  MDBModalFooter } from 'mdbreact';

//===========================================================================
//===========================================================================

const DeliveriesModal = ({ isOpen, toggle }) => {
  //console.log('>>>-MoreModal-{ isOpen, toggle, cakeName }->',{isOpen,toggle,cakeName})

    return (
        <MDBModal 
          size="small" 
          isOpen={isOpen} 
          toggle={toggle} 
          centered
          className="modal-notify modal-info text-white" 
        >  
        <MDBModalHeader>Christy&apos;s Rum Cake</MDBModalHeader>
        <MDBModalBody>
          <h3>
          Deliveries Policies
          </h3>
          <hr/>
          <p> 
          Shipping Method & Delivery 
          </p><p>
We ship orders via Fed-Ex or US Parcel Service and reserve the right to ship via any other common carrier.  
We begin processing orders within one business day of credit card transaction approval.  
We will notify you promptly of any back orders. 
All United States orders (except for overnight orders) have a delivery time of approximately 2-5 business days, other countries approximately 5-10 business days. 
We are not responsible for delays that may occur during shipping by the above common carriers. 
We do not accept checks. 
We do not ship to P.O. Boxes.  
Orders will not be process without a shipping address.  
We are not responsible for delivery acceptance at the shipping address.
</p><p>
Rush Delivery 
</p><p>
Two-Day and Priority Overnight air orders must be placed by 2:00pm E.S.T. Monday – Friday. 
Orders received after this time will not be shipped until the next business day. 
Priority Overnight - Orders received before 2:00pm E.S.T. on Friday will be shipped to arrive the next business day (Monday). 
Additional charges for rush delivery methods are calculated at checkout.  
Upon request, international orders can be shipped International Express Priority, but there is no guarantee on the delivery date due to foreign customs agencies which reserve the right to hold packages for inspection, duties, or other fees.
          </p>
          
        </MDBModalBody>

        <MDBModalFooter>
            <MDBBtn 
                color="primary" 
                onClick={toggle}>
                Close
            </MDBBtn>
        </MDBModalFooter>
        </MDBModal>
    );
}

export default DeliveriesModal