// src/admin/management/System.js

import React from "react";

import { 
    MDBContainer, 
 } from "mdbreact";

//===========================================================================
//===========================================================================

export default function System() {

  return (
    <main style={{ margin: "0 1%", paddingTop: "6rem" }}>
      <MDBContainer className="mx-auto green lighten-5" style={{ maxWidth: '98%' }}>
        <h1>System</h1>
        <ul>
          <li>create/add/edit/remove cake products (cakeList)</li>
          <li>manage pictures</li>
          <li>manage comment list</li>
          <li>accounting</li>
        </ul>
      </MDBContainer>
    </main>
  )
}