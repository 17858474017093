// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b2a8eb85-788e-45e3-b666-9daf87c1e422",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_J7MdmLgoN",
    "aws_user_pools_web_client_id": "3d30pjaf73g6dood7egar0a357",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "stripecrcprod",
            "endpoint": "https://jhtzp37m0m.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://bbhsilc7rfbq7fib2nkioordg4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
