// src/pages/shop/Orders.js

import React from 'react'
import Addresses from './Addresses'

//===========================================================================
//===========================================================================

export default function AddressesPage() {

    return (
        <main style={{margin: "0 1%", paddingTop: "6rem"}}>
        <Addresses />
        </main>
    )
}