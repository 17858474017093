// src/admin/management/Users.js

import React from "react";
import ListUsers from './ListUsers';

//===========================================================================
//===========================================================================

export default function Users() {

  return (
    <main style={{ margin: "0 1%", paddingTop: "6rem" }}>
      <ListUsers />
    </main>
  )
}