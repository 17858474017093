// src/pages/account/AddAddressModal.js

import React, { useState, useContext } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { Context } from '../../state/Context'

import {
  MDBModal,
  MDBModalHeader,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon
} from 'mdbreact'

import { replaceWithMissing } from '../../utilities/functions'
import { createAddress } from '../../graphql/mutations'

//===========================================================================
//===========================================================================

export default function AddAddressModal ({ isOpen, toggle }) {
  const { state } = useContext(Context)
  // console.log('>>>-AddAddressModal-state->', state)
  const [addressTitle, setAddressTitle] = useState('')
  const [street, setStreet] = useState('')
  const [street2, setStreet2] = useState('')
  const [city, setCity] = useState('')
  const [stateGeo, setStateGeo] = useState('')
  const [mailCode, setMailCode] = useState('')
  const [country, setCountry] = useState('')
  //const [isBilling, setIsBilling] = useState(false)
  //const [isShipping, setIsShipping] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)

  // handleCreateAddress
  const handleCreateAddress = async event => {
    event.preventDefault()
    setIsSubmitting(true)
    //console.log('>>>-AddAddressModal-Boolean(state.billingAddress.id)->', Boolean(state.billingAddress.id))
    const shipBillTrueFalse = () => {
      if (Boolean(state.billingAddress.id)) {
        //console.log('>>>-AddAddressModal-shipBillTrueFalse-true->', Boolean(state.billingAddress.id))
        return false
      } else {
        //console.log('>>>-AddAddressModal-shipBillTrueFalse-false->', Boolean(state.billingAddress.id))
        return true
      }
    }
    try {
      // prep Address
      const inputAddress = {
        // id: user.signInUserSession.idToken.payload.sub,
        cognitoId: state.userDetails.cognitoId,
        chId: state.userDetails.chId,
        usernameAWS: state.userDetails.usernameAWS,
        ownerAWS: state.userDetails.ownerAWS,
        // EDIT in AccountDetails
        addressTitle: replaceWithMissing(addressTitle),
        street: street,
        street2: replaceWithMissing(street2),
        city: city,
        stateGeo: stateGeo,
        mailCode: mailCode,
        country: country,
        isBilling: shipBillTrueFalse(),
        isShipping: shipBillTrueFalse(),
        geoLat: 'missing',
        geoLng: 'missing'
      }
      // console.log('>>>-handleCreateAddress-inputAddress->', inputAddress)
      await API.graphql(
        graphqlOperation(createAddress, { input: inputAddress })
      )
      // reset component state
      setIsSubmitting(false)
      // close the modal
      toggle()
    } catch (err) {
      console.log('errors signing in! :', err)
      alert(err.message)
      setIsSubmitting(false)
    }
  }

  const handleClearForm = event => {
    event.preventDefault()
    setAddressTitle('')
    setStreet('')
    setStreet2('')
    setCity('')
    setStateGeo('')
    setMailCode('')
    setCountry('')
  }

  return (
    <MDBModal
      isOpen={isOpen}
      toggle={toggle}
      centered
      className='modal-notify modal-info text-white'
    >
      <MDBModalHeader>Add an Address</MDBModalHeader>
      <MDBRow>
        <MDBCol>
          <form onSubmit={handleCreateAddress}>
            <h5 style={{ margin: 5 }}>Shipping Addresses</h5>
            <div style={{ margin: 2, padding: 2, spacing: 2 }}>
              <MDBInput
                label='Name'
                type='text'
                id='addressTitle'
                outline
                value={addressTitle === 'missing' ? '' : addressTitle}
                onChange={e => setAddressTitle(e.target.value)}
              />
              <MDBInput
                label='Street'
                type='text'
                id='street'
                outline
                value={street === 'missing' ? '' : street}
                onChange={e => setStreet(e.target.value.toString())}
              />
              <MDBInput
                label='Street 2 (optional)'
                type='text'
                id='street2'
                outline
                value={street2 === 'missing' ? '' : street2}
                onChange={e => setStreet2(e.target.value)}
              />
              <MDBInput
                label='City'
                type='text'
                id='city'
                outline
                value={city === 'missing' ? '' : city}
                onChange={e => setCity(e.target.value)}
              />
              <MDBInput
                label='State'
                type='text'
                id='stateGeo'
                outline
                value={stateGeo === 'missing' ? '' : stateGeo}
                onChange={e => setStateGeo(e.target.value)}
              />
              <MDBInput
                label='Post Code'
                type='text'
                id='mailCode'
                outline
                value={mailCode === 'missing' ? '' : mailCode}
                onChange={e => setMailCode(e.target.value.toString())}
              />
              <MDBInput
                label='Country'
                type='text'
                id='country'
                outline
                value={country === 'missing' ? '' : country}
                onChange={e => setCountry(e.target.value)}
              />
            </div>
            <div className='text-center mt-4'>
              <MDBBtn
                color='primary'
                type='submit'
                disabled={
                  (addressTitle.length > 5 && street.length > 5 && city.length > 2 && stateGeo.length > 1 && mailCode .length > 4)
                  ? (false)
                  : (true)
                }
              >
                {isSubmitting ? (
                  <MDBIcon icon='spinner' pulse size='1x' fixed />
                ) : (
                  'Add Address'
                )}
              </MDBBtn>
              <MDBBtn color='secondary' onClick={handleClearForm}>
                Clear Form
              </MDBBtn>
              <MDBBtn color='primary' onClick={toggle}>
                Close
              </MDBBtn>
            </div>
          </form>
        </MDBCol>
      </MDBRow>
    </MDBModal>
  )
}
