// src/components/CakeCarousel.js

import React from "react";

import {
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBMask,
} from "mdbreact";

import BananasFosterPic from "../utilities/images/BananasFoster600.jpg";
import CinnamonBunPic from "../utilities/images/CinnamonBun600.jpg";
import CoconutPic from "../utilities/images/Coconut600.jpg";
import DarkChocolatePic from "../utilities/images/DarkChocolate600.jpg";
import GingerLimePic from "../utilities/images/GingerLime600.jpg";
import OriginalPic from "../utilities/images/Original600.jpg";
import PineappleUpsideDownPic from "../utilities/images/PineappleUpsideDown600.jpg";
import SpicedApplePic from "../utilities/images/SpicedApple600.jpg";
import AppleCinnamonPic from "../utilities/images/AppleCinnamon600.jpg";
import ButterMaplePecanPic from "../utilities/images/ButterMaplePecan600.jpg";
import CranberryLemonPic from "../utilities/images/CranberryLemon600.jpg";

import { cakeList } from '../utilities/lists'

const CakeCarousel = () => {
  //console.log('>>>-CakeCarousel-cakeList->', cakeList)

  return (
    <div className="p-2 col-example text-left w-175">
      <MDBCarousel
        size="200px"
        activeItem={1}
        length={(cakeList.length > 0) ? cakeList.length : 0}
        showControls
        showIndicators
        className="z-depth-1"
      >
        <MDBCarouselInner>

        {
          cakeList.map((cake) => (
            <MDBCarouselItem key={cake.id} itemId={cake.id}>
            <MDBView>
              <img
                className="d-block w-100 rounded"
                src={cake.imagePath300}
                alt={cake.imagePath300}
              />
              <MDBMask overlay="black-slight" />
            </MDBView>
            <MDBCarouselCaption>
              <h3 className="h3-responsive">
                Christy&apos;s {cake.name} Rum Cake
              </h3>
              <p>
                {cake.description}
              </p>
            </MDBCarouselCaption>
            </MDBCarouselItem>)
          )
        }

        </MDBCarouselInner>
      </MDBCarousel>
    </div>
  );
};

export default CakeCarousel;
