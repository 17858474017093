// src/pages/shop/CartContents.js

import React, { useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Context } from "../state/Context";

import { 
  cakeAmount, 
  shippingAmount, 
  getSum, 
  discount } from "../utilities/functions";

import {
  MDBCard,
  MDBCol,
  MDBTable,
  MDBTableHead,
  TableBody,
  MDBBtn,
} from "mdbreact";

import { deleteProduct } from "../graphql/mutations";

//===========================================================================
//===========================================================================

export default function CartContents({ shipping, cartProducts }) {
  const { dispatch } = useContext(Context);
  //console.log('>>>-CartContents-cartProducts->', cartProducts)
  const [isDeleting, setIsDeleting] = useState(false);

  const discountPerCake = (item) => {
    //console.log('>>>-CartContents-discountPerCake-item->', item)
    const disc = Number(Number(item.unitPrice) * discount(item.couponUnit,Number(item.couponQuantity))).toFixed(2)
    return disc
  }

  const totalUnits = Array.isArray(cartProducts)
  ? cartProducts
      .map(item => item.quantity)
      .reduce(getSum, 0)
  : [];

  const totalCakeCost = Array.isArray(cartProducts)
  ? cartProducts
      .map(item => item.quantity * ( 
        item.unitPrice 
        - discountPerCake(item)
        + (item.addGarnish ? cakeAmount("Garnish") : 0)
        ))
      .reduce(getSum, 0)
      .toFixed(2)
  : [];

  const totalAllOtherCost = totalUnits < 1 ? 0 : shippingAmount(shipping, totalUnits).toFixed(2)

  const handleDeleteItem = async id => {
    setIsDeleting(true);
    //console.log('>>>-CakeCard-handleDeleteItem-id->', id)
    const itemToDelete = cartProducts.find(row => row.id === id);
    //console.log(">>>-CakeCard-handleDeleteRow-itemToDelete.id->", itemToDelete.id);
    const input = {
      id: itemToDelete.id,
      expectedVersion: itemToDelete.version
    };
    //console.log(">>>-CartContents-handleDeleteItem-input->", input);
    await API.graphql(graphqlOperation(deleteProduct, { input }));
    dispatch({ type: "REMOVE_CART_PRODUCT", payload: input });
    setIsDeleting(false);
  };

  return (
    <MDBCard
      className="my-1 px-1 mx-auto pink lighten-5"
      style={{ fontWeight: 300, maxWidth: "1200px" }}
    >
      <MDBCol>
        <h2 className="h2-responsive font-weight-bold text-center mt-2">
          Items in your cart
        </h2>
        <MDBTable className="p-0 m-0 s-0 text-left" small>
          <MDBTableHead color="deep-purple lighten-4">
            <tr>
              <th className="pt-2 m-0 s-0 text-center" width="5%"></th>
              <th className="pt-2 m-0 s-0 text-left" width="40%">
                Item
              </th>
              <th className="pt-2 m-0 s-0 text-right" width="5%">
                Alcohol
              </th>
              <th className="pt-2 m-0 s-0 text-right" width="5%">
                Size
              </th>
              <th className="pt-2 m-0 s-0 text-right" width="10%">
                Price per
              </th>
              <th className="pt-2 m-0 s-0 text-right" width="10%">
                Garnish per
              </th>
              <th className="pt-2 m-0 s-0 text-right" width="10%">
                Discount per
              </th>
              <th className="pt-2 m-0 s-0 text-right" width="5%">
                Quantity
              </th>
              <th className="pt-2 m-0 s-0 text-right" width="10%">
                Subtotal
              </th>
            </tr>
          </MDBTableHead>
          <TableBody>
            {
              cartProducts.map(item => (
              <tr key={item.productName}>
                <td className="pt-2 m-0 s-0 text-center" width="5%">
                  <MDBBtn
                    color="warning"
                    size="sm"
                    style={{ spacing: 3, margin: 3, padding: 3 }}
                    disabled={isDeleting}
                    id={item.id}
                    onClick={e => handleDeleteItem(e.target.id)}
                  >
                    Remove
                  </MDBBtn>
                </td>
                <td className="pt-2 m-0 s-0 text-left" width="40%">
                  {item.productName.search("Mini") > 0 
                    ? item.productName + " Rum Cakes"
                    : item.productName + " Rum Cake"}
                </td>
                <td className="pt-2 m-0 s-0 text-right" width="5%">
                  {item.hasAlcohol ? 'yes' : 'no'}
                </td>
                <td className="pt-2 m-0 s-0 text-right" width="5%">
                  {item.productName.search("Mini") > 0 ? 'mini' : 'normal'}
                </td>
                <td className="pt-2 m-0 s-0 text-right" width="10%">
                  ${cakeAmount(item.productName).toFixed(2)}{" "}
                </td>
                <td className="pt-2 m-0 s-0 text-right" width="10%">
                  ${item.addGarnish ? cakeAmount('Garnish').toFixed(2) : (0).toFixed(2)}
                </td>
                <td className="pt-2 m-0 s-0 text-right" width="10%">
                  ${discountPerCake(item)}{" "}
                </td>
                <td className="pt-2 m-0 s-0 text-right" width="5%">
                  {item.quantity}
                </td>
                <td className="pt-2 m-0 s-0 text-right" width="10%">
                  $
                  {(item.quantity * (
                    cakeAmount(item.productName) 
                    - discountPerCake(item)
                    + ( item.addGarnish ? cakeAmount('Garnish') : 0.00 )
                    )).toFixed(2)}
                </td>
              </tr>
            )) 
            }
            <tr bgcolor="#ADD8E6">
              <td className="pt-2 m-0 s-0 text-center" width="5%"></td>
              <td className="pt-2 m-0 s-0 text-left" width="40%">
                <strong>Total cost of cakes</strong>
              </td>
              <td className="pt-2 m-0 s-0 text-right" width="5%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="5%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="10%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="10%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="10%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="5%"><strong>{totalUnits}</strong></td>
              <td className="pt-2 m-0 s-0 text-right" width="10%"><strong>${totalCakeCost}</strong></td>
            </tr>
            <tr bgcolor="#ADD8E6">
              <td className="pt-2 m-0 s-0 text-center" width="5%"></td>
              <td className="pt-2 m-0 s-0 text-left" width="40%">
                <strong>Shipping, taxes, and handling</strong>
              </td>
              <td className="pt-2 m-0 s-0 text-right" width="5%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="5%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="10%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="10%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="10%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="5%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="10%">
                <strong>${totalAllOtherCost}</strong>
              </td>
            </tr>
            <tr bgcolor="#ADD8E6">
              <td className="pt-2 m-0 s-0 text-center" width="5%"></td>
              <td className="pt-2 m-0 s-0 text-left" width="40%">
                <strong>Total</strong>
              </td>
              <td className="pt-2 m-0 s-0 text-right" width="5%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="5%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="10%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="10%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="10%"></td>
              <td className="pt-2 m-0 s-0 text-right" width="5%"><strong>{totalUnits}</strong></td>
              <td className="pt-2 m-0 s-0 text-right" width="10%">
                <strong>${totalUnits < 1 ? 0 : (Number(totalCakeCost) + Number(totalAllOtherCost)).toFixed(2)}</strong>
              </td>
            </tr>
          </TableBody>
        </MDBTable>
      </MDBCol>
    </MDBCard>
  );
}
