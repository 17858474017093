// src/admin/dashboard/History.js

import React from "react";

import { 
    MDBContainer, 
 } from "mdbreact";

//===========================================================================
//===========================================================================

export default function History() {

  return (
    <main style={{ margin: "0 1%", paddingTop: "6rem" }}>
      <MDBContainer className="mx-auto green lighten-5" style={{ maxWidth: '98%' }}>
        <h1>History</h1>
        <ul>
          <li>units, revenue & est. cost</li>
          <li>user and site use stats over time</li>
        </ul>
      </MDBContainer>
    </main>
  )
}
